import { create } from 'zustand'

interface ImageFilterStoreState {
  isOpen: boolean
  contrast: number
  saturation: number
  gamma: number
  exposure: number
}

const initialState: ImageFilterStoreState = {
  isOpen: false,
  contrast: 0,
  saturation: 0,
  gamma: 1,
  exposure: 0,
}

interface ImageFilterStoreAction {
  setIsOpen: (value: boolean) => void
  setContrast: (value: number) => void
  setSaturation: (value: number) => void
  setGamma: (value: number) => void
  setExposure: (value: number) => void
  reset: () => void
}

export const useImageFilterStore = create<ImageFilterStoreState & ImageFilterStoreAction>((set) => ({
  ...initialState,
  setIsOpen: (value: boolean) => { set({ isOpen: value }) },
  setContrast: (value: number) => { set({ contrast: value }) },
  setSaturation: (value: number) => { set({ saturation: value }) },
  setGamma: (value: number) => { set({ gamma: value }) },
  setExposure: (value: number) => { set({ exposure: value }) },
  reset: () => {
    const { isOpen, ...rest } = initialState
    set(rest)
  },
}))
