import { NotificationPredictionUnknownError, NotificationTrainingUnknownError, NotificationTrainingForbiddenError, NotificationCreateTrainingError, NotificationTrainingError } from '@components/notifications/notification'
import { type ProjectNotificationType, useProjectsNotification } from '@app/hooks/notifications'
import type * as React from 'react'

export const NotificationRouter: React.FC = () => {
  const { data: notifications } = useProjectsNotification()
  return <>
    {
      notifications?.map((notification): React.ReactNode => {
        const notificationTypeToNotificationComponent: { [key in ProjectNotificationType]: React.ReactNode } = {
          TrainingForbidden: <NotificationTrainingForbiddenError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          PredictionUnknown: <NotificationPredictionUnknownError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          TrainingUnknown: <NotificationTrainingUnknownError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          TrainingError: <NotificationTrainingError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
            message={notification.message ?? ''}
          />,
          CreateTrainingError: <NotificationCreateTrainingError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          CancelTrainingErrorUnknown: <NotificationCreateTrainingError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
        }

        return notificationTypeToNotificationComponent[notification.type]
      })
    }
  </>
}
