import { Flex, Progress, Tooltip, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/prediction-status.module.scss'
import { useProjectImages, useProjectTrainingSnapshot } from '@app/api/hooks'
import { type ProjectDatasetContext, type ProjectImageResponse, TaskStatus } from '@app/api/openapi'
import { useProjectTrainingSnapshotPredictionsStatus } from '@app/api/resource-status'
const { Title } = Typography

interface PredictingStatusProps {
  projectId: string
  context: ProjectDatasetContext
}
export const PredictingStatus: React.FC<PredictingStatusProps> = ({ projectId, context }) => {
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: images } = useProjectImages(projectId, context)
  const { data: projectTrainingSnapshotPredictionsStatus } = useProjectTrainingSnapshotPredictionsStatus(projectTrainingSnapshot?.projectTrainingSnapshotId)

  const totalImages = images === undefined ? 0 : images.length

  const isPendingPrediction = (image: ProjectImageResponse): boolean => {
    return projectTrainingSnapshotPredictionsStatus?.images[image.id]?.predictionTaskStatus === TaskStatus.Pending
  }
  const numberOfPendingPrediction = totalImages - (images ?? []).filter(isPendingPrediction).length

  if (numberOfPendingPrediction === totalImages) {
    return null
  }

  return <Tooltip placement="topLeft" title={<FormattedMessage id="editor.algorithm.button.predicting.tooltip" defaultMessage={'Predicting the images by using the model on each project image.'} />}>
    <Flex vertical={true} className={styles.root}>
      <Title level={5} className={styles.title}>
        <FormattedMessage
          id="editor.algorithm.button.predicting.title"
          defaultMessage="{totalImages, plural, one {Predicting image} other {Predicting images}}: {numberOfPendingPrediction}/{totalImages}"
          values={{ totalImages, numberOfPendingPrediction }}
        />
      </Title>
      <Progress className={styles.progress} percent={totalImages > 0 ? (numberOfPendingPrediction / totalImages * 100) : 0} showInfo={false} />
    </Flex>
  </Tooltip>
}
