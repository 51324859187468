/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataAnnotationInput } from './MetadataAnnotationInput';
import {
    MetadataAnnotationInputFromJSON,
    MetadataAnnotationInputFromJSONTyped,
    MetadataAnnotationInputToJSON,
} from './MetadataAnnotationInput';

/**
 * 
 * @export
 * @interface AnnotationPatchRequest
 */
export interface AnnotationPatchRequest {
    /**
     * 
     * @type {Array<MetadataAnnotationInput>}
     * @memberof AnnotationPatchRequest
     */
    add?: Array<MetadataAnnotationInput>;
    /**
     * 
     * @type {Array<MetadataAnnotationInput>}
     * @memberof AnnotationPatchRequest
     */
    remove?: Array<MetadataAnnotationInput>;
    /**
     * 
     * @type {Array<MetadataAnnotationInput>}
     * @memberof AnnotationPatchRequest
     */
    update?: Array<MetadataAnnotationInput>;
}

/**
 * Check if a given object implements the AnnotationPatchRequest interface.
 */
export function instanceOfAnnotationPatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnnotationPatchRequestFromJSON(json: any): AnnotationPatchRequest {
    return AnnotationPatchRequestFromJSONTyped(json, false);
}

export function AnnotationPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnotationPatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add': !exists(json, 'add') ? undefined : ((json['add'] as Array<any>).map(MetadataAnnotationInputFromJSON)),
        'remove': !exists(json, 'remove') ? undefined : ((json['remove'] as Array<any>).map(MetadataAnnotationInputFromJSON)),
        'update': !exists(json, 'update') ? undefined : ((json['update'] as Array<any>).map(MetadataAnnotationInputFromJSON)),
    };
}

export function AnnotationPatchRequestToJSON(value?: AnnotationPatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add': value.add === undefined ? undefined : ((value.add as Array<any>).map(MetadataAnnotationInputToJSON)),
        'remove': value.remove === undefined ? undefined : ((value.remove as Array<any>).map(MetadataAnnotationInputToJSON)),
        'update': value.update === undefined ? undefined : ((value.update as Array<any>).map(MetadataAnnotationInputToJSON)),
    };
}

