/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Training Error
 * @export
 * @interface TrainingError
 */
export interface TrainingError {
    /**
     * 
     * @type {Date}
     * @memberof TrainingError
     */
    errorDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof TrainingError
     */
    errorMessage: string;
}

/**
 * Check if a given object implements the TrainingError interface.
 */
export function instanceOfTrainingError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "errorDatetime" in value;
    isInstance = isInstance && "errorMessage" in value;

    return isInstance;
}

export function TrainingErrorFromJSON(json: any): TrainingError {
    return TrainingErrorFromJSONTyped(json, false);
}

export function TrainingErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorDatetime': (new Date(json['error_datetime'])),
        'errorMessage': json['error_message'],
    };
}

export function TrainingErrorToJSON(value?: TrainingError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error_datetime': (value.errorDatetime.toISOString()),
        'error_message': value.errorMessage,
    };
}

