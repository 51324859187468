/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
} from './Geometry';
import type { GeometryProperties1 } from './GeometryProperties1';
import {
    GeometryProperties1FromJSON,
    GeometryProperties1FromJSONTyped,
    GeometryProperties1ToJSON,
} from './GeometryProperties1';
import type { MetadataAnnotationType } from './MetadataAnnotationType';
import {
    MetadataAnnotationTypeFromJSON,
    MetadataAnnotationTypeFromJSONTyped,
    MetadataAnnotationTypeToJSON,
} from './MetadataAnnotationType';

/**
 * 
 * @export
 * @interface MetadataAnnotationOutput
 */
export interface MetadataAnnotationOutput {
    /**
     * 
     * @type {Date}
     * @memberof MetadataAnnotationOutput
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MetadataAnnotationOutput
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationOutput
     */
    metadataAnnotationId?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationOutput
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationOutput
     */
    imageId: string;
    /**
     * 
     * @type {MetadataAnnotationType}
     * @memberof MetadataAnnotationOutput
     */
    type: MetadataAnnotationType;
    /**
     * 
     * @type {Geometry}
     * @memberof MetadataAnnotationOutput
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties1}
     * @memberof MetadataAnnotationOutput
     */
    geometryProperties?: GeometryProperties1;
}

/**
 * Check if a given object implements the MetadataAnnotationOutput interface.
 */
export function instanceOfMetadataAnnotationOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function MetadataAnnotationOutputFromJSON(json: any): MetadataAnnotationOutput {
    return MetadataAnnotationOutputFromJSONTyped(json, false);
}

export function MetadataAnnotationOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAnnotationOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDate': !exists(json, 'creation_date') ? undefined : (new Date(json['creation_date'])),
        'modificationDate': !exists(json, 'modification_date') ? undefined : (new Date(json['modification_date'])),
        'metadataAnnotationId': !exists(json, 'metadata_annotation_id') ? undefined : json['metadata_annotation_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'type': MetadataAnnotationTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': !exists(json, 'geometry_properties') ? undefined : GeometryProperties1FromJSON(json['geometry_properties']),
    };
}

export function MetadataAnnotationOutputToJSON(value?: MetadataAnnotationOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creation_date': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modification_date': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'metadata_annotation_id': value.metadataAnnotationId,
        'project_id': value.projectId,
        'image_id': value.imageId,
        'type': MetadataAnnotationTypeToJSON(value.type),
        'geometry': GeometryToJSON(value.geometry),
        'geometry_properties': GeometryProperties1ToJSON(value.geometryProperties),
    };
}

