import { type EditorStoreState, useEditorStore, type EditorStoreAction } from '../stores/editor'

export interface PredictionFailEventPayload {
  imageId: string
  projectId: string
  projectTrainingSnapshotId: string
}
export interface PredictionQueueEvent {
  projectId: string
  imageId: string
  projectTrainingSnapshotId: string
}
export interface PredictionEventStarting extends PredictionQueueEvent { }
export interface PredictionEventSuccess extends PredictionQueueEvent {
  url: string
}
export interface PredictionEventFail extends PredictionQueueEvent {
  error: unknown
}

type useSelectedImageIdReturn = [
  EditorStoreState['selectedImageId'],
  EditorStoreAction['setSelectedImage'],
]
export function useSelectedImageId (): useSelectedImageIdReturn {
  const selectedImage = useEditorStore((state) => state.selectedImageId)
  const setSelectedImage = useEditorStore((state) => state.setSelectedImage)
  return [selectedImage, setSelectedImage]
}

type useSelectedToolReturn = [
  EditorStoreState['selectedTool'],
  EditorStoreAction['setSelectedTool'],
]
export function useSelectedTool (): useSelectedToolReturn {
  const selectedTool = useEditorStore((state) => state.selectedTool)
  const setSelectedTool = useEditorStore((state) => state.setSelectedTool)
  return [selectedTool, setSelectedTool]
}

type useBrushSizeReturn = [
  EditorStoreState['brushSize'],
  EditorStoreAction['setBrushSize'],
]
export function useBrushSize (): useBrushSizeReturn {
  return useEditorStore((state) => [state.brushSize, state.setBrushSize])
}

type useSelectedAnnotationClassIndexReturn = [
  EditorStoreState['selectedAnnotationClassColorIndex'],
  EditorStoreAction['setSelectedAnnotationClassColorIndex'],
]
export function useSelectedAnnotationClassColorIndex (): useSelectedAnnotationClassIndexReturn {
  const selectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.selectedAnnotationClassColorIndex,
  )
  const setSelectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.setSelectedAnnotationClassColorIndex,
  )
  return [selectedAnnotationClassColorIndex, setSelectedAnnotationClassColorIndex]
}

type useHiddenAnnotationClassIndexesReturn = [
  EditorStoreState['hiddenAnnotationClassIndexes'],
  EditorStoreAction['setHiddenAnnotationClassIndexes'],
]
export function useHiddenAnnotationClassIndexes (): useHiddenAnnotationClassIndexesReturn {
  const hiddenAnnotationClassIndexes = useEditorStore((state) => state.hiddenAnnotationClassIndexes)
  const setHiddenAnnotationClassIndexes = useEditorStore(
    (state) => state.setHiddenAnnotationClassIndexes,
  )
  return [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes]
}

type useGlobalAnnotationOpacityReturn = [
  ReturnType<EditorStoreState['getGlobalAnnotationOpacity']>,
  EditorStoreAction['setGlobalAnnotationOpacity'],
]
export function useGlobalAnnotationOpacity (): useGlobalAnnotationOpacityReturn {
  const globalAnnotationOpacity = useEditorStore((state) => state.getGlobalAnnotationOpacity())
  const setGlobalAnnotationOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationOpacity,
  )
  return [globalAnnotationOpacity, setGlobalAnnotationOpacity]
}

type useGlobalAnnotationFullOpacityReturn = [
  EditorStoreState['globalAnnotationFullOpacity'],
  EditorStoreAction['setGlobalAnnotationFullOpacity'],
]
export function useGlobalAnnotationFullOpacity (): useGlobalAnnotationFullOpacityReturn {
  const globalAnnotationFullOpacity = useEditorStore((state) => state.globalAnnotationFullOpacity)
  const setGlobalAnnotationFullOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationFullOpacity,
  )
  return [globalAnnotationFullOpacity, setGlobalAnnotationFullOpacity]
}

type useGlobalPredictionOpacityReturn = [
  ReturnType<EditorStoreState['getGlobalPredictionOpacity']>,
  EditorStoreAction['setGlobalPredictionOpacity'],
]
export function useGlobalPredictionOpacity (): useGlobalPredictionOpacityReturn {
  const globalPredictionOpacity = useEditorStore(
    (state) => state.getGlobalPredictionOpacity(),
  )
  const setGlobalPredictionOpacity = useEditorStore(
    (state) => state.setGlobalPredictionOpacity,
  )
  return [globalPredictionOpacity, setGlobalPredictionOpacity]
}

type useGlobalPredictionFullOpacityReturn = [
  EditorStoreState['globalPredictionFullOpacity'],
  EditorStoreAction['setGlobalPredictionFullOpacity'],
]
export function useGlobalPredictionFullOpacity (): useGlobalPredictionFullOpacityReturn {
  const globalPredictionFullOpacity = useEditorStore(
    (state) => state.globalPredictionFullOpacity,
  )
  const setGlobalPredictionFullOpacity = useEditorStore(
    (state) => state.setGlobalPredictionFullOpacity,
  )
  return [globalPredictionFullOpacity, setGlobalPredictionFullOpacity]
}

type useGlobalPredictionVisibilityReturn = [
  EditorStoreState['globalPredictionVisibility'],
  EditorStoreAction['setGlobalPredictionVisibility'],
]
export function useGlobalPredictionVisibility (): useGlobalPredictionVisibilityReturn {
  const globalPredictionVisibility = useEditorStore(
    (state) => state.globalPredictionVisibility,
  )
  const setGlobalPredictionVisibility = useEditorStore(
    (state) => state.setGlobalPredictionVisibility,
  )
  return [globalPredictionVisibility, setGlobalPredictionVisibility]
}

type UseIsDirectMeasureAreaToolFreehand = [
  EditorStoreState['isDirectMeasureAreaToolFreehand'],
  EditorStoreAction['setIsDirectMeasureAreaToolFreehand'],
]
export const useIsDirectMeasureAreaToolFreehand = (): UseIsDirectMeasureAreaToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasureAreaToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasureAreaToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type UseIsDirectMeasurePerimeterToolFreehand = [
  EditorStoreState['isDirectMeasurePerimeterToolFreehand'],
  EditorStoreAction['setIsDirectMeasurePerimeterToolFreehand'],
]
export const useIsDirectMeasurePerimeterToolFreehand = (): UseIsDirectMeasurePerimeterToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasurePerimeterToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasurePerimeterToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type useHiddenPredictionClassIndexesReturn = [
  EditorStoreState['hiddenPredictionClassIndexes'],
  EditorStoreAction['setHiddenPredictionClassIndexes'],
]
export function useHiddenPredictionClassIndexes (): useHiddenPredictionClassIndexesReturn {
  const hiddenPredictionClassIndexes = useEditorStore((state) => state.hiddenPredictionClassIndexes)
  const setHiddenPredictionClassIndexes = useEditorStore(
    (state) => state.setHiddenPredictionClassIndexes,
  )
  return [hiddenPredictionClassIndexes, setHiddenPredictionClassIndexes]
}

type useGlobalAnnotationVisibilityReturn = [
  EditorStoreState['globalAnnotationVisibility'],
  EditorStoreAction['setGlobalAnnotationVisibility'],
]
export function useGlobalAnnotationVisibility (): useGlobalAnnotationVisibilityReturn {
  const globalAnnotationVisibility = useEditorStore(
    (state) => state.globalAnnotationVisibility,
  )
  const setGlobalAnnotationVisibility = useEditorStore(
    (state) => state.setGlobalAnnotationVisibility,
  )
  return [globalAnnotationVisibility, setGlobalAnnotationVisibility]
}
