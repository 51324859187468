/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivateAccountRequest,
  AlgoClass,
  AnalyticDataDirectMeasures,
  AnalyticDataInstanceSegmentation,
  AnnotationPatchRequest,
  CompleteUserOnboardingTaskRequest,
  CompleteUserOnboardingTasksRequest,
  DirectMeasureOutput,
  DirectMeasurePatchRequest,
  DirectMeasureSettingsValueObject,
  EnvironmentConfigurationResponse,
  ErrorMessage,
  FeatureFlagResponse,
  GenerateImageTilesRequest,
  GiveFeedbackRequest,
  HTTPValidationError,
  ImageResponse,
  IngestAccessPageRequest,
  LicenceResponse,
  ListResponseImageGroupResponse,
  ListResponseProjectImageResponse,
  ListResponseProjectResponse,
  MeasurementSettingsValueObject,
  MetadataAnnotationOutput,
  ProjectBundleStatusResponse,
  ProjectClassAnnotationResponse,
  ProjectClassUpdateRequest,
  ProjectClassesAnnotationDistributionResponse,
  ProjectDatasetContext,
  ProjectImageProperties,
  ProjectImageResponse,
  ProjectImageSmartAnnotationRequest,
  ProjectImagesStatus,
  ProjectResponse,
  ProjectSettings,
  ProjectSettingsUpdateRequest,
  ProjectShareDetailsResponse,
  ProjectShareResponse,
  ProjectSharingDetailsRequest,
  ProjectTrainingSnapshotPredictionsStatus,
  ProjectTrainingSnapshotResponse,
  ProjectTrainingState,
  ProjectTrainingStatus,
  ProjectUpdateRequest,
  RedeemShareProjectResponse,
  ResetUserOnboardingTaskGroupRequest,
  ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost,
  SkipUserOnboardingTaskGroupRequest,
  Statistics,
  TrainingProgression,
  UpdateClassAnnotationsRequest,
  UserAssistanceRequest,
  UserLoginRequest,
  UserManagementResponse,
  UserOnboardingTasksResponse,
  UserOut,
  UserRegistrationRequest,
  UserRequestResetPasswordRequest,
  UserResetPasswordRequest,
  UserSettingsCanvasResponse,
  UserSettingsCanvasValueObject,
  UserUpdatePasswordRequest,
  UserUpdateProfileRequest,
} from '../models/index';
import {
    ActivateAccountRequestFromJSON,
    ActivateAccountRequestToJSON,
    AlgoClassFromJSON,
    AlgoClassToJSON,
    AnalyticDataDirectMeasuresFromJSON,
    AnalyticDataDirectMeasuresToJSON,
    AnalyticDataInstanceSegmentationFromJSON,
    AnalyticDataInstanceSegmentationToJSON,
    AnnotationPatchRequestFromJSON,
    AnnotationPatchRequestToJSON,
    CompleteUserOnboardingTaskRequestFromJSON,
    CompleteUserOnboardingTaskRequestToJSON,
    CompleteUserOnboardingTasksRequestFromJSON,
    CompleteUserOnboardingTasksRequestToJSON,
    DirectMeasureOutputFromJSON,
    DirectMeasureOutputToJSON,
    DirectMeasurePatchRequestFromJSON,
    DirectMeasurePatchRequestToJSON,
    DirectMeasureSettingsValueObjectFromJSON,
    DirectMeasureSettingsValueObjectToJSON,
    EnvironmentConfigurationResponseFromJSON,
    EnvironmentConfigurationResponseToJSON,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    FeatureFlagResponseFromJSON,
    FeatureFlagResponseToJSON,
    GenerateImageTilesRequestFromJSON,
    GenerateImageTilesRequestToJSON,
    GiveFeedbackRequestFromJSON,
    GiveFeedbackRequestToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ImageResponseFromJSON,
    ImageResponseToJSON,
    IngestAccessPageRequestFromJSON,
    IngestAccessPageRequestToJSON,
    LicenceResponseFromJSON,
    LicenceResponseToJSON,
    ListResponseImageGroupResponseFromJSON,
    ListResponseImageGroupResponseToJSON,
    ListResponseProjectImageResponseFromJSON,
    ListResponseProjectImageResponseToJSON,
    ListResponseProjectResponseFromJSON,
    ListResponseProjectResponseToJSON,
    MeasurementSettingsValueObjectFromJSON,
    MeasurementSettingsValueObjectToJSON,
    MetadataAnnotationOutputFromJSON,
    MetadataAnnotationOutputToJSON,
    ProjectBundleStatusResponseFromJSON,
    ProjectBundleStatusResponseToJSON,
    ProjectClassAnnotationResponseFromJSON,
    ProjectClassAnnotationResponseToJSON,
    ProjectClassUpdateRequestFromJSON,
    ProjectClassUpdateRequestToJSON,
    ProjectClassesAnnotationDistributionResponseFromJSON,
    ProjectClassesAnnotationDistributionResponseToJSON,
    ProjectDatasetContextFromJSON,
    ProjectDatasetContextToJSON,
    ProjectImagePropertiesFromJSON,
    ProjectImagePropertiesToJSON,
    ProjectImageResponseFromJSON,
    ProjectImageResponseToJSON,
    ProjectImageSmartAnnotationRequestFromJSON,
    ProjectImageSmartAnnotationRequestToJSON,
    ProjectImagesStatusFromJSON,
    ProjectImagesStatusToJSON,
    ProjectResponseFromJSON,
    ProjectResponseToJSON,
    ProjectSettingsFromJSON,
    ProjectSettingsToJSON,
    ProjectSettingsUpdateRequestFromJSON,
    ProjectSettingsUpdateRequestToJSON,
    ProjectShareDetailsResponseFromJSON,
    ProjectShareDetailsResponseToJSON,
    ProjectShareResponseFromJSON,
    ProjectShareResponseToJSON,
    ProjectSharingDetailsRequestFromJSON,
    ProjectSharingDetailsRequestToJSON,
    ProjectTrainingSnapshotPredictionsStatusFromJSON,
    ProjectTrainingSnapshotPredictionsStatusToJSON,
    ProjectTrainingSnapshotResponseFromJSON,
    ProjectTrainingSnapshotResponseToJSON,
    ProjectTrainingStateFromJSON,
    ProjectTrainingStateToJSON,
    ProjectTrainingStatusFromJSON,
    ProjectTrainingStatusToJSON,
    ProjectUpdateRequestFromJSON,
    ProjectUpdateRequestToJSON,
    RedeemShareProjectResponseFromJSON,
    RedeemShareProjectResponseToJSON,
    ResetUserOnboardingTaskGroupRequestFromJSON,
    ResetUserOnboardingTaskGroupRequestToJSON,
    ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostFromJSON,
    ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostToJSON,
    SkipUserOnboardingTaskGroupRequestFromJSON,
    SkipUserOnboardingTaskGroupRequestToJSON,
    StatisticsFromJSON,
    StatisticsToJSON,
    TrainingProgressionFromJSON,
    TrainingProgressionToJSON,
    UpdateClassAnnotationsRequestFromJSON,
    UpdateClassAnnotationsRequestToJSON,
    UserAssistanceRequestFromJSON,
    UserAssistanceRequestToJSON,
    UserLoginRequestFromJSON,
    UserLoginRequestToJSON,
    UserManagementResponseFromJSON,
    UserManagementResponseToJSON,
    UserOnboardingTasksResponseFromJSON,
    UserOnboardingTasksResponseToJSON,
    UserOutFromJSON,
    UserOutToJSON,
    UserRegistrationRequestFromJSON,
    UserRegistrationRequestToJSON,
    UserRequestResetPasswordRequestFromJSON,
    UserRequestResetPasswordRequestToJSON,
    UserResetPasswordRequestFromJSON,
    UserResetPasswordRequestToJSON,
    UserSettingsCanvasResponseFromJSON,
    UserSettingsCanvasResponseToJSON,
    UserSettingsCanvasValueObjectFromJSON,
    UserSettingsCanvasValueObjectToJSON,
    UserUpdatePasswordRequestFromJSON,
    UserUpdatePasswordRequestToJSON,
    UserUpdateProfileRequestFromJSON,
    UserUpdateProfileRequestToJSON,
} from '../models/index';

export interface ActivateAccountApiActivateAccountPostRequest {
    activateAccountRequest: ActivateAccountRequest;
    sessionId?: string | null;
}

export interface AddDemoImageApiImageDemoPostRequest {
    image: Blob;
    sessionId?: string | null;
}

export interface AddImageToDatasetApiProjectsProjectIdImageUploadPostRequest {
    projectId: string | null;
    image: Blob;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ArchiveImageApiImagesImageIdArchivePostRequest {
    imageId: string;
    sessionId?: string | null;
}

export interface ArchiveProjectApiProjectsProjectIdArchivePostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface CancelProjectTrainingApiAlgorithmTrainProjectIdCancelPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ClearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ClearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPostRequest {
    projectId: string | null;
    colorIndex: number;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface CompleteUserOnboardingTaskApiUserOnboardingTaskCompletePostRequest {
    completeUserOnboardingTaskRequest: CompleteUserOnboardingTaskRequest;
    sessionId?: string | null;
}

export interface CompleteUserOnboardingTasksApiUserOnboardingTaskCompleteListPostRequest {
    completeUserOnboardingTasksRequest: CompleteUserOnboardingTasksRequest;
    sessionId?: string | null;
}

export interface CreateProjectApiProjectsPostRequest {
    name: string;
    sessionId?: string | null;
    description?: string;
    projectArchive?: Blob | null;
}

export interface CreateProjectClassApiProjectsProjectIdClassPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface DeleteProjectClassApiProjectsProjectIdClassColorIndexDeleteRequest {
    projectId: string | null;
    colorIndex: number;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface DuplicateProjectApiProjectsProjectIdDuplicatePostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ExportProjectApiProjectsProjectIdExportGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GenerateImageTilesApiTasksGenerateImageTilesPostRequest {
    generateImageTilesRequest: GenerateImageTilesRequest;
    sessionId?: string | null;
}

export interface GetClemexAssetApiAssetsAssetIdGetRequest {
    assetId: string;
}

export interface GetDemoImagesApiImagesGroupDemoGetRequest {
    sessionId?: string | null;
}

export interface GetDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGetRequest {
    imageId: string;
    sessionId?: string | null;
}

export interface GetDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGetRequest {
    imageId: string;
    sessionId?: string | null;
}

export interface GetDownloadApiProjectsProjectIdBundleDownloadGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetFeatureFlagApiFeatureFlagsGetRequest {
    sessionId?: string | null;
}

export interface GetImageApiImagesImageIdGetRequest {
    imageId: string;
    sessionId?: string | null;
}

export interface GetImageInfoApiImagesImageIdProjectIdInfoGetRequest {
    imageId: string;
    projectId: string;
    sessionId?: string | null;
}

export interface GetImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGetRequest {
    imageId: string;
    projectSnapshotId: string;
    sessionId?: string | null;
}

export interface GetImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGetRequest {
    imageId: string;
    projectTrainingSnapshotId: string;
    width: number;
    height: number;
    sessionId?: string | null;
}

export interface GetImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGetRequest {
    imageId: string;
    projectTrainingSnapshotId: string;
    colorIndex: number;
    z: number;
    x: number;
    y: number;
    r?: number;
    g?: number;
    b?: number;
    sessionId?: string | null;
}

export interface GetImageThumbnailApiImagesImageIdThumbnailWidthHeightGetRequest {
    imageId: string;
    width: number;
    height: number;
    sessionId?: string | null;
}

export interface GetInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGetRequest {
    imageId: string;
    projectTrainingSnapshotId: string;
    sessionId?: string | null;
}

export interface GetInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGetRequest {
    imageId: string;
    projectTrainingSnapshotId: string;
    sessionId?: string | null;
}

export interface GetLicenceApiLicenceGetRequest {
    sessionId?: string | null;
}

export interface GetProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectClassesApiProjectsProjectIdClassGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectDatasetApiProjectsProjectIdDatasetContextGetRequest {
    projectId: string | null;
    context: ProjectDatasetContext;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGetRequest {
    projectId: string | null;
    imageId: string;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGetRequest {
    projectId: string | null;
    imageId: string;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPostRequest {
    projectId: string | null;
    imageId: string;
    projectImageSmartAnnotationRequest: ProjectImageSmartAnnotationRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectImagesStatusApiProjectProjectIdImagesStatusGetRequest {
    projectId: string;
    sessionId?: string | null;
}

export interface GetProjectListApiProjectsGetRequest {
    page?: number | null;
    limit?: number | null;
    sessionId?: string | null;
}

export interface GetProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectSettingsApiProjectsProjectIdSettingsGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectShareDetailsApiProjectsShareSharingTokenGetRequest {
    sharingToken: string;
    sessionId?: string | null;
}

export interface GetProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGetRequest {
    projectTrainingSnapshotId: string;
    sessionId?: string | null;
}

export interface GetProjectTrainingStateApiProjectsProjectIdTrainingStateGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetProjectTrainingStatusApiTrainProjectIdStatusGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetStatisticsApiStatisticsGetRequest {
    startDate?: Date | null;
    endDate?: Date | null;
    sessionId?: string | null;
}

export interface GetTileApiImagesImageIdTileZXYGetRequest {
    imageId: string;
    z: number;
    x: number;
    y: number;
    sessionId?: string | null;
}

export interface GetTrainingProgressionApiAlgorithmTrainProjectIdProgressionGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetUserProfileApiUserProfileGetRequest {
    sessionId?: string | null;
}

export interface GetUserProjectApiProjectsProjectIdGetRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface GetUserProjectApiProjectsSlugProjectSlugGetRequest {
    projectSlug: string | null;
    projectId?: string | null;
    sessionId?: string | null;
}

export interface GetUserSettingsApiSettingsGetRequest {
    sessionId?: string | null;
}

export interface GiveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPostRequest {
    giveFeedbackRequest: GiveFeedbackRequest;
    sessionId?: string | null;
}

export interface IngestAccessPageApiIngestAccessPagePostRequest {
    ingestAccessPageRequest: IngestAccessPageRequest;
    sessionId?: string | null;
}

export interface ListDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGetRequest {
    imageId: string;
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ListMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGetRequest {
    projectId: string | null;
    imageId: string;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ListUserOnboardingTaskApiUserOnboardingTaskGetRequest {
    sessionId?: string | null;
}

export interface LoginApiLoginPostRequest {
    userLoginRequest: UserLoginRequest;
    sessionId?: string | null;
}

export interface LogoutApiLogoutPostRequest {
    sessionId?: string | null;
}

export interface MicrosoftLoginApiMicrosoftLoginGetRequest {
    sessionId?: string | null;
}

export interface MicrosoftLoginRedirectionApiMicrosoftLoginRedirectionGetRequest {
    flowId?: string;
    sessionId?: string | null;
}

export interface PatchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatchRequest {
    projectId: string | null;
    imageId: string;
    annotationPatchRequest: AnnotationPatchRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface PatchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatchRequest {
    projectId: string | null;
    imageId: string;
    directMeasurePatchRequest: DirectMeasurePatchRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface PostProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPostRequest {
    projectId: string | null;
    imageId: string;
    updateClassAnnotationsRequest: UpdateClassAnnotationsRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface PrepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface RedeemProjectShareApiProjectsSharePostRequest {
    projectSharingDetailsRequest: ProjectSharingDetailsRequest;
    sessionId?: string | null;
}

export interface RegisterApiRegisterPostRequest {
    userRegistrationRequest: UserRegistrationRequest;
    sessionId?: string | null;
}

export interface RequestAssistanceApiRequestAssistancePostRequest {
    userAssistanceRequest: UserAssistanceRequest;
    sessionId?: string | null;
}

export interface RequestResetPasswordApiRequestResetPasswordPostRequest {
    userRequestResetPasswordRequest: UserRequestResetPasswordRequest;
    sessionId?: string | null;
}

export interface ResetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPostRequest {
    sessionId?: string | null;
}

export interface ResetPasswordApiResetPasswordPostRequest {
    userResetPasswordRequest: UserResetPasswordRequest;
    sessionId?: string | null;
}

export interface ResetProjectApiProjectsProjectIdResetPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ResetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ResetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface ResetUserOnboardingTaskGroupApiUserOnboardingTaskResetPostRequest {
    resetUserOnboardingTaskGroupRequest: ResetUserOnboardingTaskGroupRequest;
    sessionId?: string | null;
}

export interface ResetUserSettingsCanvasApiSettingsCanvasResetPostRequest {
    sessionId?: string | null;
}

export interface ShareProjectApiProjectsProjectIdSharePostRequest {
    projectId: string | null;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface SkipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPostRequest {
    sessionId?: string | null;
}

export interface SkipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPostRequest {
    skipUserOnboardingTaskGroupRequest: SkipUserOnboardingTaskGroupRequest;
    sessionId?: string | null;
}

export interface SwitchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPostRequest {
    projectId: string | null;
    imageId: string;
    context: ProjectDatasetContext;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface SynchronizeLicencesApiTasksSynchronizeLicencesPostRequest {
    sessionId?: string | null;
}

export interface UnarchiveImageApiImagesImageIdUnarchivePostRequest {
    imageId: string;
    sessionId?: string | null;
}

export interface UpdateImagePropertiesApiProjectsProjectIdImageImageIdPutRequest {
    projectId: string | null;
    imageId: string;
    projectImageProperties: ProjectImageProperties;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface UpdatePasswordApiUserPasswordPutRequest {
    userUpdatePasswordRequest: UserUpdatePasswordRequest;
    sessionId?: string | null;
}

export interface UpdateProfileApiUserProfilePutRequest {
    userUpdateProfileRequest: UserUpdateProfileRequest;
    sessionId?: string | null;
}

export interface UpdateProjectApiProjectsProjectIdPutRequest {
    projectId: string | null;
    projectUpdateRequest: ProjectUpdateRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface UpdateProjectClassApiProjectsProjectIdClassColorIndexPutRequest {
    projectId: string | null;
    colorIndex: number;
    projectClassUpdateRequest: ProjectClassUpdateRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface UpdateProjectSettingsApiProjectsProjectIdSettingsPutRequest {
    projectId: string | null;
    projectSettingsUpdateRequest: ProjectSettingsUpdateRequest;
    projectSlug?: string | null;
    sessionId?: string | null;
}

export interface UpdateUserSettingsCanvasApiSettingsCanvasPutRequest {
    userSettingsCanvasValueObject: UserSettingsCanvasValueObject;
    sessionId?: string | null;
}

export interface UseDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPostRequest {
    projectId: string | null;
    context: ProjectDatasetContext;
    imageId: string;
    projectSlug?: string | null;
    sessionId?: string | null;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Activate Account
     */
    async activateAccountApiActivateAccountPostRaw(requestParameters: ActivateAccountApiActivateAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.activateAccountRequest === null || requestParameters.activateAccountRequest === undefined) {
            throw new runtime.RequiredError('activateAccountRequest','Required parameter requestParameters.activateAccountRequest was null or undefined when calling activateAccountApiActivateAccountPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/activate-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateAccountRequestToJSON(requestParameters.activateAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Activate Account
     */
    async activateAccountApiActivateAccountPost(requestParameters: ActivateAccountApiActivateAccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.activateAccountApiActivateAccountPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Demo Image
     */
    async addDemoImageApiImageDemoPostRaw(requestParameters: AddDemoImageApiImageDemoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImageResponse>> {
        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling addDemoImageApiImageDemoPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/api/image/demo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageResponseFromJSON(jsonValue));
    }

    /**
     * Add Demo Image
     */
    async addDemoImageApiImageDemoPost(requestParameters: AddDemoImageApiImageDemoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageResponse> {
        const response = await this.addDemoImageApiImageDemoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Image To Dataset
     */
    async addImageToDatasetApiProjectsProjectIdImageUploadPostRaw(requestParameters: AddImageToDatasetApiProjectsProjectIdImageUploadPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectImageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addImageToDatasetApiProjectsProjectIdImageUploadPost.');
        }

        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling addImageToDatasetApiProjectsProjectIdImageUploadPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/api/projects/{project_id}/image/upload`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectImageResponseFromJSON(jsonValue));
    }

    /**
     * Add Image To Dataset
     */
    async addImageToDatasetApiProjectsProjectIdImageUploadPost(requestParameters: AddImageToDatasetApiProjectsProjectIdImageUploadPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectImageResponse> {
        const response = await this.addImageToDatasetApiProjectsProjectIdImageUploadPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive Image
     */
    async archiveImageApiImagesImageIdArchivePostRaw(requestParameters: ArchiveImageApiImagesImageIdArchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling archiveImageApiImagesImageIdArchivePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/archive`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Archive Image
     */
    async archiveImageApiImagesImageIdArchivePost(requestParameters: ArchiveImageApiImagesImageIdArchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.archiveImageApiImagesImageIdArchivePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive Project
     */
    async archiveProjectApiProjectsProjectIdArchivePostRaw(requestParameters: ArchiveProjectApiProjectsProjectIdArchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling archiveProjectApiProjectsProjectIdArchivePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/archive`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Archive Project
     */
    async archiveProjectApiProjectsProjectIdArchivePost(requestParameters: ArchiveProjectApiProjectsProjectIdArchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.archiveProjectApiProjectsProjectIdArchivePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel Project Training
     */
    async cancelProjectTrainingApiAlgorithmTrainProjectIdCancelPostRaw(requestParameters: CancelProjectTrainingApiAlgorithmTrainProjectIdCancelPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling cancelProjectTrainingApiAlgorithmTrainProjectIdCancelPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/algorithm/train/{project_id}/cancel`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cancel Project Training
     */
    async cancelProjectTrainingApiAlgorithmTrainProjectIdCancelPost(requestParameters: CancelProjectTrainingApiAlgorithmTrainProjectIdCancelPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.cancelProjectTrainingApiAlgorithmTrainProjectIdCancelPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Clear all the class annotations of a project.
     * Clear Project Class Annotations
     */
    async clearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPostRaw(requestParameters: ClearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling clearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/clear_class_annotations`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Clear all the class annotations of a project.
     * Clear Project Class Annotations
     */
    async clearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPost(requestParameters: ClearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.clearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Clear all the class_annotations of a project for a given color index.
     * Clear Project Class Annotations By Color Index
     */
    async clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPostRaw(requestParameters: ClearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPost.');
        }

        if (requestParameters.colorIndex === null || requestParameters.colorIndex === undefined) {
            throw new runtime.RequiredError('colorIndex','Required parameter requestParameters.colorIndex was null or undefined when calling clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/clear_class_annotations/{color_index}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"color_index"}}`, encodeURIComponent(String(requestParameters.colorIndex))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Clear all the class_annotations of a project for a given color index.
     * Clear Project Class Annotations By Color Index
     */
    async clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPost(requestParameters: ClearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete User Onboarding Task
     */
    async completeUserOnboardingTaskApiUserOnboardingTaskCompletePostRaw(requestParameters: CompleteUserOnboardingTaskApiUserOnboardingTaskCompletePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.completeUserOnboardingTaskRequest === null || requestParameters.completeUserOnboardingTaskRequest === undefined) {
            throw new runtime.RequiredError('completeUserOnboardingTaskRequest','Required parameter requestParameters.completeUserOnboardingTaskRequest was null or undefined when calling completeUserOnboardingTaskApiUserOnboardingTaskCompletePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_onboarding_task/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteUserOnboardingTaskRequestToJSON(requestParameters.completeUserOnboardingTaskRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Complete User Onboarding Task
     */
    async completeUserOnboardingTaskApiUserOnboardingTaskCompletePost(requestParameters: CompleteUserOnboardingTaskApiUserOnboardingTaskCompletePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.completeUserOnboardingTaskApiUserOnboardingTaskCompletePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete onboarding tasks in one call.
     * Complete User Onboarding Tasks
     */
    async completeUserOnboardingTasksApiUserOnboardingTaskCompleteListPostRaw(requestParameters: CompleteUserOnboardingTasksApiUserOnboardingTaskCompleteListPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.completeUserOnboardingTasksRequest === null || requestParameters.completeUserOnboardingTasksRequest === undefined) {
            throw new runtime.RequiredError('completeUserOnboardingTasksRequest','Required parameter requestParameters.completeUserOnboardingTasksRequest was null or undefined when calling completeUserOnboardingTasksApiUserOnboardingTaskCompleteListPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_onboarding_task/complete_list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteUserOnboardingTasksRequestToJSON(requestParameters.completeUserOnboardingTasksRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Complete onboarding tasks in one call.
     * Complete User Onboarding Tasks
     */
    async completeUserOnboardingTasksApiUserOnboardingTaskCompleteListPost(requestParameters: CompleteUserOnboardingTasksApiUserOnboardingTaskCompleteListPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.completeUserOnboardingTasksApiUserOnboardingTaskCompleteListPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Project
     */
    async createProjectApiProjectsPostRaw(requestParameters: CreateProjectApiProjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createProjectApiProjectsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.projectArchive !== undefined) {
            formParams.append('project_archive', requestParameters.projectArchive as any);
        }

        const response = await this.request({
            path: `/api/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Create Project
     */
    async createProjectApiProjectsPost(requestParameters: CreateProjectApiProjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.createProjectApiProjectsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Project Class
     */
    async createProjectClassApiProjectsProjectIdClassPostRaw(requestParameters: CreateProjectClassApiProjectsProjectIdClassPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlgoClass>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectClassApiProjectsProjectIdClassPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/class`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlgoClassFromJSON(jsonValue));
    }

    /**
     * Create Project Class
     */
    async createProjectClassApiProjectsProjectIdClassPost(requestParameters: CreateProjectClassApiProjectsProjectIdClassPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlgoClass> {
        const response = await this.createProjectClassApiProjectsProjectIdClassPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Project Class
     */
    async deleteProjectClassApiProjectsProjectIdClassColorIndexDeleteRaw(requestParameters: DeleteProjectClassApiProjectsProjectIdClassColorIndexDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectClassApiProjectsProjectIdClassColorIndexDelete.');
        }

        if (requestParameters.colorIndex === null || requestParameters.colorIndex === undefined) {
            throw new runtime.RequiredError('colorIndex','Required parameter requestParameters.colorIndex was null or undefined when calling deleteProjectClassApiProjectsProjectIdClassColorIndexDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/class/{color_index}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"color_index"}}`, encodeURIComponent(String(requestParameters.colorIndex))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Project Class
     */
    async deleteProjectClassApiProjectsProjectIdClassColorIndexDelete(requestParameters: DeleteProjectClassApiProjectsProjectIdClassColorIndexDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteProjectClassApiProjectsProjectIdClassColorIndexDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Project
     */
    async duplicateProjectApiProjectsProjectIdDuplicatePostRaw(requestParameters: DuplicateProjectApiProjectsProjectIdDuplicatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling duplicateProjectApiProjectsProjectIdDuplicatePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/duplicate`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate Project
     */
    async duplicateProjectApiProjectsProjectIdDuplicatePost(requestParameters: DuplicateProjectApiProjectsProjectIdDuplicatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.duplicateProjectApiProjectsProjectIdDuplicatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export the project and return the zip file.
     * Export Project
     */
    async exportProjectApiProjectsProjectIdExportGetRaw(requestParameters: ExportProjectApiProjectsProjectIdExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProjectApiProjectsProjectIdExportGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export the project and return the zip file.
     * Export Project
     */
    async exportProjectApiProjectsProjectIdExportGet(requestParameters: ExportProjectApiProjectsProjectIdExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.exportProjectApiProjectsProjectIdExportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate Image Tiles
     */
    async generateImageTilesApiTasksGenerateImageTilesPostRaw(requestParameters: GenerateImageTilesApiTasksGenerateImageTilesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.generateImageTilesRequest === null || requestParameters.generateImageTilesRequest === undefined) {
            throw new runtime.RequiredError('generateImageTilesRequest','Required parameter requestParameters.generateImageTilesRequest was null or undefined when calling generateImageTilesApiTasksGenerateImageTilesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/_tasks/generate_image_tiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateImageTilesRequestToJSON(requestParameters.generateImageTilesRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate Image Tiles
     */
    async generateImageTilesApiTasksGenerateImageTilesPost(requestParameters: GenerateImageTilesApiTasksGenerateImageTilesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.generateImageTilesApiTasksGenerateImageTilesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Clemex Asset
     */
    async getClemexAssetApiAssetsAssetIdGetRaw(requestParameters: GetClemexAssetApiAssetsAssetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getClemexAssetApiAssetsAssetIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/{asset_id}`.replace(`{${"asset_id"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Clemex Asset
     */
    async getClemexAssetApiAssetsAssetIdGet(requestParameters: GetClemexAssetApiAssetsAssetIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getClemexAssetApiAssetsAssetIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Demo Images
     */
    async getDemoImagesApiImagesGroupDemoGetRaw(requestParameters: GetDemoImagesApiImagesGroupDemoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListResponseImageGroupResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/group/demo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListResponseImageGroupResponseFromJSON(jsonValue));
    }

    /**
     * Get Demo Images
     */
    async getDemoImagesApiImagesGroupDemoGet(requestParameters: GetDemoImagesApiImagesGroupDemoGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListResponseImageGroupResponse> {
        const response = await this.getDemoImagesApiImagesGroupDemoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get direct measure with stats for an image
     * Get Direct Measure With Stats
     */
    async getDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGetRaw(requestParameters: GetDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalyticDataDirectMeasures>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytic-data/{image_id}/direct_measures`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalyticDataDirectMeasuresFromJSON(jsonValue));
    }

    /**
     * Get direct measure with stats for an image
     * Get Direct Measure With Stats
     */
    async getDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGet(requestParameters: GetDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalyticDataDirectMeasures> {
        const response = await this.getDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a CSV with direct measure with stats for an image
     * Get Direct Measure With Stats Csv
     */
    async getDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGetRaw(requestParameters: GetDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytic-data/{image_id}/direct_measures.csv`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get a CSV with direct measure with stats for an image
     * Get Direct Measure With Stats Csv
     */
    async getDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGet(requestParameters: GetDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getDirectMeasureWithStatsCsvApiAnalyticDataImageIdDirectMeasuresCsvGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the download bundle for a given project. Returns:     Response: The HTTP response containing the download bundle.
     * Get Download
     */
    async getDownloadApiProjectsProjectIdBundleDownloadGetRaw(requestParameters: GetDownloadApiProjectsProjectIdBundleDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDownloadApiProjectsProjectIdBundleDownloadGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/bundle/download`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieves the download bundle for a given project. Returns:     Response: The HTTP response containing the download bundle.
     * Get Download
     */
    async getDownloadApiProjectsProjectIdBundleDownloadGet(requestParameters: GetDownloadApiProjectsProjectIdBundleDownloadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getDownloadApiProjectsProjectIdBundleDownloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the download bundle for a given project. Returns:     Response: The HTTP response containing the download bundle.
     * Get Download
     */
    async getDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGetRaw(requestParameters: GetDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/bundle/download/{target_platform}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieves the download bundle for a given project. Returns:     Response: The HTTP response containing the download bundle.
     * Get Download
     */
    async getDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGet(requestParameters: GetDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getDownloadApiProjectsProjectIdBundleDownloadTargetPlatformGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Environment Configuration
     */
    async getEnvironmentConfigurationApiEnvironmentGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentConfigurationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/environment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * Get Environment Configuration
     */
    async getEnvironmentConfigurationApiEnvironmentGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentConfigurationResponse> {
        const response = await this.getEnvironmentConfigurationApiEnvironmentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Feature Flag
     */
    async getFeatureFlagApiFeatureFlagsGetRaw(requestParameters: GetFeatureFlagApiFeatureFlagsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeatureFlagResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/feature_flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagResponseFromJSON(jsonValue));
    }

    /**
     * Get Feature Flag
     */
    async getFeatureFlagApiFeatureFlagsGet(requestParameters: GetFeatureFlagApiFeatureFlagsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeatureFlagResponse> {
        const response = await this.getFeatureFlagApiFeatureFlagsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Image
     */
    async getImageApiImagesImageIdGetRaw(requestParameters: GetImageApiImagesImageIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImageApiImagesImageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Image
     */
    async getImageApiImagesImageIdGet(requestParameters: GetImageApiImagesImageIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getImageApiImagesImageIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Image Info
     */
    async getImageInfoApiImagesImageIdProjectIdInfoGetRaw(requestParameters: GetImageInfoApiImagesImageIdProjectIdInfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectImageResponse>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImageInfoApiImagesImageIdProjectIdInfoGet.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getImageInfoApiImagesImageIdProjectIdInfoGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/{project_id}/info`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectImageResponseFromJSON(jsonValue));
    }

    /**
     * Get Image Info
     */
    async getImageInfoApiImagesImageIdProjectIdInfoGet(requestParameters: GetImageInfoApiImagesImageIdProjectIdInfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectImageResponse> {
        const response = await this.getImageInfoApiImagesImageIdProjectIdInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Image Prediction Mask
     */
    async getImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGetRaw(requestParameters: GetImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGet.');
        }

        if (requestParameters.projectSnapshotId === null || requestParameters.projectSnapshotId === undefined) {
            throw new runtime.RequiredError('projectSnapshotId','Required parameter requestParameters.projectSnapshotId was null or undefined when calling getImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/prediction_mask/{project_snapshot_id}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectSnapshotId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Image Prediction Mask
     */
    async getImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGet(requestParameters: GetImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getImagePredictionMaskApiImagesImageIdPredictionMaskProjectSnapshotIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the prediction thumbnail for the image accordint to the project training snapshot.  The size of the image is defined by the width and height parameters. For now we use the clemex vision color palette as it is not parametrable.
     * Get Image Prediction Thumbnail
     */
    async getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGetRaw(requestParameters: GetImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGet.');
        }

        if (requestParameters.projectTrainingSnapshotId === null || requestParameters.projectTrainingSnapshotId === undefined) {
            throw new runtime.RequiredError('projectTrainingSnapshotId','Required parameter requestParameters.projectTrainingSnapshotId was null or undefined when calling getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGet.');
        }

        if (requestParameters.width === null || requestParameters.width === undefined) {
            throw new runtime.RequiredError('width','Required parameter requestParameters.width was null or undefined when calling getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGet.');
        }

        if (requestParameters.height === null || requestParameters.height === undefined) {
            throw new runtime.RequiredError('height','Required parameter requestParameters.height was null or undefined when calling getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/prediction/{project_training_snapshot_id}/thumbnail/{width}/{height}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_training_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectTrainingSnapshotId))).replace(`{${"width"}}`, encodeURIComponent(String(requestParameters.width))).replace(`{${"height"}}`, encodeURIComponent(String(requestParameters.height))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the prediction thumbnail for the image accordint to the project training snapshot.  The size of the image is defined by the width and height parameters. For now we use the clemex vision color palette as it is not parametrable.
     * Get Image Prediction Thumbnail
     */
    async getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGet(requestParameters: GetImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getImagePredictionThumbnailApiImagesImageIdPredictionProjectTrainingSnapshotIdThumbnailWidthHeightGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the tile for the given prediction mask at the given zoom level and coordinates.
     * Get Image Prediction Tile
     */
    async getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGetRaw(requestParameters: GetImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        if (requestParameters.projectTrainingSnapshotId === null || requestParameters.projectTrainingSnapshotId === undefined) {
            throw new runtime.RequiredError('projectTrainingSnapshotId','Required parameter requestParameters.projectTrainingSnapshotId was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        if (requestParameters.colorIndex === null || requestParameters.colorIndex === undefined) {
            throw new runtime.RequiredError('colorIndex','Required parameter requestParameters.colorIndex was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        if (requestParameters.z === null || requestParameters.z === undefined) {
            throw new runtime.RequiredError('z','Required parameter requestParameters.z was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.r !== undefined) {
            queryParameters['r'] = requestParameters.r;
        }

        if (requestParameters.g !== undefined) {
            queryParameters['g'] = requestParameters.g;
        }

        if (requestParameters.b !== undefined) {
            queryParameters['b'] = requestParameters.b;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/prediction/{project_training_snapshot_id}/mask/{color_index}/tile/{z}/{x}/{y}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_training_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectTrainingSnapshotId))).replace(`{${"color_index"}}`, encodeURIComponent(String(requestParameters.colorIndex))).replace(`{${"z"}}`, encodeURIComponent(String(requestParameters.z))).replace(`{${"x"}}`, encodeURIComponent(String(requestParameters.x))).replace(`{${"y"}}`, encodeURIComponent(String(requestParameters.y))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the tile for the given prediction mask at the given zoom level and coordinates.
     * Get Image Prediction Tile
     */
    async getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGet(requestParameters: GetImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getImagePredictionTileApiImagesImageIdPredictionProjectTrainingSnapshotIdMaskColorIndexTileZXYGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Image Thumbnail
     */
    async getImageThumbnailApiImagesImageIdThumbnailWidthHeightGetRaw(requestParameters: GetImageThumbnailApiImagesImageIdThumbnailWidthHeightGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getImageThumbnailApiImagesImageIdThumbnailWidthHeightGet.');
        }

        if (requestParameters.width === null || requestParameters.width === undefined) {
            throw new runtime.RequiredError('width','Required parameter requestParameters.width was null or undefined when calling getImageThumbnailApiImagesImageIdThumbnailWidthHeightGet.');
        }

        if (requestParameters.height === null || requestParameters.height === undefined) {
            throw new runtime.RequiredError('height','Required parameter requestParameters.height was null or undefined when calling getImageThumbnailApiImagesImageIdThumbnailWidthHeightGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/thumbnail/{width}/{height}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"width"}}`, encodeURIComponent(String(requestParameters.width))).replace(`{${"height"}}`, encodeURIComponent(String(requestParameters.height))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Image Thumbnail
     */
    async getImageThumbnailApiImagesImageIdThumbnailWidthHeightGet(requestParameters: GetImageThumbnailApiImagesImageIdThumbnailWidthHeightGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getImageThumbnailApiImagesImageIdThumbnailWidthHeightGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Instance Segmentation data for an image
     * Get Instance Segmentation
     */
    async getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGetRaw(requestParameters: GetInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalyticDataInstanceSegmentation>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGet.');
        }

        if (requestParameters.projectTrainingSnapshotId === null || requestParameters.projectTrainingSnapshotId === undefined) {
            throw new runtime.RequiredError('projectTrainingSnapshotId','Required parameter requestParameters.projectTrainingSnapshotId was null or undefined when calling getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytic-data/{image_id}/{project_training_snapshot_id}/prediction_detection_objects`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_training_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectTrainingSnapshotId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalyticDataInstanceSegmentationFromJSON(jsonValue));
    }

    /**
     * Get Instance Segmentation data for an image
     * Get Instance Segmentation
     */
    async getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGet(requestParameters: GetInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalyticDataInstanceSegmentation> {
        const response = await this.getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a CSV file of Instance Segmentation data for an image
     * Get Instance Segmentation Csv
     */
    async getInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGetRaw(requestParameters: GetInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGet.');
        }

        if (requestParameters.projectTrainingSnapshotId === null || requestParameters.projectTrainingSnapshotId === undefined) {
            throw new runtime.RequiredError('projectTrainingSnapshotId','Required parameter requestParameters.projectTrainingSnapshotId was null or undefined when calling getInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytic-data/{image_id}/{project_training_snapshot_id}/prediction_detection_objects.csv`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_training_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectTrainingSnapshotId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get a CSV file of Instance Segmentation data for an image
     * Get Instance Segmentation Csv
     */
    async getInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGet(requestParameters: GetInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getInstanceSegmentationCsvApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsCsvGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an user\'s licence
     * Get Licence
     */
    async getLicenceApiLicenceGetRaw(requestParameters: GetLicenceApiLicenceGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LicenceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/licence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenceResponseFromJSON(jsonValue));
    }

    /**
     * Get an user\'s licence
     * Get Licence
     */
    async getLicenceApiLicenceGet(requestParameters: GetLicenceApiLicenceGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LicenceResponse> {
        const response = await this.getLicenceApiLicenceGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Class Annotations
     */
    async getProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGetRaw(requestParameters: GetProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectClassAnnotationResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/class_annotations`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClassAnnotationResponseFromJSON(jsonValue));
    }

    /**
     * Get Project Class Annotations
     */
    async getProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGet(requestParameters: GetProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectClassAnnotationResponse> {
        const response = await this.getProjectClassAnnotationsApiProjectsProjectIdClassAnnotationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Classes
     */
    async getProjectClassesApiProjectsProjectIdClassGetRaw(requestParameters: GetProjectClassesApiProjectsProjectIdClassGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AlgoClass>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectClassesApiProjectsProjectIdClassGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/class`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlgoClassFromJSON));
    }

    /**
     * Get Project Classes
     */
    async getProjectClassesApiProjectsProjectIdClassGet(requestParameters: GetProjectClassesApiProjectsProjectIdClassGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AlgoClass>> {
        const response = await this.getProjectClassesApiProjectsProjectIdClassGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Dataset
     */
    async getProjectDatasetApiProjectsProjectIdDatasetContextGetRaw(requestParameters: GetProjectDatasetApiProjectsProjectIdDatasetContextGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListResponseProjectImageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectDatasetApiProjectsProjectIdDatasetContextGet.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling getProjectDatasetApiProjectsProjectIdDatasetContextGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/dataset/{context}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"context"}}`, encodeURIComponent(String(requestParameters.context))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListResponseProjectImageResponseFromJSON(jsonValue));
    }

    /**
     * Get Project Dataset
     */
    async getProjectDatasetApiProjectsProjectIdDatasetContextGet(requestParameters: GetProjectDatasetApiProjectsProjectIdDatasetContextGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListResponseProjectImageResponse> {
        const response = await this.getProjectDatasetApiProjectsProjectIdDatasetContextGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return the class annotations of a project for an image with same order as it was received.
     * Get Project Image Annotations
     */
    async getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGetRaw(requestParameters: GetProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectClassAnnotationResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGet.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/class_annotations`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClassAnnotationResponseFromJSON(jsonValue));
    }

    /**
     * Return the class annotations of a project for an image with same order as it was received.
     * Get Project Image Annotations
     */
    async getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGet(requestParameters: GetProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectClassAnnotationResponse> {
        const response = await this.getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Image Classes Annotation Distribution
     */
    async getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGetRaw(requestParameters: GetProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectClassesAnnotationDistributionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGet.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/classes_annotation_distribution`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClassesAnnotationDistributionResponseFromJSON(jsonValue));
    }

    /**
     * Get Project Image Classes Annotation Distribution
     */
    async getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGet(requestParameters: GetProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectClassesAnnotationDistributionResponse> {
        const response = await this.getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the smart annotation for an image.
     * Get Project Image Smart Annotation
     */
    async getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPostRaw(requestParameters: GetProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectClassAnnotationResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPost.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPost.');
        }

        if (requestParameters.projectImageSmartAnnotationRequest === null || requestParameters.projectImageSmartAnnotationRequest === undefined) {
            throw new runtime.RequiredError('projectImageSmartAnnotationRequest','Required parameter requestParameters.projectImageSmartAnnotationRequest was null or undefined when calling getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/smart_annotation`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectImageSmartAnnotationRequestToJSON(requestParameters.projectImageSmartAnnotationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClassAnnotationResponseFromJSON(jsonValue));
    }

    /**
     * Get the smart annotation for an image.
     * Get Project Image Smart Annotation
     */
    async getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPost(requestParameters: GetProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectClassAnnotationResponse> {
        const response = await this.getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Images Status
     */
    async getProjectImagesStatusApiProjectProjectIdImagesStatusGetRaw(requestParameters: GetProjectImagesStatusApiProjectProjectIdImagesStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectImagesStatus>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectImagesStatusApiProjectProjectIdImagesStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{project_id}/images/status`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectImagesStatusFromJSON(jsonValue));
    }

    /**
     * Get Project Images Status
     */
    async getProjectImagesStatusApiProjectProjectIdImagesStatusGet(requestParameters: GetProjectImagesStatusApiProjectProjectIdImagesStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectImagesStatus> {
        const response = await this.getProjectImagesStatusApiProjectProjectIdImagesStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project List
     */
    async getProjectListApiProjectsGetRaw(requestParameters: GetProjectListApiProjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListResponseProjectResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListResponseProjectResponseFromJSON(jsonValue));
    }

    /**
     * Get Project List
     */
    async getProjectListApiProjectsGet(requestParameters: GetProjectListApiProjectsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListResponseProjectResponse> {
        const response = await this.getProjectListApiProjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Plugin Bundle Status
     */
    async getProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPostRaw(requestParameters: GetProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectBundleStatusResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/bundle/status`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectBundleStatusResponseFromJSON(jsonValue));
    }

    /**
     * Get Project Plugin Bundle Status
     */
    async getProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPost(requestParameters: GetProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectBundleStatusResponse> {
        const response = await this.getProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the project settings.
     * Get Project Settings
     */
    async getProjectSettingsApiProjectsProjectIdSettingsGetRaw(requestParameters: GetProjectSettingsApiProjectsProjectIdSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectSettings>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectSettingsApiProjectsProjectIdSettingsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/settings`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSettingsFromJSON(jsonValue));
    }

    /**
     * Get the project settings.
     * Get Project Settings
     */
    async getProjectSettingsApiProjectsProjectIdSettingsGet(requestParameters: GetProjectSettingsApiProjectsProjectIdSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectSettings> {
        const response = await this.getProjectSettingsApiProjectsProjectIdSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Share Details
     */
    async getProjectShareDetailsApiProjectsShareSharingTokenGetRaw(requestParameters: GetProjectShareDetailsApiProjectsShareSharingTokenGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectShareDetailsResponse>> {
        if (requestParameters.sharingToken === null || requestParameters.sharingToken === undefined) {
            throw new runtime.RequiredError('sharingToken','Required parameter requestParameters.sharingToken was null or undefined when calling getProjectShareDetailsApiProjectsShareSharingTokenGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/share/{sharing_token}`.replace(`{${"sharing_token"}}`, encodeURIComponent(String(requestParameters.sharingToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectShareDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get Project Share Details
     */
    async getProjectShareDetailsApiProjectsShareSharingTokenGet(requestParameters: GetProjectShareDetailsApiProjectsShareSharingTokenGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectShareDetailsResponse> {
        const response = await this.getProjectShareDetailsApiProjectsShareSharingTokenGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the training snapshot of a project.
     * Get Project Training Snapshot
     */
    async getProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGetRaw(requestParameters: GetProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTrainingSnapshotResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/training_snapshot`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTrainingSnapshotResponseFromJSON(jsonValue));
    }

    /**
     * Get the training snapshot of a project.
     * Get Project Training Snapshot
     */
    async getProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGet(requestParameters: GetProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTrainingSnapshotResponse> {
        const response = await this.getProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Training Snapshot Prediction Status
     */
    async getProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGetRaw(requestParameters: GetProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTrainingSnapshotPredictionsStatus>> {
        if (requestParameters.projectTrainingSnapshotId === null || requestParameters.projectTrainingSnapshotId === undefined) {
            throw new runtime.RequiredError('projectTrainingSnapshotId','Required parameter requestParameters.projectTrainingSnapshotId was null or undefined when calling getProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/predict/{project_training_snapshot_id}/status`.replace(`{${"project_training_snapshot_id"}}`, encodeURIComponent(String(requestParameters.projectTrainingSnapshotId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTrainingSnapshotPredictionsStatusFromJSON(jsonValue));
    }

    /**
     * Get Project Training Snapshot Prediction Status
     */
    async getProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGet(requestParameters: GetProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTrainingSnapshotPredictionsStatus> {
        const response = await this.getProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Training State
     */
    async getProjectTrainingStateApiProjectsProjectIdTrainingStateGetRaw(requestParameters: GetProjectTrainingStateApiProjectsProjectIdTrainingStateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTrainingState>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTrainingStateApiProjectsProjectIdTrainingStateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/training_state`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTrainingStateFromJSON(jsonValue));
    }

    /**
     * Get Project Training State
     */
    async getProjectTrainingStateApiProjectsProjectIdTrainingStateGet(requestParameters: GetProjectTrainingStateApiProjectsProjectIdTrainingStateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTrainingState> {
        const response = await this.getProjectTrainingStateApiProjectsProjectIdTrainingStateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Training Status
     */
    async getProjectTrainingStatusApiTrainProjectIdStatusGetRaw(requestParameters: GetProjectTrainingStatusApiTrainProjectIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTrainingStatus>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTrainingStatusApiTrainProjectIdStatusGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/train/{project_id}/status`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTrainingStatusFromJSON(jsonValue));
    }

    /**
     * Get Project Training Status
     */
    async getProjectTrainingStatusApiTrainProjectIdStatusGet(requestParameters: GetProjectTrainingStatusApiTrainProjectIdStatusGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTrainingStatus> {
        const response = await this.getProjectTrainingStatusApiTrainProjectIdStatusGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Statistics
     */
    async getStatisticsApiStatisticsGetRaw(requestParameters: GetStatisticsApiStatisticsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statistics>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatisticsFromJSON(jsonValue));
    }

    /**
     * Get Statistics
     */
    async getStatisticsApiStatisticsGet(requestParameters: GetStatisticsApiStatisticsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statistics> {
        const response = await this.getStatisticsApiStatisticsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the tile for the given image at the given zoom level and coordinates.  Note:     The routes starts with /images/{image_id}/tile     to highlight that this router is an \"extension\" of the image router     and that the image_id is required to access the tiles
     * Get Tile
     */
    async getTileApiImagesImageIdTileZXYGetRaw(requestParameters: GetTileApiImagesImageIdTileZXYGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling getTileApiImagesImageIdTileZXYGet.');
        }

        if (requestParameters.z === null || requestParameters.z === undefined) {
            throw new runtime.RequiredError('z','Required parameter requestParameters.z was null or undefined when calling getTileApiImagesImageIdTileZXYGet.');
        }

        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling getTileApiImagesImageIdTileZXYGet.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling getTileApiImagesImageIdTileZXYGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/tile/{z}/{x}/{y}`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"z"}}`, encodeURIComponent(String(requestParameters.z))).replace(`{${"x"}}`, encodeURIComponent(String(requestParameters.x))).replace(`{${"y"}}`, encodeURIComponent(String(requestParameters.y))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the tile for the given image at the given zoom level and coordinates.  Note:     The routes starts with /images/{image_id}/tile     to highlight that this router is an \"extension\" of the image router     and that the image_id is required to access the tiles
     * Get Tile
     */
    async getTileApiImagesImageIdTileZXYGet(requestParameters: GetTileApiImagesImageIdTileZXYGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getTileApiImagesImageIdTileZXYGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the training progression of a project
     * Get Training Progression
     */
    async getTrainingProgressionApiAlgorithmTrainProjectIdProgressionGetRaw(requestParameters: GetTrainingProgressionApiAlgorithmTrainProjectIdProgressionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainingProgression>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getTrainingProgressionApiAlgorithmTrainProjectIdProgressionGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/algorithm/train/{project_id}/progression`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingProgressionFromJSON(jsonValue));
    }

    /**
     * Get the training progression of a project
     * Get Training Progression
     */
    async getTrainingProgressionApiAlgorithmTrainProjectIdProgressionGet(requestParameters: GetTrainingProgressionApiAlgorithmTrainProjectIdProgressionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainingProgression> {
        const response = await this.getTrainingProgressionApiAlgorithmTrainProjectIdProgressionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Profile
     */
    async getUserProfileApiUserProfileGetRaw(requestParameters: GetUserProfileApiUserProfileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOut>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutFromJSON(jsonValue));
    }

    /**
     * Get User Profile
     */
    async getUserProfileApiUserProfileGet(requestParameters: GetUserProfileApiUserProfileGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOut> {
        const response = await this.getUserProfileApiUserProfileGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Project
     */
    async getUserProjectApiProjectsProjectIdGetRaw(requestParameters: GetUserProjectApiProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getUserProjectApiProjectsProjectIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Get User Project
     */
    async getUserProjectApiProjectsProjectIdGet(requestParameters: GetUserProjectApiProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.getUserProjectApiProjectsProjectIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get User Project
     */
    async getUserProjectApiProjectsSlugProjectSlugGetRaw(requestParameters: GetUserProjectApiProjectsSlugProjectSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.projectSlug === null || requestParameters.projectSlug === undefined) {
            throw new runtime.RequiredError('projectSlug','Required parameter requestParameters.projectSlug was null or undefined when calling getUserProjectApiProjectsSlugProjectSlugGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/slug/{project_slug}`.replace(`{${"project_slug"}}`, encodeURIComponent(String(requestParameters.projectSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Get User Project
     */
    async getUserProjectApiProjectsSlugProjectSlugGet(requestParameters: GetUserProjectApiProjectsSlugProjectSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.getUserProjectApiProjectsSlugProjectSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the user settings.
     * Get User Settings
     */
    async getUserSettingsApiSettingsGetRaw(requestParameters: GetUserSettingsApiSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettingsCanvasResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsCanvasResponseFromJSON(jsonValue));
    }

    /**
     * Get the user settings.
     * Get User Settings
     */
    async getUserSettingsApiSettingsGet(requestParameters: GetUserSettingsApiSettingsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettingsCanvasResponse> {
        const response = await this.getUserSettingsApiSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Give Completed Onboarding Feedback
     */
    async giveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPostRaw(requestParameters: GiveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.giveFeedbackRequest === null || requestParameters.giveFeedbackRequest === undefined) {
            throw new runtime.RequiredError('giveFeedbackRequest','Required parameter requestParameters.giveFeedbackRequest was null or undefined when calling giveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_feedback/completed_onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GiveFeedbackRequestToJSON(requestParameters.giveFeedbackRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Give Completed Onboarding Feedback
     */
    async giveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPost(requestParameters: GiveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.giveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ingest Access Page
     */
    async ingestAccessPageApiIngestAccessPagePostRaw(requestParameters: IngestAccessPageApiIngestAccessPagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.ingestAccessPageRequest === null || requestParameters.ingestAccessPageRequest === undefined) {
            throw new runtime.RequiredError('ingestAccessPageRequest','Required parameter requestParameters.ingestAccessPageRequest was null or undefined when calling ingestAccessPageApiIngestAccessPagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ingest_access_page`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IngestAccessPageRequestToJSON(requestParameters.ingestAccessPageRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Ingest Access Page
     */
    async ingestAccessPageApiIngestAccessPagePost(requestParameters: IngestAccessPageApiIngestAccessPagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.ingestAccessPageApiIngestAccessPagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List direct measure for a given project and image.
     * List Direct Measure
     */
    async listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGetRaw(requestParameters: ListDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DirectMeasureOutput>>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGet.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/direct-measure`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DirectMeasureOutputFromJSON));
    }

    /**
     * List direct measure for a given project and image.
     * List Direct Measure
     */
    async listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGet(requestParameters: ListDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DirectMeasureOutput>> {
        const response = await this.listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List metadata annotations for a given project and image.
     * List Metadata Annotation
     */
    async listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGetRaw(requestParameters: ListMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetadataAnnotationOutput>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGet.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/metadata-annotation`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetadataAnnotationOutputFromJSON));
    }

    /**
     * List metadata annotations for a given project and image.
     * List Metadata Annotation
     */
    async listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGet(requestParameters: ListMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetadataAnnotationOutput>> {
        const response = await this.listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List User Onboarding Task
     */
    async listUserOnboardingTaskApiUserOnboardingTaskGetRaw(requestParameters: ListUserOnboardingTaskApiUserOnboardingTaskGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOnboardingTasksResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_onboarding_task`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOnboardingTasksResponseFromJSON(jsonValue));
    }

    /**
     * List User Onboarding Task
     */
    async listUserOnboardingTaskApiUserOnboardingTaskGet(requestParameters: ListUserOnboardingTaskApiUserOnboardingTaskGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOnboardingTasksResponse> {
        const response = await this.listUserOnboardingTaskApiUserOnboardingTaskGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login
     */
    async loginApiLoginPostRaw(requestParameters: LoginApiLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userLoginRequest === null || requestParameters.userLoginRequest === undefined) {
            throw new runtime.RequiredError('userLoginRequest','Required parameter requestParameters.userLoginRequest was null or undefined when calling loginApiLoginPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginRequestToJSON(requestParameters.userLoginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Login
     */
    async loginApiLoginPost(requestParameters: LoginApiLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.loginApiLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async logoutApiLogoutPostRaw(requestParameters: LogoutApiLogoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Logout
     */
    async logoutApiLogoutPost(requestParameters: LogoutApiLogoutPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.logoutApiLogoutPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Microsoft Login
     */
    async microsoftLoginApiMicrosoftLoginGetRaw(requestParameters: MicrosoftLoginApiMicrosoftLoginGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/microsoft-login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Microsoft Login
     */
    async microsoftLoginApiMicrosoftLoginGet(requestParameters: MicrosoftLoginApiMicrosoftLoginGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.microsoftLoginApiMicrosoftLoginGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Microsoft Login Redirection
     */
    async microsoftLoginRedirectionApiMicrosoftLoginRedirectionGetRaw(requestParameters: MicrosoftLoginRedirectionApiMicrosoftLoginRedirectionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/microsoft-login-redirection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Microsoft Login Redirection
     */
    async microsoftLoginRedirectionApiMicrosoftLoginRedirectionGet(requestParameters: MicrosoftLoginRedirectionApiMicrosoftLoginRedirectionGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.microsoftLoginRedirectionApiMicrosoftLoginRedirectionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch metadata annotation for a given project and image.
     * Patch Metadata Annotations
     */
    async patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatchRaw(requestParameters: PatchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatch.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatch.');
        }

        if (requestParameters.annotationPatchRequest === null || requestParameters.annotationPatchRequest === undefined) {
            throw new runtime.RequiredError('annotationPatchRequest','Required parameter requestParameters.annotationPatchRequest was null or undefined when calling patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/metadata-annotation`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AnnotationPatchRequestToJSON(requestParameters.annotationPatchRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Patch metadata annotation for a given project and image.
     * Patch Metadata Annotations
     */
    async patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatch(requestParameters: PatchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch direct measure for a given project and image.
     * Patch Project Image Direct Measures
     */
    async patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatchRaw(requestParameters: PatchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatch.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatch.');
        }

        if (requestParameters.directMeasurePatchRequest === null || requestParameters.directMeasurePatchRequest === undefined) {
            throw new runtime.RequiredError('directMeasurePatchRequest','Required parameter requestParameters.directMeasurePatchRequest was null or undefined when calling patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/direct-measure`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DirectMeasurePatchRequestToJSON(requestParameters.directMeasurePatchRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Patch direct measure for a given project and image.
     * Patch Project Image Direct Measures
     */
    async patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatch(requestParameters: PatchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the class annotations of a project for an image. Request metrics compute related to the class annotations.  Works by deleting all previous class annotations and creating new ones.  Note: If an class annotation is created by this request, it will validate OnboardingTaskType.PUBLIC_USER_HAS_DRAWN_ANNOTATIONS as a side effect.  Note: If you need to keep the class_annotation_ids, please build an API to update them per class_annotation_ids.       This API is meant to set the class_annotations in bulk per project per image.
     * Post Project Image Class Annotations
     */
    async postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPostRaw(requestParameters: PostProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectClassAnnotationResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPost.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPost.');
        }

        if (requestParameters.updateClassAnnotationsRequest === null || requestParameters.updateClassAnnotationsRequest === undefined) {
            throw new runtime.RequiredError('updateClassAnnotationsRequest','Required parameter requestParameters.updateClassAnnotationsRequest was null or undefined when calling postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/images/{image_id}/class_annotations`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClassAnnotationsRequestToJSON(requestParameters.updateClassAnnotationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectClassAnnotationResponseFromJSON(jsonValue));
    }

    /**
     * Set the class annotations of a project for an image. Request metrics compute related to the class annotations.  Works by deleting all previous class annotations and creating new ones.  Note: If an class annotation is created by this request, it will validate OnboardingTaskType.PUBLIC_USER_HAS_DRAWN_ANNOTATIONS as a side effect.  Note: If you need to keep the class_annotation_ids, please build an API to update them per class_annotation_ids.       This API is meant to set the class_annotations in bulk per project per image.
     * Post Project Image Class Annotations
     */
    async postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPost(requestParameters: PostProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectClassAnnotationResponse> {
        const response = await this.postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prepare Training Algorithm Workflow From Project
     */
    async prepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPostRaw(requestParameters: PrepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling prepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/algorithm/train/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Prepare Training Algorithm Workflow From Project
     */
    async prepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPost(requestParameters: PrepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.prepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Consumes a project sharing token. This means duplicating the project for the user making the request. The token is only valid once.
     * Redeem Project Share
     */
    async redeemProjectShareApiProjectsSharePostRaw(requestParameters: RedeemProjectShareApiProjectsSharePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedeemShareProjectResponse>> {
        if (requestParameters.projectSharingDetailsRequest === null || requestParameters.projectSharingDetailsRequest === undefined) {
            throw new runtime.RequiredError('projectSharingDetailsRequest','Required parameter requestParameters.projectSharingDetailsRequest was null or undefined when calling redeemProjectShareApiProjectsSharePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/share`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSharingDetailsRequestToJSON(requestParameters.projectSharingDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedeemShareProjectResponseFromJSON(jsonValue));
    }

    /**
     * Consumes a project sharing token. This means duplicating the project for the user making the request. The token is only valid once.
     * Redeem Project Share
     */
    async redeemProjectShareApiProjectsSharePost(requestParameters: RedeemProjectShareApiProjectsSharePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedeemShareProjectResponse> {
        const response = await this.redeemProjectShareApiProjectsSharePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register
     */
    async registerApiRegisterPostRaw(requestParameters: RegisterApiRegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userRegistrationRequest === null || requestParameters.userRegistrationRequest === undefined) {
            throw new runtime.RequiredError('userRegistrationRequest','Required parameter requestParameters.userRegistrationRequest was null or undefined when calling registerApiRegisterPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationRequestToJSON(requestParameters.userRegistrationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Register
     */
    async registerApiRegisterPost(requestParameters: RegisterApiRegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.registerApiRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request Assistance
     */
    async requestAssistanceApiRequestAssistancePostRaw(requestParameters: RequestAssistanceApiRequestAssistancePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userAssistanceRequest === null || requestParameters.userAssistanceRequest === undefined) {
            throw new runtime.RequiredError('userAssistanceRequest','Required parameter requestParameters.userAssistanceRequest was null or undefined when calling requestAssistanceApiRequestAssistancePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/request-assistance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAssistanceRequestToJSON(requestParameters.userAssistanceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request Assistance
     */
    async requestAssistanceApiRequestAssistancePost(requestParameters: RequestAssistanceApiRequestAssistancePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestAssistanceApiRequestAssistancePostRaw(requestParameters, initOverrides);
    }

    /**
     * Request Reset Password
     */
    async requestResetPasswordApiRequestResetPasswordPostRaw(requestParameters: RequestResetPasswordApiRequestResetPasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userRequestResetPasswordRequest === null || requestParameters.userRequestResetPasswordRequest === undefined) {
            throw new runtime.RequiredError('userRequestResetPasswordRequest','Required parameter requestParameters.userRequestResetPasswordRequest was null or undefined when calling requestResetPasswordApiRequestResetPasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/request-reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRequestResetPasswordRequestToJSON(requestParameters.userRequestResetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Request Reset Password
     */
    async requestResetPasswordApiRequestResetPasswordPost(requestParameters: RequestResetPasswordApiRequestResetPasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.requestResetPasswordApiRequestResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset All User Onboarding Tasks
     */
    async resetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPostRaw(requestParameters: ResetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_onboarding_task/reset/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset All User Onboarding Tasks
     */
    async resetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPost(requestParameters: ResetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.resetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset Password
     */
    async resetPasswordApiResetPasswordPostRaw(requestParameters: ResetPasswordApiResetPasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userResetPasswordRequest === null || requestParameters.userResetPasswordRequest === undefined) {
            throw new runtime.RequiredError('userResetPasswordRequest','Required parameter requestParameters.userResetPasswordRequest was null or undefined when calling resetPasswordApiResetPasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserResetPasswordRequestToJSON(requestParameters.userResetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Reset Password
     */
    async resetPasswordApiResetPasswordPost(requestParameters: ResetPasswordApiResetPasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.resetPasswordApiResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset Project
     */
    async resetProjectApiProjectsProjectIdResetPostRaw(requestParameters: ResetProjectApiProjectsProjectIdResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling resetProjectApiProjectsProjectIdResetPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/reset`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset Project
     */
    async resetProjectApiProjectsProjectIdResetPost(requestParameters: ResetProjectApiProjectsProjectIdResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.resetProjectApiProjectsProjectIdResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset the training parameters of a project.
     * Reset Project Measurement Parameter
     */
    async resetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPostRaw(requestParameters: ResetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeasurementSettingsValueObject>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling resetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/settings/measurement_settings/reset`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementSettingsValueObjectFromJSON(jsonValue));
    }

    /**
     * Reset the training parameters of a project.
     * Reset Project Measurement Parameter
     */
    async resetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPost(requestParameters: ResetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeasurementSettingsValueObject> {
        const response = await this.resetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset the project settings.
     * Reset Project Settings Direct Measure
     */
    async resetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPostRaw(requestParameters: ResetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DirectMeasureSettingsValueObject>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling resetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/settings/direct-measure/reset`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DirectMeasureSettingsValueObjectFromJSON(jsonValue));
    }

    /**
     * Reset the project settings.
     * Reset Project Settings Direct Measure
     */
    async resetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPost(requestParameters: ResetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DirectMeasureSettingsValueObject> {
        const response = await this.resetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset the training parameters of a project.
     * Reset Project Training Parameters
     */
    async resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostRaw(requestParameters: ResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/settings/training_parameters/reset`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostFromJSON(jsonValue));
    }

    /**
     * Reset the training parameters of a project.
     * Reset Project Training Parameters
     */
    async resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost(requestParameters: ResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseResetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost> {
        const response = await this.resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset User Onboarding Task Group
     */
    async resetUserOnboardingTaskGroupApiUserOnboardingTaskResetPostRaw(requestParameters: ResetUserOnboardingTaskGroupApiUserOnboardingTaskResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.resetUserOnboardingTaskGroupRequest === null || requestParameters.resetUserOnboardingTaskGroupRequest === undefined) {
            throw new runtime.RequiredError('resetUserOnboardingTaskGroupRequest','Required parameter requestParameters.resetUserOnboardingTaskGroupRequest was null or undefined when calling resetUserOnboardingTaskGroupApiUserOnboardingTaskResetPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_onboarding_task/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetUserOnboardingTaskGroupRequestToJSON(requestParameters.resetUserOnboardingTaskGroupRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset User Onboarding Task Group
     */
    async resetUserOnboardingTaskGroupApiUserOnboardingTaskResetPost(requestParameters: ResetUserOnboardingTaskGroupApiUserOnboardingTaskResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.resetUserOnboardingTaskGroupApiUserOnboardingTaskResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset the user settings canvas.
     * Reset User Settings Canvas
     */
    async resetUserSettingsCanvasApiSettingsCanvasResetPostRaw(requestParameters: ResetUserSettingsCanvasApiSettingsCanvasResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettingsCanvasResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/canvas/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsCanvasResponseFromJSON(jsonValue));
    }

    /**
     * Reset the user settings canvas.
     * Reset User Settings Canvas
     */
    async resetUserSettingsCanvasApiSettingsCanvasResetPost(requestParameters: ResetUserSettingsCanvasApiSettingsCanvasResetPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettingsCanvasResponse> {
        const response = await this.resetUserSettingsCanvasApiSettingsCanvasResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a project share token
     * Share Project
     */
    async shareProjectApiProjectsProjectIdSharePostRaw(requestParameters: ShareProjectApiProjectsProjectIdSharePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectShareResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling shareProjectApiProjectsProjectIdSharePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/share`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectShareResponseFromJSON(jsonValue));
    }

    /**
     * Creates a project share token
     * Share Project
     */
    async shareProjectApiProjectsProjectIdSharePost(requestParameters: ShareProjectApiProjectsProjectIdSharePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectShareResponse> {
        const response = await this.shareProjectApiProjectsProjectIdSharePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Skip All User Onboarding Tasks
     */
    async skipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPostRaw(requestParameters: SkipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user_onboarding_task/skip/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Skip All User Onboarding Tasks
     */
    async skipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPost(requestParameters: SkipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.skipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Skip User Onboarding Task Group
     */
    async skipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPostRaw(requestParameters: SkipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.skipUserOnboardingTaskGroupRequest === null || requestParameters.skipUserOnboardingTaskGroupRequest === undefined) {
            throw new runtime.RequiredError('skipUserOnboardingTaskGroupRequest','Required parameter requestParameters.skipUserOnboardingTaskGroupRequest was null or undefined when calling skipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user_onboarding_task/skip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SkipUserOnboardingTaskGroupRequestToJSON(requestParameters.skipUserOnboardingTaskGroupRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Skip User Onboarding Task Group
     */
    async skipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPost(requestParameters: SkipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.skipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Switch Image Dataset
     */
    async switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPostRaw(requestParameters: SwitchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPost.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPost.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/switch-image-dataset/{image_id}/{context}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))).replace(`{${"context"}}`, encodeURIComponent(String(requestParameters.context))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Switch Image Dataset
     */
    async switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPost(requestParameters: SwitchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronize Licences
     */
    async synchronizeLicencesApiTasksSynchronizeLicencesPostRaw(requestParameters: SynchronizeLicencesApiTasksSynchronizeLicencesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/_tasks/synchronize_licences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Synchronize Licences
     */
    async synchronizeLicencesApiTasksSynchronizeLicencesPost(requestParameters: SynchronizeLicencesApiTasksSynchronizeLicencesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.synchronizeLicencesApiTasksSynchronizeLicencesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchive Image
     */
    async unarchiveImageApiImagesImageIdUnarchivePostRaw(requestParameters: UnarchiveImageApiImagesImageIdUnarchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling unarchiveImageApiImagesImageIdUnarchivePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/images/{image_id}/unarchive`.replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Unarchive Image
     */
    async unarchiveImageApiImagesImageIdUnarchivePost(requestParameters: UnarchiveImageApiImagesImageIdUnarchivePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.unarchiveImageApiImagesImageIdUnarchivePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Image Properties
     */
    async updateImagePropertiesApiProjectsProjectIdImageImageIdPutRaw(requestParameters: UpdateImagePropertiesApiProjectsProjectIdImageImageIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectImageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateImagePropertiesApiProjectsProjectIdImageImageIdPut.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling updateImagePropertiesApiProjectsProjectIdImageImageIdPut.');
        }

        if (requestParameters.projectImageProperties === null || requestParameters.projectImageProperties === undefined) {
            throw new runtime.RequiredError('projectImageProperties','Required parameter requestParameters.projectImageProperties was null or undefined when calling updateImagePropertiesApiProjectsProjectIdImageImageIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/image/{image_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectImagePropertiesToJSON(requestParameters.projectImageProperties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectImageResponseFromJSON(jsonValue));
    }

    /**
     * Update Image Properties
     */
    async updateImagePropertiesApiProjectsProjectIdImageImageIdPut(requestParameters: UpdateImagePropertiesApiProjectsProjectIdImageImageIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectImageResponse> {
        const response = await this.updateImagePropertiesApiProjectsProjectIdImageImageIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Password
     */
    async updatePasswordApiUserPasswordPutRaw(requestParameters: UpdatePasswordApiUserPasswordPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userUpdatePasswordRequest === null || requestParameters.userUpdatePasswordRequest === undefined) {
            throw new runtime.RequiredError('userUpdatePasswordRequest','Required parameter requestParameters.userUpdatePasswordRequest was null or undefined when calling updatePasswordApiUserPasswordPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdatePasswordRequestToJSON(requestParameters.userUpdatePasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Update Password
     */
    async updatePasswordApiUserPasswordPut(requestParameters: UpdatePasswordApiUserPasswordPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.updatePasswordApiUserPasswordPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Profile
     */
    async updateProfileApiUserProfilePutRaw(requestParameters: UpdateProfileApiUserProfilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserManagementResponse>> {
        if (requestParameters.userUpdateProfileRequest === null || requestParameters.userUpdateProfileRequest === undefined) {
            throw new runtime.RequiredError('userUpdateProfileRequest','Required parameter requestParameters.userUpdateProfileRequest was null or undefined when calling updateProfileApiUserProfilePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateProfileRequestToJSON(requestParameters.userUpdateProfileRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserManagementResponseFromJSON(jsonValue));
    }

    /**
     * Update Profile
     */
    async updateProfileApiUserProfilePut(requestParameters: UpdateProfileApiUserProfilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserManagementResponse> {
        const response = await this.updateProfileApiUserProfilePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Project
     */
    async updateProjectApiProjectsProjectIdPutRaw(requestParameters: UpdateProjectApiProjectsProjectIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectApiProjectsProjectIdPut.');
        }

        if (requestParameters.projectUpdateRequest === null || requestParameters.projectUpdateRequest === undefined) {
            throw new runtime.RequiredError('projectUpdateRequest','Required parameter requestParameters.projectUpdateRequest was null or undefined when calling updateProjectApiProjectsProjectIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateRequestToJSON(requestParameters.projectUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     * Update Project
     */
    async updateProjectApiProjectsProjectIdPut(requestParameters: UpdateProjectApiProjectsProjectIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.updateProjectApiProjectsProjectIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Project Class
     */
    async updateProjectClassApiProjectsProjectIdClassColorIndexPutRaw(requestParameters: UpdateProjectClassApiProjectsProjectIdClassColorIndexPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlgoClass>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectClassApiProjectsProjectIdClassColorIndexPut.');
        }

        if (requestParameters.colorIndex === null || requestParameters.colorIndex === undefined) {
            throw new runtime.RequiredError('colorIndex','Required parameter requestParameters.colorIndex was null or undefined when calling updateProjectClassApiProjectsProjectIdClassColorIndexPut.');
        }

        if (requestParameters.projectClassUpdateRequest === null || requestParameters.projectClassUpdateRequest === undefined) {
            throw new runtime.RequiredError('projectClassUpdateRequest','Required parameter requestParameters.projectClassUpdateRequest was null or undefined when calling updateProjectClassApiProjectsProjectIdClassColorIndexPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/class/{color_index}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"color_index"}}`, encodeURIComponent(String(requestParameters.colorIndex))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectClassUpdateRequestToJSON(requestParameters.projectClassUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlgoClassFromJSON(jsonValue));
    }

    /**
     * Update Project Class
     */
    async updateProjectClassApiProjectsProjectIdClassColorIndexPut(requestParameters: UpdateProjectClassApiProjectsProjectIdClassColorIndexPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlgoClass> {
        const response = await this.updateProjectClassApiProjectsProjectIdClassColorIndexPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the training parameters of a project.
     * Update Project Settings
     */
    async updateProjectSettingsApiProjectsProjectIdSettingsPutRaw(requestParameters: UpdateProjectSettingsApiProjectsProjectIdSettingsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectSettings>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectSettingsApiProjectsProjectIdSettingsPut.');
        }

        if (requestParameters.projectSettingsUpdateRequest === null || requestParameters.projectSettingsUpdateRequest === undefined) {
            throw new runtime.RequiredError('projectSettingsUpdateRequest','Required parameter requestParameters.projectSettingsUpdateRequest was null or undefined when calling updateProjectSettingsApiProjectsProjectIdSettingsPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projects/{project_id}/settings`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSettingsUpdateRequestToJSON(requestParameters.projectSettingsUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSettingsFromJSON(jsonValue));
    }

    /**
     * Update the training parameters of a project.
     * Update Project Settings
     */
    async updateProjectSettingsApiProjectsProjectIdSettingsPut(requestParameters: UpdateProjectSettingsApiProjectsProjectIdSettingsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectSettings> {
        const response = await this.updateProjectSettingsApiProjectsProjectIdSettingsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user settings canvas.
     * Update User Settings Canvas
     */
    async updateUserSettingsCanvasApiSettingsCanvasPutRaw(requestParameters: UpdateUserSettingsCanvasApiSettingsCanvasPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettingsCanvasResponse>> {
        if (requestParameters.userSettingsCanvasValueObject === null || requestParameters.userSettingsCanvasValueObject === undefined) {
            throw new runtime.RequiredError('userSettingsCanvasValueObject','Required parameter requestParameters.userSettingsCanvasValueObject was null or undefined when calling updateUserSettingsCanvasApiSettingsCanvasPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/settings/canvas`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsCanvasValueObjectToJSON(requestParameters.userSettingsCanvasValueObject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsCanvasResponseFromJSON(jsonValue));
    }

    /**
     * Update the user settings canvas.
     * Update User Settings Canvas
     */
    async updateUserSettingsCanvasApiSettingsCanvasPut(requestParameters: UpdateUserSettingsCanvasApiSettingsCanvasPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettingsCanvasResponse> {
        const response = await this.updateUserSettingsCanvasApiSettingsCanvasPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use Demo Image
     */
    async useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPostRaw(requestParameters: UseDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectImageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPost.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPost.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectSlug !== undefined) {
            queryParameters['project_slug'] = requestParameters.projectSlug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projects/{project_id}/use-demo-image/{context}/{image_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"context"}}`, encodeURIComponent(String(requestParameters.context))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectImageResponseFromJSON(jsonValue));
    }

    /**
     * Use Demo Image
     */
    async useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPost(requestParameters: UseDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectImageResponse> {
        const response = await this.useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
