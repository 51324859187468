import { AngleUnitValues, TextPositionToShapeValues } from '@clemex/mosaic-canvas'
import { ColorPicker, type ColorPickerProps, Flex, Select, Radio, type RadioChangeEvent, InputNumber, Button, Space } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/direct-measure-settings.module.scss'
import { useProjectSettingsDirectMeasure } from '@app/api/hooks'
import { FontWeight } from '@app/api/openapi'
import { COLOR_PRESETS, colorToRGBString } from '@app/constants'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'

const AVAILABLE_FONTS_FAMILY = [
  ...new Set([...document.fonts].map((font) => font.family)),
  // Adding Generic font family
  // https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/
  'sans-serif',
  'serif',
  'cursive',
  'fantasy',
  'monospace',
  'system-ui',
  'emoji',
  'math',
  'fangsong',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
]

interface DirectMeasureSettingsProps {
  projectId: string
}
export const DirectMeasureSettings: React.FC<DirectMeasureSettingsProps> = ({ projectId }) => {
  const { data: projectSettingsMeasurementData, isLoading, mutateDirectMeasureSettings: mutateMeasurementSettings, reset } = useProjectSettingsDirectMeasure(projectId)

  const onChangeMainColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      mainColor: value.toRgbString(),
    })
  }

  const onChangeMeasuringLineColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      measuringLineColor: value.toRgbString(),
    })
  }

  const onChangeHinterDotColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      measuringPointColor: value.toRgbString(),
    })
  }

  const onChangeFontFamily = (value: string): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontFamily: value,
    })
  }

  const onChangeFontSize = (value: number | null): void => {
    if (value === null) {
      return
    }
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontSize: value,
    })
  }

  const onChangeFontWeight = (value: FontWeight | null): void => {
    if (value === null) {
      return
    }
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontWeight: value,
    })
  }
  const onChangeFontColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontColor: value.toRgbString(),
    })
  }

  const onChangeFontOutlineColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontOutlineColor: value.toRgbString(),
    })
  }

  const onChangeAngleUnit = (event: RadioChangeEvent): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      angleUnit: event.target.value,
    })
  }

  const onChangeGeometryMeasurementTextPosition = (event: RadioChangeEvent): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      directMeasureTextPosition: event.target.value,
    })
  }

  if (isLoading || projectSettingsMeasurementData === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.measurement.loading" defaultMessage={'Loading...'} />
  }

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      title={
        <FormattedMessage id="project-settings.modal.tab.measurement.common" defaultMessage={'Common Measurement Style'} />
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.main-color" defaultMessage={'Main Color'} />
        }
      >
        <ColorPicker
          disabledAlpha={false}
          allowClear={true}
          value={colorToRGBString(projectSettingsMeasurementData.mainColor)}
          onChangeComplete={onChangeMainColor}
          presets={COLOR_PRESETS}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.measuring-line-color" defaultMessage={'Measuring Line Color'} />
        }
      >
        <ColorPicker
          disabledAlpha={false}
          allowClear={true}
          value={colorToRGBString(projectSettingsMeasurementData.measuringLineColor)}
          onChangeComplete={onChangeMeasuringLineColor}
          presets={COLOR_PRESETS}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.measuring-dot-color" defaultMessage={'Measuring Dot Color'} />
        }
      >
        <ColorPicker
          disabledAlpha={false}
          allowClear={true}
          value={colorToRGBString(projectSettingsMeasurementData.measuringPointColor)}
          onChangeComplete={onChangeHinterDotColor}
          presets={COLOR_PRESETS}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.font.label" defaultMessage={'Font'} />
        }
      >
        <Space>
          <Select className={styles.selectFontWeight} value={projectSettingsMeasurementData.fontWeight} options={Object.values(FontWeight).map((fontWeight) => ({ value: fontWeight, label: `${fontWeight}` }))} onChange={onChangeFontWeight}/>
          <Select className={styles.selectFontFamily} value={projectSettingsMeasurementData.fontFamily} options={AVAILABLE_FONTS_FAMILY.map((fontFamily) => ({ value: fontFamily, label: fontFamily }))} onChange={onChangeFontFamily}/>
          <InputNumber className={styles.selectFontSize} min={8} value={projectSettingsMeasurementData.fontSize} onChange={onChangeFontSize} addonAfter={'px'}/>
        </Space>
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.font.color.label" defaultMessage={'Font color'} />
        }
      >
        <ColorPicker
          disabledAlpha={false}
          allowClear={true}
          value={colorToRGBString(projectSettingsMeasurementData.fontColor)}
          onChangeComplete={onChangeFontColor}
          presets={COLOR_PRESETS}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.font.outline-color.label" defaultMessage={'Font Outline Color'} />
        }
      >
        <ColorPicker
          disabledAlpha={false}
          allowClear={true}
          value={colorToRGBString(projectSettingsMeasurementData.fontOutlineColor)}
          onChangeComplete={onChangeFontOutlineColor}
          presets={COLOR_PRESETS}
        />
      </SettingRow>
    </SettingsSection>
    <SettingsSection
      title={
        <FormattedMessage id="project-settings.modal.tab.measurement.angle.title" defaultMessage={'Angle Tool'} />
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.label" defaultMessage={'Unit'} />
        }
      >
        <Radio.Group value={projectSettingsMeasurementData.angleUnit} onChange={onChangeAngleUnit}>
          <Radio.Button value={AngleUnitValues.DEGREE}>
            <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.inner" defaultMessage={'Degree'} />
          </Radio.Button>
          <Radio.Button value={AngleUnitValues.RADIAN}>
            <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.outer" defaultMessage={'Radian'} />
          </Radio.Button>
        </Radio.Group>
      </SettingRow>
    </SettingsSection>
    <SettingsSection
      title={
        <FormattedMessage id="project-settings.modal.tab.measurement.geometry.title" defaultMessage={'Geometry Tool'} />
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.label" defaultMessage={'Measurement Text Position'} />
        }
      >
        <Radio.Group value={projectSettingsMeasurementData.directMeasureTextPosition} onChange={onChangeGeometryMeasurementTextPosition}>
          <Radio.Button value={TextPositionToShapeValues.TOP}>
            <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.top" defaultMessage={'Top'} />
          </Radio.Button>
          <Radio.Button value={TextPositionToShapeValues.RIGHT}>
            <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.right" defaultMessage={'Right'} />
          </Radio.Button>
          <Radio.Button value={TextPositionToShapeValues.BOTTOM}>
            <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.bottom" defaultMessage={'Bottom'} />
          </Radio.Button>
          <Radio.Button value={TextPositionToShapeValues.LEFT}>
            <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.left" defaultMessage={'Left'} />
          </Radio.Button>
        </Radio.Group>
      </SettingRow>
    </SettingsSection>
    <SettingRow className={styles.actionsContainer}>
      <Button type='primary' onClick={reset}>
        <FormattedMessage id="project-settings.modal.tab.measurement.reset" defaultMessage={'Reset to default direct measure settings'} />
      </Button>
    </SettingRow>
  </Flex>
}
