import { Typography, Flex, Button, Slider, Switch, Alert, Select } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/algorithm-settings.module.scss'
import { useAlgorithmId, useDeepLearningProjectTrainingParameters, useRFAlgorithmProjectTrainingParameters, useProjectTrainingState, useLicence } from '@app/api/hooks'
import clsx from 'clsx'
import { AlgorithmId } from '@app/api/openapi'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'

const { Text, Paragraph } = Typography

interface AlgorithmSelectorProps {
  projectId: string
}
export const AlgorithmSelector: React.FC<AlgorithmSelectorProps> = ({ projectId }) => {
  const { data: algorithmId, mutate: mutateAlgorithmId } = useAlgorithmId(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const { data: licence } = useLicence()

  const available = licence !== undefined && (
    (licence?.algoClemexNetLiteV1 && algorithmId === AlgorithmId.ClemexNetLiteV1) ||
    (licence?.algoClemexNetLiteV2 && algorithmId === AlgorithmId.ClemexNetLiteV2) ||
    (algorithmId === AlgorithmId.RfAlgorithmSklearn) ||
    (algorithmId === AlgorithmId.RfAlgorithmCuml)
  )
  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      title={
        <FormattedMessage id='training-options.models.label' defaultMessage='Models'/>
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.algorithm.choice" defaultMessage={'Model'} />
        }
      >
        <Select
          className={styles.algorithmSelect}
          options={[
            { value: AlgorithmId.RfAlgorithmSklearn, label: <FormattedMessage id='algorithm-id.random-forest.label' defaultMessage='Random Forest'/> },
            { value: AlgorithmId.ClemexNetLiteV1, label: <FormattedMessage id='algorithm-id.clemex-net-lite-v1.label' defaultMessage='ClemexNet Lite V1' /> },
            { value: AlgorithmId.ClemexNetLiteV2, label: <FormattedMessage id='algorithm-id.clemex-net-lite-v2.label' defaultMessage='ClemexNet Lite V2' /> },
          ]}
          onChange={async (value: AlgorithmId) => {
            await mutateAlgorithmId(value)
            await mutateProjectTrainingState()
          }}
          value={algorithmId}
        />
      </SettingRow>
    </SettingsSection>
    { !available && <SettingRow>
      <Alert
        message={<Text>
          {
            algorithmId === AlgorithmId.ClemexNetLiteV1 &&
              <>
                <Paragraph>
                  <FormattedMessage id="project-settings.modal.deep-learning.clemex-net-lite-v1.training-parameters.licence-restriction.warning.model" defaultMessage='This model use deep learning technology.' />
                </Paragraph>
                <RequestLicenceFeature featureText='ClemexNet Lite V1' licenceFeatureRequest={UserAssistanceLicenceFeature.AlgoClemexNetLiteV1}/>
              </>
          }
          {
            algorithmId === AlgorithmId.ClemexNetLiteV2 &&
              <>
                <Paragraph>
                  <FormattedMessage id="project-settings.modal.deep-learning.clemex-net-lite-v2.training-parameters.licence-restriction.warning.model" defaultMessage='This model use deep learning technology.' />
                </Paragraph>
                <RequestLicenceFeature featureText='ClemexNet Lite V2' licenceFeatureRequest={UserAssistanceLicenceFeature.AlgoClemexNetLiteV2}/>
              </>
          }
        </Text>}
        type="warning"
      />
    </SettingRow>
    }
  </Flex>
}
interface AlgorithmSettingsProps {
  projectId: string
  algorithmId?: AlgorithmId
}
export const DeepLearningSettings: React.FC<AlgorithmSettingsProps> = ({ projectId, algorithmId }) => {
  const { data: projectSettingsDeepLearningTrainingParameters, isLoading, mutateTrainingParameters, reset } = useDeepLearningProjectTrainingParameters(projectId)
  const { data: licence } = useLicence()
  const available = licence !== undefined && (
    (licence?.algoClemexNetLiteV1 && algorithmId === AlgorithmId.ClemexNetLiteV1) ||
    (licence?.algoClemexNetLiteV2 && algorithmId === AlgorithmId.ClemexNetLiteV2)
  )
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)

  const onChangeEpochs = async (epochs: number): Promise<void> => {
    if (projectSettingsDeepLearningTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsDeepLearningTrainingParameters,
      epochs,
    })
    await mutateProjectTrainingState()
  }
  if (isLoading || projectSettingsDeepLearningTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }
  const epochs: number = projectSettingsDeepLearningTrainingParameters.epochs ?? 20

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      title={
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      }
    >
      <SettingRow
        className={clsx({ [styles.disabledRow]: !available })}
        title={
          <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.epochs" defaultMessage={'Epochs'} />
        }
      >
        <Slider
          disabled={!available}
          className={styles.slider}
          tooltip={{ open: false }}
          min={1}
          max={150}
          step={1}
          marks={{
            [epochs]: epochs,
          }}
          value={epochs}
          onChange={onChangeEpochs}
        />
      </SettingRow>
      <SettingRow className={styles.actionsContainer}>
        <Button type='primary' onClick={reset}>
          <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.reset" defaultMessage={'Reset to default training parameters'} />
        </Button>
      </SettingRow>
    </SettingsSection>
  </Flex>
}
export const RfAlgorithmSettings: React.FC<AlgorithmSettingsProps> = ({ projectId }) => {
  const { data: projectSettingsRfAlgorithmTrainingParameters, isLoading, mutateTrainingParameters, reset } = useRFAlgorithmProjectTrainingParameters(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const onChangeSigma = async (sigma: number[]): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      sigmaMin: sigma[0],
      sigmaMax: sigma[1],
    })
    await mutateProjectTrainingState()
  }
  const onToggleIntensity = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      intensity: !projectSettingsRfAlgorithmTrainingParameters.intensity,
    })
    await mutateProjectTrainingState()
  }
  const onToggleTexture = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      texture: !projectSettingsRfAlgorithmTrainingParameters.texture,
    })
    await mutateProjectTrainingState()
  }
  const onToggleEdges = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      edges: !projectSettingsRfAlgorithmTrainingParameters.edges,
    })
    await mutateProjectTrainingState()
  }

  if (isLoading || projectSettingsRfAlgorithmTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }

  const { sigmaMin, sigmaMax, edges, intensity, texture } = projectSettingsRfAlgorithmTrainingParameters
  const sigma = [sigmaMin, sigmaMax]
  const marks = { [sigma[0]]: sigma[0], [sigma[1]]: sigma[1] }
  const features = [intensity, edges, texture]
  const enabledFeatures = features.filter((feature) => feature)
  const onlyHasOneFeatureEnabled = enabledFeatures.length === 1

  const disabledIntensity = onlyHasOneFeatureEnabled && intensity
  const disabledEdges = onlyHasOneFeatureEnabled && edges
  const disabledTexture = onlyHasOneFeatureEnabled && texture

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      className={styles.settingsSection}
      title={
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.sigma" defaultMessage={'Sigma'} />
        }
      >
        <Slider
          className={styles.slider}
          tooltip={{ open: false }}
          range
          min={0.1}
          max={20.0}
          step={0.1}
          marks={marks}
          value={sigma}
          onChange={onChangeSigma}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.intensity" defaultMessage={'Intensity'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledIntensity })}
          checked={intensity}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledIntensity) {
              void onToggleIntensity()
            }
            element.blur()
          }}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.edges" defaultMessage={'Edges'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledEdges })}
          checked={edges}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledEdges) {
              void onToggleEdges()
            }
            element.blur()
          }}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.texture" defaultMessage={'Texture'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledTexture })}
          checked={texture}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledTexture) {
              void onToggleTexture()
            }
            element.blur()
          }}
        />
      </SettingRow>
      {onlyHasOneFeatureEnabled && <SettingRow>
        <Alert
          className={styles.alert}
          message={<FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.switch.warning" defaultMessage="At least one option is required. You will not be able to deactivate the last one." />}
          type="warning"
        />
      </SettingRow>}
      <SettingRow className={styles.actionsContainer}>
        <Button type='primary' onClick={reset}>
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.reset" defaultMessage={'Reset to default training parameters'} />
        </Button>
      </SettingRow>
    </SettingsSection>
  </Flex>
}

interface AlgorithmSettingsProps {
  projectId: string
  algorithmId?: AlgorithmId
}

export const AlgorithmSettings: React.FC<AlgorithmSettingsProps> = ({ projectId, algorithmId }) => {
  switch (algorithmId) {
    case AlgorithmId.RfAlgorithmSklearn:
    case AlgorithmId.RfAlgorithmCuml:
      return <RfAlgorithmSettings projectId={projectId} />
    case AlgorithmId.ClemexNetLiteV1:
    case AlgorithmId.ClemexNetLiteV2:
      return <DeepLearningSettings projectId={projectId} algorithmId={algorithmId} />
    default:
      return null
  }
}
