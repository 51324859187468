import useSWR, { type SWRResponse } from 'swr'
import { api, DEFAULT_PROJECT_LIST_LIMIT } from './api'
import * as API from './api'
import { mutate as globalMutate } from 'swr'
import {
  type UserOnboardingTasksResponse,
  type FeatureFlagResponse,
  type ListResponseProjectResponse,
  type UserOut,
  type ProjectDatasetContext,
  type ProjectImageResponse,
  type ProjectResponse,
  type AlgoClass,
  type ImageResponse,
  type ClassAnnotationGeoJSON,
  type Polygon,
  type RfAlgorithmTrainingParametersValueObject,
  type ProjectTrainingSnapshotResponse,
  type ResponseError,
  LicenceType,
  type ProjectSettings,
  type ProjectTrainingState,
  type DirectMeasureSettingsValueObject,
  type DeepLearningTrainingParametersValueObject,
  type AlgorithmId,
  type ProjectShareDetailsResponse,
  type ExportOptionsValueObject,
  type OnboardingTaskType,
  type UserSettingsCanvasResponse,
  type UserSettingsCanvasValueObject,
  type MeasurementSettingsValueObject,
  type ProjectBundleStatusResponse,
} from '@app/api/openapi'
import { type EnvironmentConfiguration } from '@app/types'
import { Project } from './models'
import React, { useCallback, useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import { useSelectedImageId } from '@app/hooks/editor'
import { flatMap } from 'lodash'
import hash from 'stable-hash'
import { CACHE_KEYS, type CacheKey } from '@app/api/cache-keys'
import { ONBOARDING_TOUR_CONTEXT } from '@app/constants'
import { useTriggerProjectTrainingStatusRevalidation } from '@app/api/resource-status'
import { EVENT_DISPATCHER } from '@app/event'
import { useTrainingProgression } from '@app/api/training'

export const projectListFetcher =
  async (): Promise<ListResponseProjectResponse> => {
    return await api.getProjectListApiProjectsGet({
      limit: DEFAULT_PROJECT_LIST_LIMIT,
    })
  }

type useProjectReturn = SWRResponse<ListResponseProjectResponse, ResponseError> & {
  projects: Project[] | undefined
}

export function useProjects (): useProjectReturn {
  const response = useSWR<ListResponseProjectResponse>(
    CACHE_KEYS.PROJECT_LIST,
    projectListFetcher,
  )

  const projects = useMemo(() => {
    return response.data?.data.map((project) =>
      Project.fromProjectResponse(project),
    )
  }, [response.data])

  return {
    ...response,
    projects,
  }
}

const environmentFetcher = async (): Promise<EnvironmentConfiguration> => {
  return (await api.getEnvironmentConfigurationApiEnvironmentGet())
}
export function useEnvironment (): SWRResponse<EnvironmentConfiguration> {
  return useSWR<EnvironmentConfiguration>(
    CACHE_KEYS.ENVIRONMENT,
    environmentFetcher,
  )
}

const featureFlagFetcher = async (): Promise<FeatureFlagResponse> => {
  return await api.getFeatureFlagApiFeatureFlagsGet({})
}

export function useFeatureFlags (): SWRResponse<FeatureFlagResponse> {
  const TIME_BETWEEN_CHECK_CALL = 30 * 1000 // 30000ms (30s)

  return useSWR<FeatureFlagResponse>(
    CACHE_KEYS.FEATURE_FLAGS,
    featureFlagFetcher,
    { revalidateOnFocus: true, refreshInterval: TIME_BETWEEN_CHECK_CALL },
  )
}

export const userProfileFetcher = async (): Promise<UserOut> =>
  await api.getUserProfileApiUserProfileGet({})
export function useProfile (): SWRResponse<UserOut, ResponseError> {
  const response = useSWR<UserOut>(CACHE_KEYS.USER_PROFILE, userProfileFetcher)
  React.useEffect(() => {
    if (response.data?.userId !== undefined) {
      Sentry.setUser({ id: response.data.userId, email: response.data.email })
      LogRocket.identify(response.data.userId, { email: response.data.email })
    }
  }, [response])

  return response
}

export const userOnboardingTasksFetcher = async (): Promise<UserOnboardingTasksResponse> => {
  return await api.listUserOnboardingTaskApiUserOnboardingTaskGet({})
}

type useOnboardingTasksResponse = SWRResponse<UserOnboardingTasksResponse> & {
  incompleteTasks: Array<keyof UserOnboardingTasksResponse>
  isOnboardingInProgress: boolean
  completeOnboardingTask: (tasks: OnboardingTaskType) => Promise<void>
  finishOnboardingTask: (tasks: OnboardingTaskType) => Promise<void>
  finishAllOnboardingTasks: (tasks: OnboardingTaskType[]) => Promise<void>
}

export function useOnboardingTasks (onboardingTourContext: ONBOARDING_TOUR_CONTEXT = ONBOARDING_TOUR_CONTEXT.NOT_USED): useOnboardingTasksResponse {
  // Regroup the onboarding tasks by context
  const onboardingContextState: Record< ONBOARDING_TOUR_CONTEXT, Array<keyof UserOnboardingTasksResponse>> = {
    [ONBOARDING_TOUR_CONTEXT.PROJECT_LIST]: [
      'projectListUserHasReadWelcome',
      'irreversibleUserHasDiscoveredMenu',
      'projectListUserHasReadPageTutorialWelcome',
      'projectListUserHowToCreateProject',
      'projectListUserHowToOpenProject',
      'projectListUserHowToEditProject',
      'irreversibleUserHasReadLogoutNotice',
    ],
    // Same as PROJECT_LIST dedicated local user
    [ONBOARDING_TOUR_CONTEXT.PROJECT_LIST_LOCAL_USER]: [
      'projectListUserHasReadWelcome',
      'irreversibleUserHasDiscoveredMenu',
      'projectListUserHasReadPageTutorialWelcome',
      'projectListUserHowToCreateProject',
      'projectListUserHowToOpenProject',
      'projectListUserHowToEditProject',
    ],
    [ONBOARDING_TOUR_CONTEXT.ANNOTATION]: [
      'annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain',
      'annotationPageUserHasReadUploadTrainingImage',
      'annotationPageUserHasReadClassSection',
      'annotationPageUserHasReadAddAnnotation',
      'annotationPageUserHasReadAddSmartAnnotation',
      'annotationPageUserHasReadTrain',
      'annotationPageUserHasReadAccessValidate',
    ],
    [ONBOARDING_TOUR_CONTEXT.VALIDATION]: [
      'validationPageUserHasReadValidateIntroduction',
      'validationPageUserHasReadUploadImage',
      'validationPageUserHasReadVisualizeResultsClassHide',
      'validationPageUserHasReadSendImageAnnotate',
      'validationPageUserHasReadVisualizeResultsOpacity',
      'validationPageUserHasReadDownloadPlugin',
    ],
    [ONBOARDING_TOUR_CONTEXT.NOT_USED]: [],
  }

  const onboardingTasks = useSWR(
    CACHE_KEYS.USER_ONBOARDING_TASKS(onboardingTourContext),
    userOnboardingTasksFetcher,
  )

  // Return no onboarding tasks if the context is not defined
  const incompleteTasks = onboardingContextState[onboardingTourContext].filter((step) => {
    return (
      onboardingTasks?.data !== undefined &&
      (onboardingTasks.data[step] === false)
    )
  })

  const completeOnboardingTask = React.useCallback(async (task: OnboardingTaskType) => {
    await API.completeUserOnboardingTask(task)
  }, [])

  // Difference from completeOnboardingTask, the finish will trigger a frontend update as memo with optimistic data
  const finishOnboardingTask = React.useCallback(async (task: OnboardingTaskType) => {
    if (onboardingTasks.data === undefined) {
      return
    }
    await onboardingTasks.mutate(async (): Promise<undefined> => {
      await API.completeUserOnboardingTask(task)
    },
    {
      optimisticData: {
        ...onboardingTasks.data,
        ...incompleteTasks.reduce((acc: Partial<UserOnboardingTasksResponse>, task) => {
          acc[task] = true
          return acc
        }, {}),
      },
    },
    )
  }, [incompleteTasks, onboardingTasks])

  const finishAllOnboardingTasks = React.useCallback(async (tasks: OnboardingTaskType[]) => {
    await onboardingTasks.mutate(async (): Promise<undefined> => {
      await API.completeUserOnboardingTasks(tasks)
    },
    {
      optimisticData: {
        ...onboardingTasks.data,
        ...incompleteTasks.reduce((acc: Partial<UserOnboardingTasksResponse>, task) => {
          acc[task] = true
          return acc
        }, {}),
      },
    },
    )
  }, [incompleteTasks, onboardingTasks])

  return {
    ...onboardingTasks,
    incompleteTasks,
    isOnboardingInProgress: incompleteTasks.length !== 0,
    completeOnboardingTask,
    finishOnboardingTask,
    finishAllOnboardingTasks,
  }
}

export function useProject (projectSlug?: string): SWRResponse<ProjectResponse, ResponseError> {
  return useSWR(
    projectSlug !== undefined ? CACHE_KEYS.PROJECT(projectSlug) : null,
    async () => {
      if (projectSlug === undefined) {
        throw new Error('Project slug must not be undefined')
      }
      return await api.getUserProjectApiProjectsSlugProjectSlugGet({
        projectSlug,
      })
    },
  )
}

export interface ProjectMetadata {
  name: string
  description: string
}
interface UseProjectSessionResponse extends SWRResponse<ProjectResponse, ResponseError> {
  archiveProject: () => Promise<void>
  updateProjectMetadata: (params: ProjectMetadata) => Promise<ProjectResponse | undefined>
}
export function useProjectFromId (projectId: string | undefined): UseProjectSessionResponse {
  const { mutate: mutateProjects } = useProjects()
  const project = useSWR(
    CACHE_KEYS.PROJECT_FROM_ID(projectId),
    async ({ params }) => {
      return await api.getUserProjectApiProjectsProjectIdGet(params)
    },
  )

  const archiveProject = useCallback(async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await api.archiveProjectApiProjectsProjectIdArchivePost({ projectId })
    await project.mutate()
    await mutateProjects()
  }, [mutateProjects, project, projectId])

  const updateProjectMetadata = useCallback(async (params: ProjectMetadata): Promise<ProjectResponse | undefined> => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    const result = await project.mutate(async () => {
      return await api.updateProjectApiProjectsProjectIdPut({
        projectId,
        projectUpdateRequest: params,
      })
    })
    await mutateProjects()
    return result
  }, [mutateProjects, project, projectId])

  return {
    archiveProject,
    updateProjectMetadata,
    ...project,
  }
}
export const useProjectSettings = (projectId: string | undefined): SWRResponse<ProjectSettings, ResponseError> => {
  return useSWR(
    CACHE_KEYS.PROJECT_SETTINGS(projectId),
    async ({ params }) => {
      if (projectId === undefined) {
        throw new Error('Project id must not be undefined')
      }
      return await api.getProjectSettingsApiProjectsProjectIdSettingsGet(params)
    },
  )
}
interface UseAlgorithmIdReturn {
  data: AlgorithmId | undefined
  mutate: (algorithmId: AlgorithmId) => Promise<void>
}
export const useAlgorithmId = (projectId: string | undefined): UseAlgorithmIdReturn => {
  const {
    data, mutate: mutateProjectAlgorithmId,
  } = useProjectSettings(projectId)
  const mutateAlgorithmId = useCallback(async (algorithmId: AlgorithmId) => {
    if (projectId === undefined) {
      throw new Error('project id must not be undefined')
    }
    const optimisticData = data !== undefined ? { ...data, algorithmId } : undefined
    await mutateProjectAlgorithmId(
      async () => {
        const updatedData = await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
          projectId,
          projectSettingsUpdateRequest: {
            algorithmId,
          },
        })
        return updatedData
      },
      { optimisticData },
    )
  }, [projectId, data, mutateProjectAlgorithmId])
  return {
    data: data?.algorithmId,
    mutate: mutateAlgorithmId,
  }
}

interface UseProjectRfAlgorithmTrainingParametersResponse {
  data: RfAlgorithmTrainingParametersValueObject | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  mutateTrainingParameters: (parameters: RfAlgorithmTrainingParametersValueObject) => Promise<void>
  invalidate: () => Promise<void>
  reset: () => Promise<void>
}
export const useRFAlgorithmProjectTrainingParameters = (projectId: string | undefined): UseProjectRfAlgorithmTrainingParametersResponse => {
  const {
    data, error, isLoading, isValidating, mutate: mutateProjectTrainingParameters,
  } = useProjectSettings(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const mutateTrainingParameters = useCallback(async (parameters: RfAlgorithmTrainingParametersValueObject) => {
    if (projectId === undefined) {
      throw new Error('project id must not be undefined')
    }
    const optimisticData = data !== undefined ? { ...data, rfAlgorithmTrainingParameters: parameters } : undefined
    await mutateProjectTrainingParameters(
      async () => {
        const updatedData = await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
          projectId,
          projectSettingsUpdateRequest: {
            rfAlgorithmTrainingParameters: parameters,
          },
        })
        await mutateProjectTrainingState()
        return updatedData
      },
      { optimisticData },
    )
  }, [projectId, data, mutateProjectTrainingParameters, mutateProjectTrainingState])

  const invalidate = useCallback(async () => {
    await mutateProjectTrainingParameters()
    // Side effect: project should be train cache should be revalidated
    void mutateProjectTrainingState()
  }, [mutateProjectTrainingState, mutateProjectTrainingParameters])

  const reset = useCallback(async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await api.resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost({
      projectId,
    })
    await invalidate()
  }, [projectId, invalidate])

  return {
    data: data?.rfAlgorithmTrainingParameters,
    error,
    isLoading,
    isValidating,
    mutateTrainingParameters,
    invalidate,
    reset,
  }
}

interface UseProjectSettingsDirectMeasureResponse {
  data: DirectMeasureSettingsValueObject | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  mutateDirectMeasureSettings: (parameters: DirectMeasureSettingsValueObject) => Promise<void>
  invalidate: () => Promise<void>
  reset: () => Promise<void>
}
export const useProjectSettingsDirectMeasure = (projectId: string | undefined): UseProjectSettingsDirectMeasureResponse => {
  const {
    data, error, isLoading, isValidating, mutate: mutateProjectDirectMeasureSettings,
  } = useProjectSettings(projectId)

  const mutateDirectMeasureSettings = useCallback(async (parameters: DirectMeasureSettingsValueObject) => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    const optimisticData = data !== undefined ? { ...data, directMeasureSettings: parameters } : undefined
    await mutateProjectDirectMeasureSettings(
      async () => {
        await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
          projectId,
          projectSettingsUpdateRequest: {
            directMeasureSettings: parameters,
          },
        })
        return optimisticData
      },
      { optimisticData },
    )
  }, [projectId, mutateProjectDirectMeasureSettings, data])

  const invalidate = useCallback(async () => {
    await mutateProjectDirectMeasureSettings()
  }, [mutateProjectDirectMeasureSettings])

  const reset = useCallback(async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await api.resetProjectSettingsDirectMeasureApiProjectsProjectIdSettingsDirectMeasureResetPost({
      projectId,
    })
    await invalidate()
  }, [projectId, invalidate])

  return {
    data: data?.directMeasureSettings,
    error,
    isLoading,
    isValidating,
    mutateDirectMeasureSettings,
    invalidate,
    reset,
  }
}
interface UseDeepLearningProjectTrainingParametersResponse {
  data: DeepLearningTrainingParametersValueObject | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  mutateTrainingParameters: (parameters: DeepLearningTrainingParametersValueObject) => Promise<void>
  invalidate: () => Promise<void>
  reset: () => Promise<void>
}
export const useDeepLearningProjectTrainingParameters = (projectId: string | undefined): UseDeepLearningProjectTrainingParametersResponse => {
  const {
    data, error, isLoading, isValidating, mutate: mutateProjectTrainingParameters,
  } = useProjectSettings(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const mutateTrainingParameters = useCallback(async (parameters: DeepLearningTrainingParametersValueObject) => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    const optimisticData = data !== undefined ? { ...data, deepLearningTrainingParameters: parameters } : undefined
    await mutateProjectTrainingParameters(
      async () => {
        const updatedData = await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
          projectId,
          projectSettingsUpdateRequest: {
            deepLearningTrainingParameters: parameters,
          },
        })
        // Side effect: project should be train cache should be revalidated
        void mutateProjectTrainingState()
        return updatedData
      },
      { optimisticData },
    )
  }, [projectId, data, mutateProjectTrainingParameters, mutateProjectTrainingState])

  const invalidate = useCallback(async () => {
    await mutateProjectTrainingParameters()
    // Side effect: project should be train cache should be revalidated
    void mutateProjectTrainingState()
  }, [mutateProjectTrainingState, mutateProjectTrainingParameters])

  const reset = useCallback(async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await api.resetProjectTrainingParametersApiProjectsProjectIdSettingsTrainingParametersResetPost({
      projectId,
    })
    await invalidate()
  }, [projectId, invalidate])

  return {
    data: data?.deepLearningTrainingParameters,
    error,
    isLoading,
    isValidating,
    mutateTrainingParameters,
    invalidate,
    reset,
  }
}
interface UseExportOptionsResponse {
  data: ExportOptionsValueObject | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  mutateExportOptions: (options: ExportOptionsValueObject) => Promise<void>
}
export const useExportOptions = (projectId: string | undefined): UseExportOptionsResponse => {
  const {
    data, error, isLoading, isValidating, mutate: mutateProjectExportOptions,
  } = useProjectSettings(projectId)
  const mutateExportOptions = useCallback(async (options: ExportOptionsValueObject) => {
    if (projectId === undefined) {
      throw new Error('Project Id must be defined')
    }
    const optimisticData = data !== undefined ? { ...data, exportOptions: options } : undefined
    await mutateProjectExportOptions(
      async () => {
        const updatedData = await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
          projectId,
          projectSettingsUpdateRequest: {
            exportOptions: options,
          },
        })
        void mutateExportOptions
        return updatedData
      },
      { optimisticData },
    )
  }, [projectId, data, mutateProjectExportOptions])
  return {
    data: data?.exportOptions,
    error,
    isLoading,
    isValidating,
    mutateExportOptions,
  }
}

interface UseMeasurementSettingsResponse {
  data: MeasurementSettingsValueObject | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  mutateMeasurementSettings: (options: Partial<MeasurementSettingsValueObject>) => Promise<void>
  reset: () => Promise<void>
}

export const useMeasurementSettings = (projectId: string | undefined): UseMeasurementSettingsResponse => {
  const {
    data, error, isLoading, isValidating, mutate: mutateProjectSettings,
  } = useProjectSettings(projectId)
  const mutateMeasurementSettings = useCallback(async (options: Partial<MeasurementSettingsValueObject>) => {
    if (projectId === undefined || data?.measurementSettings === undefined) {
      throw new Error('Project Id and data must be defined')
    }
    const updateData = { ...data.measurementSettings, ...options }
    await mutateProjectSettings(async () => {
      const updatedSettings = await api.updateProjectSettingsApiProjectsProjectIdSettingsPut({
        projectId,
        projectSettingsUpdateRequest: {
          measurementSettings: updateData,
        },
      })
      return updatedSettings
    }, { optimisticData: { ...data, measurementSettings: updateData } })
  }, [projectId, mutateProjectSettings, data])

  const reset = useCallback(async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await api.resetProjectMeasurementParameterApiProjectsProjectIdSettingsMeasurementSettingsResetPost({
      projectId,
    })
    await mutateProjectSettings()
  }, [projectId, mutateProjectSettings])

  return {
    data: data?.measurementSettings,
    error,
    isLoading,
    isValidating,
    mutateMeasurementSettings,
    reset,
  }
}

export const useProjectTrainingSnapshot = (projectId: string | undefined): SWRResponse<ProjectTrainingSnapshotResponse | undefined> => {
  const swrProjectTrainingSnapshot = useSWR(
    CACHE_KEYS.PROJECT_TRAINING_SNAPSHOT(projectId),
    async ({ params }) => {
      try {
        const response = await api.getProjectTrainingSnapshotApiProjectsProjectIdTrainingSnapshotGet(params)
        return response
      } catch (err) {
        if ((err as { response?: { status?: number } }).response?.status === 404) {
          return undefined
        } else {
          throw err
        }
      }
    },
    {
      keepPreviousData: false,
    },
  )

  return swrProjectTrainingSnapshot
}

const makeAnnotationCompareKey = (classAnnotation: ClassAnnotationGeoJSON): {
  projectId: string
  imageId: string
  colorIndex: number
  geometry: Polygon
} => {
  return {
    projectId: classAnnotation.projectId,
    imageId: classAnnotation.imageId,
    colorIndex: classAnnotation.colorIndex,
    geometry: classAnnotation.geometry,
  }
}
export const useImageClassAnnotations = (projectId: string | undefined, imageId: string | undefined): {
  data: ClassAnnotationGeoJSON[] | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  saveClassAnnotations: (params: {
    classAnnotationsGeoJSON: ClassAnnotationGeoJSON[]
    projectId: string
    imageId: string
  }) => Promise<void>
} => {
  const { data, error, isLoading, isValidating } = useSWR(
    CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS(projectId, imageId),
    async ({ data }) => {
      return (await api.getProjectImageAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsGet(data)).classAnnotations
    },
    {
      // This allow to compare ignoring the annotationId, creationDate and modificationDate fields
      compare: (annotationsA, annotationsB) => {
        const a = annotationsA?.map(makeAnnotationCompareKey)
        const b = annotationsB?.map(makeAnnotationCompareKey)
        return hash(a) === hash(b)
      },
      keepPreviousData: false,
    },
  )

  const saveClassAnnotations = useCallback(async ({ classAnnotationsGeoJSON, projectId, imageId }: {
    classAnnotationsGeoJSON: ClassAnnotationGeoJSON[]
    projectId: string
    imageId: string
  }) => {
    await API.updateProjectImageAnnotations(projectId, imageId, classAnnotationsGeoJSON)
  }, [])

  return {
    data,
    error,
    isLoading,
    isValidating,
    saveClassAnnotations,
  }
}

export function useProjectImages (
  projectId: string | undefined,
  context: ProjectDatasetContext | undefined,
): SWRResponse<ProjectImageResponse[]> {
  return useSWR(
    projectId !== undefined && context !== undefined
      ? CACHE_KEYS.PROJECT_IMAGES(projectId, context)
      : null,
    async () => {
      if (projectId === undefined || context === undefined) {
        throw new Error('Project id and context must not be undefined')
      }
      const response =
        await api.getProjectDatasetApiProjectsProjectIdDatasetContextGet({
          projectId,
          context,
        })
      return response.data
    },
  )
}

interface UseSelectedImageReturns {
  data: ProjectImageResponse | undefined
  mutate: (PixelSize: number | undefined) => Promise<void>
  isLoading: boolean
  isValidating: boolean
  error: unknown
}

export const useSelectedImage = (
  projectId: string | undefined,
): UseSelectedImageReturns => {
  const [selectedImageId] = useSelectedImageId()
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const update = async (pixelSize: number | undefined): Promise<void> => {
    if (projectId !== undefined && selectedImageId !== undefined) {
      await api.updateImagePropertiesApiProjectsProjectIdImageImageIdPut({
        projectId,
        imageId: selectedImageId,
        projectImageProperties: {
          pixelSizeUm: pixelSize,
        },
      })
      await imageProjectInfo.mutate()
    }
  }
  return {
    ...imageProjectInfo,
    data: selectedImageId !== undefined ? imageProjectInfo.data : undefined,
    mutate: update,
  }
}

export const useImageProjectInfo = (
  projectId?: string,
  imageId?: string,
): SWRResponse<ProjectImageResponse> => {
  return useSWR(
    CACHE_KEYS.IMAGE_PROJECT_INFO(projectId, imageId),
    async ({ data }) => {
      const response = await api.getImageInfoApiImagesImageIdProjectIdInfoGet(data)
      return response
    },
  )
}

export function useDemoImages (): SWRResponse<ImageResponse[]> {
  return useSWR(CACHE_KEYS.DEMO_IMAGES, async () => {
    const response = await api
      .getDemoImagesApiImagesGroupDemoGet({})
      .then((imageGroup) => {
        return flatMap(imageGroup.data, (data) => {
          return data.images
        })
      })
    return response
  })
}

interface UseProjectClasses {
  data: AlgoClass[] | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  invalidate: () => Promise<void>
  addClass: () => Promise<AlgoClass>
  deleteClass: (colorIndex: number) => Promise<void>
  updateClassName: (colorIndex: number, name: string) => Promise<void>
}
export function useProjectClasses (projectId?: string): UseProjectClasses {
  const { data, error, isLoading, isValidating, mutate } = useSWR(CACHE_KEYS.PROJECT_CLASSES(projectId), async ({ data }) => {
    const classes = await api.getProjectClassesApiProjectsProjectIdClassGet(data)
    // This ensure that the class index is the same as the color index
    return classes.sort((a, b) => a.colorIndex - b.colorIndex)
  })

  const invalidate = useCallback(async (): Promise<void> => {
    await mutate()
  }, [mutate])

  const addClass = useCallback(async (): Promise<AlgoClass> => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    return await API.addProjectClass(projectId)
  }, [projectId])

  const deleteClass = useCallback(async (colorIndex: number): Promise<void> => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await API.deleteProjectClass(projectId, colorIndex)
    await globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATUS(projectId))
    await globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  }, [projectId])

  const updateClassName = useCallback(async (colorIndex: number, name: string): Promise<void> => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    await API.updateProjectClass(projectId, name, colorIndex)
  }, [projectId])

  return {
    data,
    error,
    isLoading,
    isValidating,
    invalidate,
    addClass,
    deleteClass,
    updateClassName,
  }
}

export const useProjectTrainingState = (projectId: string | undefined): SWRResponse<ProjectTrainingState> => {
  return useSWR(
    CACHE_KEYS.PROJECT_TRAINING_STATE(projectId), async ({ data }) => {
      return (await api.getProjectTrainingStateApiProjectsProjectIdTrainingStateGet(data))
    },
  )
}

export interface LicenceInformation {
  key: string
  isPremium: boolean
  isTrial: boolean
  expirationDate: Date
  duration: number
  enableExportProject: boolean
  algoClemexNetLiteV1: boolean
  algoClemexNetLiteV2: boolean
  theme: string
}
type useLicenceReturn = SWRResponse<LicenceInformation, boolean>
export const licenceFetcher = async (): Promise<LicenceInformation> => {
  const licence = await api.getLicenceApiLicenceGet({})
  return {
    key: licence.key,
    isPremium: licence.type === LicenceType.Premium,
    isTrial: licence.type === LicenceType.Trial,
    expirationDate: licence.expiration,
    duration: licence.duration,
    enableExportProject: licence.features.exportProject,
    algoClemexNetLiteV1: licence.features.algoClemexNetLiteV1,
    algoClemexNetLiteV2: licence.features.algoClemexNetLiteV2,
    theme: licence.features.theme,
  }
}

export const useLicence = (): useLicenceReturn => {
  return useSWR(
    CACHE_KEYS.LICENCE, licenceFetcher,
  )
}

export function useAnnotationClassesDistribution (projectId?: string, imageId?: string): SWRResponse<Record<string, number>> {
  return useSWR(CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION(projectId, imageId), async ({ data }) => {
    return (await api.getProjectImageClassesAnnotationDistributionApiProjectsProjectIdImagesImageIdClassesAnnotationDistributionGet(data)).distribution
  })
}

interface UseProjectActions {
  triggerCancelTrain: () => Promise<void>
  triggerTrain: () => Promise<void>
  triggerReset: () => Promise<void>
  triggerClearAllAnnotations: () => Promise<void>
  triggerClearAnnotationsByColorIndex: (colorIndex: number) => Promise<void>
}
export const useProjectActions = (projectId: string): UseProjectActions => {
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)

  const triggerTaskRevalidation = useTriggerProjectTrainingStatusRevalidation(projectId)
  const { mutate: mutateTrainingProgression } = useTrainingProgression(projectId)

  const triggerTrain = useCallback(async (): Promise<void> => {
    await API.trainProject(projectId)
    await triggerTaskRevalidation()
    await mutateTrainingProgression()
  }, [projectId, triggerTaskRevalidation, mutateTrainingProgression])

  const triggerCancelTrain = useCallback(async (): Promise<void> => {
    await API.cancelTrainProject(projectId)
    await triggerTaskRevalidation()
  }, [triggerTaskRevalidation, projectId])

  const triggerReset = useCallback(async (): Promise<void> => {
    await API.resetProject(projectId)
    await mutateProjectTrainingState()
    await triggerTaskRevalidation()
    await globalMutate((key) => {
      if (typeof key === 'object') {
        const cacheKey = key as CacheKey<unknown>
        if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_DIRECT_MEASURE.name) {
          const projectImageDirectMeasuresCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
          if (projectImageDirectMeasuresCacheKey.data.projectId === projectId) {
            return true
          }
        }
        if (cacheKey.keyId === CACHE_KEYS.METADATA_ANNOTATIONS.name) {
          const projectImageMetadataAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
          if (projectImageMetadataAnnotationsCacheKey.data.projectId === projectId) {
            return true
          }
        }
      }
      return false
    }, undefined, {
      revalidate: true,
    })
    // Dispatch PROJECT_RESETED event
    // This should be used by the canvas to unload resources
    EVENT_DISPATCHER.dispatch_project_reseted()
  }, [projectId, mutateProjectTrainingState, triggerTaskRevalidation])

  const triggerClearAllAnnotations = useCallback(async (): Promise<void> => {
    await API.triggerClearAllAnnotations(projectId)
  }, [projectId])

  const triggerClearAnnotationsByColorIndex = useCallback(async (colorIndex: number): Promise<void> => {
    await API.triggerClearAnnotationsByColorIndex(projectId, colorIndex)
  }, [projectId])

  return {
    triggerCancelTrain,
    triggerTrain,
    triggerReset,
    triggerClearAllAnnotations,
    triggerClearAnnotationsByColorIndex,
  }
}

const useUserSettings = (): SWRResponse<UserSettingsCanvasResponse> => {
  return useSWR(CACHE_KEYS.USER_SETTINGS, async () => {
    return (await api.getUserSettingsApiSettingsGet({}))
  })
}

interface UseUserSettingsCanvas {
  data: UserSettingsCanvasResponse | undefined
  error: unknown
  isLoading: boolean
  isValidating: boolean
  updateUserSettingsCanvas: (userSettingsCanvas: UserSettingsCanvasValueObject) => Promise<void>
  resetUserSettingsCanvas: () => Promise<void>
}
export const useUserSettingsCanvas = (): UseUserSettingsCanvas => {
  const { data, error, isLoading, isValidating, mutate } = useUserSettings()

  const updateUserSettingsCanvas = useCallback(async (userSettingsCanvas: UserSettingsCanvasValueObject) => {
    await api.updateUserSettingsCanvasApiSettingsCanvasPut({
      userSettingsCanvasValueObject: userSettingsCanvas,
    })
    await mutate()
  }, [mutate])

  const resetUserSettingsCanvas = useCallback(async () => {
    await api.resetUserSettingsCanvasApiSettingsCanvasResetPost({})
    await mutate()
  }, [mutate])

  return {
    data,
    error,
    isLoading,
    isValidating,
    updateUserSettingsCanvas,
    resetUserSettingsCanvas,
  }
}

export const useSharedProjectDetails = (sharedProjectToken: string): SWRResponse<ProjectShareDetailsResponse> => {
  return useSWR(CACHE_KEYS.PROJECT_SHARE_DETAILS(sharedProjectToken), async () => await api.getProjectShareDetailsApiProjectsShareSharingTokenGet({
    sharingToken: sharedProjectToken,
  }))
}

export function useProjectBundle (projectId?: string): SWRResponse<ProjectBundleStatusResponse> {
  const TIME_BETWEEN_CHECK_CALL = 15 * 1000 // 15000ms (15s)
  return useSWR(projectId !== undefined ? CACHE_KEYS.PROJECT_BUNDLE(projectId) : null, async () => {
    if (projectId === undefined) {
      throw new Error('Project id must not be undefined')
    }
    return await api.getProjectPluginBundleStatusApiProjectsProjectIdBundleStatusPost({ projectId })
  }, {
    refreshInterval: TIME_BETWEEN_CHECK_CALL,
  })
}
