import { api } from '@app/api/api'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { TaskStatus, type ProjectTrainingSnapshotPredictionsStatus, type ProjectTrainingStatus } from '@app/api/openapi'
import { type ProjectImagesStatus } from '@app/api/openapi/models/ProjectImagesStatus'
import useSWR, { type SWRResponse } from 'swr'

export const IN_PROGRESS_STATUSES: Array<TaskStatus | undefined> = [TaskStatus.Pending, TaskStatus.Running]

export const useProjectTrainingStatus = (projectId: string | undefined): SWRResponse<ProjectTrainingStatus> => {
  const cacheKey = CACHE_KEYS.PROJECT_TRAINING_STATUS(projectId)
  return useSWR(
    cacheKey,
    async ({ data }) => {
      return await api.getProjectTrainingStatusApiTrainProjectIdStatusGet(data)
    },
    {
      ...cacheKey?.swrConfig,
      refreshInterval: (latestData: ProjectTrainingStatus | undefined) => {
        if (latestData === undefined || latestData.inProgressTraining != null) {
          return 1000
        } else {
          return 30000
        }
      },
    },
  )
}

export const useTriggerProjectTrainingStatusRevalidation = (projectId: string | undefined): () => Promise<void> => {
  const { mutate } = useProjectTrainingStatus(projectId)
  return async () => {
    if (projectId === undefined) {
      await mutate()
    } else {
      await mutate(await api.getProjectTrainingStatusApiTrainProjectIdStatusGet({ projectId }))
    }
  }
}

const isAnyProjectTrainingSnapshotPredictionsStatusInProgress = (data: ProjectTrainingSnapshotPredictionsStatus): boolean => {
  return Object.values(data.images).some((image) => {
    return (
      IN_PROGRESS_STATUSES.includes(image.predictionTaskStatus) ||
      IN_PROGRESS_STATUSES.includes(image.predictionMaskTilingTaskStatus) ||
      IN_PROGRESS_STATUSES.includes(image.predictionInstanceSegmentationStatus)
    )
  })
}
export const useProjectTrainingSnapshotPredictionsStatus = (projectTrainingSnapshotId: string | undefined): SWRResponse<ProjectTrainingSnapshotPredictionsStatus> => {
  const cacheKey = CACHE_KEYS.PROJECT_TRAINING_SNAPSHOT_PREDICTIONS_STATUS(projectTrainingSnapshotId)
  return useSWR(
    cacheKey,
    async ({ data }) => {
      return await api.getProjectTrainingSnapshotPredictionStatusApiPredictProjectTrainingSnapshotIdStatusGet(data)
    },
    {
      ...cacheKey?.swrConfig,
      refreshInterval: (latestData: ProjectTrainingSnapshotPredictionsStatus | undefined) => {
        if (latestData === undefined) {
          return 1000
        } else if (isAnyProjectTrainingSnapshotPredictionsStatusInProgress(latestData)) {
          return 1000
        } else {
          return 30000
        }
      },
    },
  )
}

const isAnyProjectImagesStatusInProgress = (data: ProjectImagesStatus): boolean => {
  return Object.values(data.images).some((image) => {
    return (
      IN_PROGRESS_STATUSES.includes(image.imageTilingStatus)
    )
  })
}
export const useProjectImageStatus = (projectId: string | undefined): SWRResponse<ProjectImagesStatus> => {
  const cacheKey = CACHE_KEYS.PROJECT_IMAGES_STATUS(projectId)
  return useSWR(
    cacheKey,
    async ({ data }) => {
      return await api.getProjectImagesStatusApiProjectProjectIdImagesStatusGet(data)
    },
    {
      ...cacheKey?.swrConfig,
      refreshInterval: (latestData: ProjectImagesStatus | undefined) => {
        if (latestData === undefined) {
          return 1000
        } else if (isAnyProjectImagesStatusInProgress(latestData)) {
          return 1000
        } else {
          return 30000
        }
      },
    },
  )
}

export interface CustomSWRResponse<D, E = unknown> {
  data: D | undefined
  isLoading: boolean
  isValidating: boolean
  error: E
}

export const useCurrentProjectTrainingSnapshotId = (projectId: string | undefined): CustomSWRResponse<NonNullable<ProjectTrainingStatus['currentProjectTrainingSnapshotId']>> => {
  const { data, mutate, ...rest } = useProjectTrainingStatus(projectId)
  return {
    data: data?.currentProjectTrainingSnapshotId ?? undefined,
    ...rest,
  }
}

export const useLastErrorTraining = (projectId: string | undefined): CustomSWRResponse<NonNullable<ProjectTrainingStatus['lastTrainingError']>> => {
  const { data, mutate, ...rest } = useProjectTrainingStatus(projectId)
  return {
    data: data?.lastTrainingError ?? undefined,
    ...rest,
  }
}

export const useInProgressTraining = (projectId: string | undefined): CustomSWRResponse<NonNullable<ProjectTrainingStatus['inProgressTraining']>> => {
  const { data, mutate, ...rest } = useProjectTrainingStatus(projectId)
  return {
    data: data?.inProgressTraining ?? undefined,
    ...rest,
  }
}

export const usePredictionStatus = (imageId: string | undefined, projectTrainingSnapshotId: string | undefined): CustomSWRResponse<ProjectTrainingSnapshotPredictionsStatus['images'][string]['predictionTaskStatus']> => {
  const { data, mutate, ...rest } = useProjectTrainingSnapshotPredictionsStatus(projectTrainingSnapshotId)
  return {
    data: imageId === undefined ? undefined : data?.images[imageId]?.predictionTaskStatus ?? undefined,
    ...rest,
  }
}

export const usePredictionMaskTilingStatus = (imageId: string | undefined, projectTrainingSnapshotId: string | undefined): CustomSWRResponse<ProjectTrainingSnapshotPredictionsStatus['images'][string]['predictionMaskTilingTaskStatus']> => {
  const { data, mutate, ...rest } = useProjectTrainingSnapshotPredictionsStatus(projectTrainingSnapshotId)
  return {
    data: imageId === undefined ? undefined : data?.images[imageId]?.predictionMaskTilingTaskStatus ?? undefined,
    ...rest,
  }
}

export const usePredictionMaskAnalyticDetectedObjectStatus = (imageId: string | undefined, projectTrainingSnapshotId: string | undefined): CustomSWRResponse<ProjectTrainingSnapshotPredictionsStatus['images'][string]['predictionInstanceSegmentationStatus']> => {
  const { data, mutate, ...rest } = useProjectTrainingSnapshotPredictionsStatus(projectTrainingSnapshotId)
  return {
    data: imageId === undefined ? undefined : data?.images[imageId]?.predictionInstanceSegmentationStatus ?? undefined,
    ...rest,
  }
}

export const useImageTilingStatus = (imageId: string | undefined, projectId: string | undefined): CustomSWRResponse<ProjectImagesStatus['images'][string]['imageTilingStatus']> => {
  const { data, mutate, ...rest } = useProjectImageStatus(projectId)
  return {
    data: imageId === undefined ? undefined : data?.images[imageId]?.imageTilingStatus ?? undefined,
    ...rest,
  }
}
