import { useRef, type FC } from 'react'
import MoreIcon from '@material-design-icons/svg/filled/more_vert.svg'
import styles from './styles/image-thumbnail.module.scss'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { ContextualMenu } from '@components/contextual-menu'
import { ProjectDatasetContext } from '@app/api/openapi'
import { EVENTS_ID } from '@app/constants'

const THUMBNAIL_FETCH_MAX_RETRY = 10

interface ImageThumbnailProps {
  imageThumbnailUrl?: string
  imagePredictionMaskThumbnailUrl?: string
  width: number
  height: number
}
type EditorImageThumbnailProps = ImageThumbnailProps & {
  imageId: string
  imageSlug?: string
  isOneImage: boolean
  isDisabled?: boolean
  step?: ProjectDatasetContext
}
interface ThumbnailImageMenuProps {
  children: React.ReactElement
  step?: ProjectDatasetContext
  imageId: string
  imageSlug?: string
  trigger?: Array<('contextMenu' | 'click' | 'hover')>
  isOneImage: boolean
  isDisabled?: boolean
}
const ThumbnailImageMenu: FC<ThumbnailImageMenuProps> = (props) => (props.step !== undefined
  ? <ContextualMenu className={clsx({ [styles.disableInteraction]: props.isDisabled })} trigger={props.trigger} menuItems={
      props.step === ProjectDatasetContext.Training
        ? {
            [EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION]: { imageId: props.imageId },
            [EVENTS_ID.PROJECT_DELETE_IMAGE]: { imageId: props.imageId },
          }
        : (props.isOneImage)
            ? {
                [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE]: { imageId: props.imageId, imageSlug: props.imageSlug },
                [EVENTS_ID.PROJECT_DELETE_IMAGE]: { imageId: props.imageId },
              }
            : {
                [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING]: { imageId: props.imageId },
                [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE]: { imageId: props.imageId, imageSlug: props.imageSlug },
                [EVENTS_ID.PROJECT_DELETE_IMAGE]: { imageId: props.imageId },
              }
    }>
      {props.children}
    </ContextualMenu>
  : props.children
)

export const ImageThumbnail: FC<ImageThumbnailProps> = (props) => {
  const intl = useIntl()
  const imageThumbnailErrorCountRef = useRef(0)
  const imagePredictionMaskThumbnailErrorCountRef = useRef(0)

  const noImageText = intl.formatMessage({
    id: 'image-thumbnail.no-image.text',
    defaultMessage: 'No image',
  })

  const { imageThumbnailUrl, imagePredictionMaskThumbnailUrl, width, height } = props
  return <div className={styles.thumbnailContainer} style={{ width, height }}>
    {
      imageThumbnailUrl !== undefined
        ? <>
            <img
              className={clsx(styles.thumbnail, styles.thumbnailImage)}
              src={imageThumbnailUrl}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                imageThumbnailErrorCountRef.current += 1
                if (imageThumbnailErrorCountRef.current > THUMBNAIL_FETCH_MAX_RETRY) {
                  return
                }
                if (e.target !== undefined) {
                  setTimeout(() => {
                    (e.target as HTMLImageElement).src = imageThumbnailUrl
                  }, 1000 * imageThumbnailErrorCountRef.current)
                }
              }}
              width={width}
            />
            {
              imagePredictionMaskThumbnailUrl !== undefined &&
                <img
                  className={clsx(styles.thumbnail, styles.thumbnailPrediction)}
                  src={imagePredictionMaskThumbnailUrl}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    imagePredictionMaskThumbnailErrorCountRef.current += 1
                    if (imagePredictionMaskThumbnailErrorCountRef.current > THUMBNAIL_FETCH_MAX_RETRY) {
                      return
                    }
                    if (e.target !== undefined) {
                      setTimeout(() => {
                        (e.target as HTMLImageElement).src = imagePredictionMaskThumbnailUrl ?? ''
                      }, 1000 * imagePredictionMaskThumbnailErrorCountRef.current)
                    }
                  }}
                  width={width}
                />
            }
          </>
        : <div className={styles.missingThumbnailImage}>{noImageText}</div>
    }
  </div>
}

export const EditorImageThumbnail: React.FC<EditorImageThumbnailProps> = ({ imageId, imageSlug, step, ...rest }) => (
  <ThumbnailImageMenu step={step} imageId={imageId} imageSlug={imageSlug} isOneImage={rest.isOneImage}>
    <div className={styles.thumbnailContainer}>
      <ImageThumbnail
        height={rest.height}
        width={rest.width}
        imagePredictionMaskThumbnailUrl={rest.imagePredictionMaskThumbnailUrl}
        imageThumbnailUrl={rest.imageThumbnailUrl}
      />
      {
        <div className={styles.thumbnailMenu} onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          // Send a context Menu Event to display the ThumbnailMenu
          const contextMenuEvent = new MouseEvent('contextmenu', {
            bubbles: true,
            cancelable: true,
            clientX: event.nativeEvent.clientX,
            clientY: event.nativeEvent.clientY,
          })
          event.nativeEvent.target?.dispatchEvent(contextMenuEvent)
          // Avoid automatic scroll image
          event.stopPropagation()
        } }>
          <MoreIcon />
        </div>
      }
    </div>
  </ThumbnailImageMenu>
)
