import { EVENTS_ID } from '@app/constants'

export const EVENT_DISPATCHER = {
  dispatch_editor_canvas_focus_object: (
    imageId: string,
    bbox: [number, number, number, number],
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT, {
        detail: {
          imageId,
          bbox,
        },
      }),
    )
  },
  dispatch_project_reseted: () => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_RESETED))
  },
} as const
