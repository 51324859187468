/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LicenceFeaturesResponse
 */
export interface LicenceFeaturesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    exportProject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexNetLiteV1: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceFeaturesResponse
     */
    algoClemexNetLiteV2: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenceFeaturesResponse
     */
    theme: string;
}

/**
 * Check if a given object implements the LicenceFeaturesResponse interface.
 */
export function instanceOfLicenceFeaturesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "exportProject" in value;
    isInstance = isInstance && "algoClemexNetLiteV1" in value;
    isInstance = isInstance && "algoClemexNetLiteV2" in value;
    isInstance = isInstance && "theme" in value;

    return isInstance;
}

export function LicenceFeaturesResponseFromJSON(json: any): LicenceFeaturesResponse {
    return LicenceFeaturesResponseFromJSONTyped(json, false);
}

export function LicenceFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenceFeaturesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportProject': json['export_project'],
        'algoClemexNetLiteV1': json['algo_clemex_net_lite_v1'],
        'algoClemexNetLiteV2': json['algo_clemex_net_lite_v2'],
        'theme': json['theme'],
    };
}

export function LicenceFeaturesResponseToJSON(value?: LicenceFeaturesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'export_project': value.exportProject,
        'algo_clemex_net_lite_v1': value.algoClemexNetLiteV1,
        'algo_clemex_net_lite_v2': value.algoClemexNetLiteV2,
        'theme': value.theme,
    };
}

