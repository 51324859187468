import { useMemo, useState, type FC } from 'react'
import { DataTable } from '@app/pages/editor-page/databrowser/table/data-table'
import { createDirectMeasureColumnsDef } from '@app/pages/editor-page/databrowser/table/direct-measure-columns'
import { FormattedMessage } from 'react-intl'
import { ToggleGroup, ToggleGroupItem } from '@shadcn-ui/components/ui/toggle-group'
import { createAnalyticDirectMeasureDownloadCSVURL, createAnalyticPredictionDetectedObjectsDownloadCSVURL, useAnalyticDataDirectMeasures, useAnalyticDataInstanceSegmentation } from '@app/api/analytic'
import { useImageProjectInfo, useProjectSettingsDirectMeasure, useProjectTrainingSnapshot } from '@app/api/hooks'
import { useEditorStore } from '@app/stores/editor'
import CloseIcon from '@material-design-icons/svg/outlined/close.svg'
import { createDetectedObjectStatsColumnsDef } from '@app/pages/editor-page/databrowser/table/detected-objects-columns'
import { useDataBrowserStore } from '@app/stores/databrowser'

enum DataBrowserType {
  DirectMeasure = 'DirectMeasure',
  DectectedObject = 'DectectedObject',
}

export const DataBrowserTable: FC = () => {
  const [dataBrowserType, setDataBrowserType] = useState<DataBrowserType>(DataBrowserType.DectectedObject)
  const { close } = useDataBrowserStore((state) => ({
    close: state.close,
  }))

  const onSelectDataBrowserType = (value: DataBrowserType | ''): void => {
    if (value !== '') {
      setDataBrowserType(value)
    }
  }

  return <div className='grow flex flex-col border-r-2 border-clemex-shellGray max-h-full max-w-full overflow-auto'>
    <div className="flex flex-row justify-between items-center gap-4 p-2 border-b-[1px] border-clemex-shellGray">
      <div className='clemex-title'>
        <FormattedMessage id='editor-page.draggable-panel.databrowser.title' defaultMessage='Data Browser' />
      </div>
      <div className="flex flex-row justify-between items-center">
        <button onClick={close}>
          <CloseIcon />
        </button>
      </div>
    </div>
    <div className='grow flex flex-col max-h-full max-w-full overflow-auto'>
      <div className='flex gap-[16px] justify-between p-3'>
        <div className='flex items-center shrink-0 text-sm text-clemex-darkGray'>
          <FormattedMessage id="databrowser.table.type" defaultMessage="Data Type:" />
        </div>
        <ToggleGroup type="single" value={dataBrowserType} onValueChange={(value) => { onSelectDataBrowserType(value as DataBrowserType | '') }}>
          <ToggleGroupItem value={DataBrowserType.DirectMeasure}>
            <FormattedMessage id="databrowser.table.type.selection.direct-measure" defaultMessage="Direct Measure" />
          </ToggleGroupItem>
          <ToggleGroupItem value={DataBrowserType.DectectedObject}>
            <FormattedMessage id="databrowser.table.type.selection.detected-object" defaultMessage="Detected Object" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      {
        dataBrowserType === DataBrowserType.DirectMeasure
          ? <DirectMeasureDataBrowserTable />
          : dataBrowserType === DataBrowserType.DectectedObject
            ? <DetectedObjectDataBrowserTable />
            : null
      }
    </div>
  </div>
}

const DirectMeasureDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const { data: projectSettingsDirectMeasureData } = useProjectSettingsDirectMeasure(projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const { data: analyticPredictionDetectedObjects, isLoading } = useAnalyticDataDirectMeasures(selectedImageId)
  const directMeasureColumnsDef = createDirectMeasureColumnsDef({
    pixelSizeUm: imageProjectInfo.data?.pixelSizeUm ?? undefined,
    angleUnit: projectSettingsDirectMeasureData?.angleUnit,
    imageId: selectedImageId,
  })

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined) {
      return undefined
    }
    return createAnalyticDirectMeasureDownloadCSVURL(selectedImageId)
  }, [selectedImageId])

  return <DataTable
    columns={directMeasureColumnsDef}
    data={isLoading ? undefined : analyticPredictionDetectedObjects?.items ?? null}
    csvURL={csvURL}
  />
}

const DetectedObjectDataBrowserTable: FC = () => {
  const projectId = useEditorStore((state) => state.projectId)
  const selectedImageId = useEditorStore((state) => state.selectedImageId)
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: analyticPredictionDetectedObjects, isLoading } = useAnalyticDataInstanceSegmentation(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const detectedObjectColumnsDef = useMemo(() => {
    return createDetectedObjectStatsColumnsDef({
      pixelSizeUm: imageProjectInfo.data?.pixelSizeUm ?? undefined,
      imageId: selectedImageId,
      projectTrainingSnapshotId: projectTrainingSnapshot?.projectTrainingSnapshotId,
    })
  }, [imageProjectInfo, projectTrainingSnapshot, selectedImageId])

  const csvURL = useMemo(() => {
    if (selectedImageId === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    return createAnalyticPredictionDetectedObjectsDownloadCSVURL(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)
  }, [projectTrainingSnapshot, selectedImageId])

  return <DataTable
    columns={detectedObjectColumnsDef}
    data={isLoading ? undefined : analyticPredictionDetectedObjects?.items ?? null}
    csvURL={csvURL}
    defaultHiddenColumns={{
      shellArea: false,
      hullArea: false,
      minX: false,
      minY: false,
      maxX: false,
      maxY: false,
      centroidX: false,
      centroidY: false,
    }}
    defaultSorting={[
      {
        id: 'area',
        desc: true,
      },
    ]}
  />
}
