import { create } from 'zustand'

interface DataBrowserState {
  isOpen: boolean
}

interface DataBrowserActions {
  open: () => void
  close: () => void
  toggleOpenClose: () => void
  reset: () => void
}

const initailState: DataBrowserState = {
  isOpen: false,
}

export const useDataBrowserStore = create<DataBrowserState & DataBrowserActions>((set) => ({
  ...initailState,
  open: () => { set({ isOpen: true }) },
  close: () => { set({ isOpen: false }) },
  toggleOpenClose: () => { set((state) => ({ isOpen: !state.isOpen })) },
  reset: () => { set(initailState) },
}))
