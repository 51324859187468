import { type AnnotationPatchRequest as MetadataAnnotationPatchRequest, type MetadataAnnotationInput } from '@app/api/openapi'
import { api } from './api'
import useSWR from 'swr'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { useCallback, useState } from 'react'

const MAX_RETRY = 3

const listMetadataAnnotation = async (projectId: string, imageId: string): Promise<MetadataAnnotationInput[]> => {
  const response = await api.listMetadataAnnotationApiProjectsProjectIdImagesImageIdMetadataAnnotationGet({ projectId, imageId })
  return response
}

const patchMetadataAnnotations = async (
  projectId: string,
  imageId: string,
  patch: MetadataAnnotationPatchRequest,
): Promise<void> => {
  await api.patchMetadataAnnotationsApiProjectsProjectIdImagesImageIdMetadataAnnotationPatch({
    projectId,
    imageId,
    annotationPatchRequest: patch,
  })
}

interface UseMetadataAnnotation {
  data: MetadataAnnotationInput[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
  patch: (patch: MetadataAnnotationPatchRequest) => void
  savePatches: () => Promise<void>
}
export const useMetadataAnnotation = (projectId: string | undefined, imageId: string | undefined): UseMetadataAnnotation => {
  const cacheKey = CACHE_KEYS.METADATA_ANNOTATIONS(projectId, imageId)
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    cacheKey,
    async ({ data }) => {
      return await listMetadataAnnotation(data.projectId, data.imageId)
    },
  )

  const [cumulativePatches, setCumulativePatches] = useState<MetadataAnnotationPatchRequest[]>([])

  const patch = (changes: MetadataAnnotationPatchRequest): void => {
    setCumulativePatches((prev) => {
      return [
        ...prev,
        changes,
      ]
    })
  }

  const savePatches = useCallback(async (): Promise<void> => {
    if (projectId === undefined || imageId === undefined) {
      return
    }
    setCumulativePatches([])
    let retry = MAX_RETRY
    let success = false
    while (!success && retry > 0) {
      try {
        for (const patch of cumulativePatches) {
          await patchMetadataAnnotations(projectId, imageId, patch)
        }
        success = true
      } catch (e) {
        retry -= 1
        console.error(`Failed to save metadata annotation changes(retry ${MAX_RETRY - retry} / ${MAX_RETRY})`, e)
        if (retry === 0) {
          throw e
        }
      } finally {
        // After saving the patches, we need to refresh the data
        // In case of failure, we also need to refresh the data to get the latest state
        await mutate()
      }
    }
  }, [cumulativePatches, imageId, projectId, mutate])

  return {
    data,
    isLoading,
    isValidating,
    error,
    patch,
    savePatches,
  }
}
