import type * as React from 'react'
import styles from './styles/training-options.module.scss'
import { Typography } from 'antd'
import { FormattedMessage, FormattedRelativeTime, IntlProvider } from 'react-intl'
import { useProjectTrainingSnapshot } from '@app/api/hooks'
import { useInProgressTraining, useLastErrorTraining } from '@app/api/resource-status'

const { Title } = Typography

interface TrainingElapsedTimeProps {
  projectId: string
}
export const TrainingElapsedTime: React.FC<TrainingElapsedTimeProps> = ({ projectId }) => {
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: inProgressTraining } = useInProgressTraining(projectId)
  const { data: lastErrorTraining } = useLastErrorTraining(projectId)

  const computeElapsedTimeInSeconds = (): number | null => {
    if (projectTrainingSnapshot === undefined) {
      return null
    }
    const currentDate = new Date()
    const diffInMilliseconds = currentDate.getTime() - new Date(projectTrainingSnapshot.creationDate).getTime()
    return Math.floor(diffInMilliseconds / 1000)
  }
  const elapsedTime = computeElapsedTimeInSeconds()

  return (
    <Title level={5} className={styles.title}>
      <FormattedMessage id="training-elapsed-time.last-training.label" defaultMessage={'Last Training: '} />&nbsp;
      {inProgressTraining === undefined && lastErrorTraining !== undefined && <FormattedMessage id="training-elapsed-time.training-failed.value" defaultMessage={'Training Failed'} />}
      {inProgressTraining !== undefined && <FormattedMessage id="training-elapsed-time.training-in-progress.value" defaultMessage={'In progress'} />}
      {(inProgressTraining === undefined && lastErrorTraining === undefined && elapsedTime === null) && <FormattedMessage id="training-elapsed-time.training-snapshot-unavailable.value" defaultMessage={'Not available'} />}
      {(inProgressTraining === undefined && lastErrorTraining === undefined && elapsedTime !== null) && (
        <IntlProvider locale="en">
          <FormattedRelativeTime value={-elapsedTime} numeric="auto" updateIntervalInSeconds={30} />
        </IntlProvider>
      )}
    </Title>
  )
}
