import { CACHE_KEYS } from '@app/api/cache-keys'
import { type ProjectImageResponse, DefaultApi, Configuration, type UserManagementResponse, type ProjectResponse, type OnboardingTaskType, type PageName, type ErrorMessage, ErrorMessageCodes, type ImageResponse, type ProjectDatasetContext, type ClassAnnotationGeoJSON, type ProjectClassAnnotationResponse, type UserOnboardingTaskNamespace, type UserAssistanceType, type ProjectShareResponse } from './openapi'
import { type ResponseContext } from './openapi/runtime'
import { mutate as globalMutate } from 'swr'
import { captureException } from '@sentry/react'
import { type UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
import { type RedeemShareProjectResponse } from '@app/api/openapi/models/RedeemShareProjectResponse'
export const DEFAULT_PROJECT_LIST_LIMIT = 100
const STATUS_CODE_MAINTENANCE = 503
const MAINTENANCE_STATUS_MESSAGE = 'Clemex Studio is down for maintenance'

let defaultAPI: DefaultApi | null = null

// handle maintenance mode middleware
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
const maintenanceHandler = async (context: ResponseContext): Promise<Response | void> => {
  if (context.response.status === STATUS_CODE_MAINTENANCE &&
    await context.response.text() === MAINTENANCE_STATUS_MESSAGE) {
    const routeUrl = `/503?redirectionUrl=${encodeURIComponent(window.location.href)}`
    if (window.location.pathname !== '/503') {
      window.location.replace(`${routeUrl}`)
      return
    }
    return context.response
  } else {
    return context.response
  }
}

function getDefaultAPI (): DefaultApi {
  if (defaultAPI !== null) {
    return defaultAPI
  }

  const isSSR = typeof window === 'undefined'

  if (isSSR) {
    return new DefaultApi(new Configuration())
  }

  const basePath = window.location.origin
  const apiConfiguration = new Configuration({
    fetchApi: window.fetch.bind(window),
    basePath,
    middleware: [{
      post: maintenanceHandler,
    }],
  })
  defaultAPI = new DefaultApi(apiConfiguration)

  return defaultAPI
}

export const api = getDefaultAPI()

export const getImageSizeFromImageResponse = (imgResp: ProjectImageResponse): ProjectImageResponse => {
  return {
    id: imgResp.id,
    slug: imgResp.slug,
    name: imgResp.name,
    width: imgResp.width,
    height: imgResp.height,
  }
}

export const createProject = async (name: string, description: string, projectArchive: Blob): Promise<ProjectResponse> => {
  const projectInfo = await api.createProjectApiProjectsPost({ description, name, projectArchive })
    .then((projectResponse) => {
      return {
        ...projectResponse,
      }
    })
  return projectInfo
}

export const updateProject = async (projectId: string, projectName: string, projectDescription: string): Promise<ProjectResponse> => {
  return await api.updateProjectApiProjectsProjectIdPut({ projectUpdateRequest: { description: projectDescription, name: projectName }, projectId })
}

export const archiveProject = async (projectId: string): Promise<void> => {
  return await api.archiveProjectApiProjectsProjectIdArchivePost({ projectId })
}

export const duplicateProject = async (projectId: string): Promise<ProjectResponse> => {
  return await api.duplicateProjectApiProjectsProjectIdDuplicatePost({ projectId })
}

export const register = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
): Promise<UserManagementResponse> => {
  return await api.registerApiRegisterPost({
    userRegistrationRequest: {
      email,
      password,
      firstName,
      lastName,
    },
  })
}

export const activateAccount = async (
  token: string,
): Promise<UserManagementResponse> => {
  return await api.activateAccountApiActivateAccountPost({
    activateAccountRequest: {
      token,
    },
  })
}

export const login = async (
  email: string,
  password: string,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rememberMe: boolean,
): Promise<UserManagementResponse> => {
  return await api.loginApiLoginPost({
    userLoginRequest: {
      email,
      password,
    },
  })
}

export const logout = async (): Promise<UserManagementResponse> => {
  // The logout only requires the session cookie that  is sent by the browser
  return await api.logoutApiLogoutPost({})
}

export const requestResetPassword = async (
  email: string,
): Promise<UserManagementResponse> => {
  return await api.requestResetPasswordApiRequestResetPasswordPost({
    userRequestResetPasswordRequest: {
      email,
    },
  })
}

export const createOrResetPassword = async (
  token: string,
  newPassword: string,
): Promise<UserManagementResponse> => {
  return await api.resetPasswordApiResetPasswordPost({
    userResetPasswordRequest: {
      token,
      newPassword,
    },
  })
}
export const updateProfile = async (profile: {
  firstName: string
  lastName: string
  jobTitle?: string
  organization?: string
  phoneNumber?: string
}): Promise<void> => {
  await api.updateProfileApiUserProfilePut({
    userUpdateProfileRequest: profile,
  })
}

export const createImageThumbnailURL = (imageId: string, width: number, height: number): string => {
  return `/api/images/${imageId}/thumbnail/${width}/${height}?cache=${window.clemex.environmentConfiguration.thumbnailCacheInvalidationKey ?? ''}`
}
export const createImagePredictionThumbnail = (imageId: string, projectTrainingSnapshotId: string, width: number, height: number): string => {
  return `/api/images/${imageId}/prediction/${projectTrainingSnapshotId}/thumbnail/${width}/${height}?cache=${window.clemex.environmentConfiguration.thumbnailCacheInvalidationKey ?? ''}`
}
export const createImageURL = (imageId: string): string => {
  return `/api/images/${imageId}`
}

export const getImageInfo = async (imageId: string, projectId: string): Promise<ProjectImageResponse> => {
  return await api.getImageInfoApiImagesImageIdProjectIdInfoGet({ imageId, projectId })
}

export const postUseImageDemo = async (projectId: string, imageDemoId: string, context: ProjectDatasetContext): Promise<ImageResponse> => {
  return await api.useDemoImageApiProjectsProjectIdUseDemoImageContextImageIdPost({ projectId, imageId: imageDemoId, context })
}

export const getPluginLinkURL = (projectId: string): string => {
  return `/api/projects/${projectId}/bundle/download`
}

export const sendImageToDataset = async (projectId: string, imageId: string, targetContext: ProjectDatasetContext): Promise<void> => {
  await api.switchImageDatasetApiProjectsProjectIdSwitchImageDatasetImageIdContextPost({
    projectId,
    imageId,
    context: targetContext,
  })
}
export const completeUserOnboardingTask = async (taskType: OnboardingTaskType): Promise<void> => {
  await api.completeUserOnboardingTaskApiUserOnboardingTaskCompletePost({
    completeUserOnboardingTaskRequest: {
      taskType,
    },
  })
}
export const completeUserOnboardingTasks = async (tasksType: OnboardingTaskType[]): Promise<void> => {
  await api.completeUserOnboardingTasksApiUserOnboardingTaskCompleteListPost({
    completeUserOnboardingTasksRequest: {
      tasksType,
    },
  })
}

export const skipAllUserOnboardingTasks = async (): Promise<void> => {
  await api.skipAllUserOnboardingTasksApiUserOnboardingTaskSkipAllPost({})
}
export const skipUserOnboardingTaskGroup = async (taskGroupToSkip: UserOnboardingTaskNamespace): Promise<void> => {
  await api.skipUserOnboardingTaskGroupApiUserOnboardingTaskSkipPost({ skipUserOnboardingTaskGroupRequest: { taskGroup: taskGroupToSkip } })
}

export const resetAllUserOnboardingTasks = async (): Promise<void> => {
  await api.resetAllUserOnboardingTasksApiUserOnboardingTaskResetAllPost({})
}

export const ingestPageAccess = async (pageName: PageName): Promise<void> => {
  await api.ingestAccessPageApiIngestAccessPagePost({
    ingestAccessPageRequest: {
      pageName,
    },
  })
}

export const giveCompletedOnboardingFeedback = async (rating: number): Promise<void> => {
  await api.giveCompletedOnboardingFeedbackApiUserFeedbackCompletedOnboardingPost({ giveFeedbackRequest: { rating } })
}

export const errorResponseToErrorMessage = async (errorResponse: Response): Promise<ErrorMessage> => {
  try {
    return await errorResponse.json()
  } catch (e) {
    captureException(new Error('Failed to parse error response'))
    return {
      code: ErrorMessageCodes.UnrecoverableError,
      detail: 'Unknown error',
    }
  }
}

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<UserManagementResponse> => {
  return await api.updatePasswordApiUserPasswordPut({
    userUpdatePasswordRequest: {
      oldPassword,
      newPassword,
    },
  },
  )
}

export async function updateProjectImageAnnotations (
  projectId: string,
  imageId: string,
  newClassAnnotations: ClassAnnotationGeoJSON[],
): Promise<ProjectClassAnnotationResponse> {
  void globalMutate(CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS(projectId, imageId), newClassAnnotations, { revalidate: false })
  const response = await api.postProjectImageClassAnnotationsApiProjectsProjectIdImagesImageIdClassAnnotationsPost({
    projectId,
    imageId,
    updateClassAnnotationsRequest: {
      classAnnotations: newClassAnnotations,
    },
  })
  void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  void globalMutate(CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION(projectId, imageId))
  return response
}

export async function requestAssistance (assistanceRequested: UserAssistanceType[], licenceFeature: UserAssistanceLicenceFeature | null = null): Promise<void> {
  await api.requestAssistanceApiRequestAssistancePost({ userAssistanceRequest: { assistanceRequested, licenceFeature } })
}

export async function requestProjectSharingToken (projectId: string): Promise<ProjectShareResponse> {
  return await api.shareProjectApiProjectsProjectIdSharePost({
    projectId,
  })
}

export async function redeemProjectSharing (sharedProjectToken: string): Promise<RedeemShareProjectResponse> {
  return await api.redeemProjectShareApiProjectsSharePost({
    projectSharingDetailsRequest: {
      sharingToken: sharedProjectToken,
    },
  })
}

export async function requestSmartAnnotation (projectId: string, imageId: string, colorIndex: number, points: Array<Record<number, number>>): Promise<ClassAnnotationGeoJSON[]> {
  const response = await api.getProjectImageSmartAnnotationApiProjectsProjectIdImagesImageIdSmartAnnotationPost(
    {
      projectId,
      imageId,
      projectImageSmartAnnotationRequest: {
        colorIndex,
        points,
      },
    })
  return response.classAnnotations
}

export * from './classes'
export * from './project'
