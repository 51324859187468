import { useProjectTrainingSnapshot, useSelectedImage } from '@app/api/hooks'
import { TaskStatus } from '@app/api/openapi/models/TaskStatus'
import { useImageTilingStatus, usePredictionMaskTilingStatus } from '@app/api/resource-status'
import { ClemexMosaicImage, ClemexMosaicMask } from '@clemex/mosaic-canvas'
import { uniq } from 'lodash'
import { useMemo, useState } from 'react'

class StudioClemexMosaicImage extends ClemexMosaicImage {
  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/tile/${z}/${x}/${y}`
  }
}
interface ImageMetadata {
  mosaicId: string
  x: number
  y: number
  width: number
  height: number
  tileSize?: number
  rotation?: number
}
type StudioClemexMosaicMaskProps = ImageMetadata & { baseURL: string, projectTrainingSnapshotId: string, maskIndex: number }
class StudioClemexMosaicMask extends ClemexMosaicMask {
  private readonly projectTrainingSnapshotId: string
  constructor ({ projectTrainingSnapshotId, ...options }: StudioClemexMosaicMaskProps) {
    super(options)
    this.projectTrainingSnapshotId = projectTrainingSnapshotId
  }

  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/prediction/${this.projectTrainingSnapshotId}/mask/${this.maskIndex}/tile/${z}/${x}/${y}`
  }
}

export interface MosaicConfiguration {
  mosaicImage: StudioClemexMosaicImage
  mosaicMasks: StudioClemexMosaicMask[]
}
interface useMosaicImageType {
  data: StudioClemexMosaicImage | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicImage = (projectId: string | undefined): useMosaicImageType => {
  const { data: selectedImage, ...rest } = useSelectedImage(projectId)
  const { data: imageMaskTilingStatus, isValidating: isValidatingImageMaskTiling } = useImageTilingStatus(selectedImage?.id, projectId)

  // Use cache to avoid revalidation transient state
  const [mosaicImageCache, setMosaicImageCache] = useState<Record<string, StudioClemexMosaicImage>>({})

  // Memoize ClemexMosaicImageData instance per imageId
  const mosaicImage = useMemo((): StudioClemexMosaicImage | undefined => {
    if (selectedImage?.id === undefined) {
      return undefined
    }
    const cacheKey = `${selectedImage.id}`
    if (mosaicImageCache[cacheKey] !== undefined) {
      return mosaicImageCache[cacheKey]
    }
    const _mosaicImage = (selectedImage?.id === undefined || imageMaskTilingStatus !== TaskStatus.Completed || isValidatingImageMaskTiling)
      ? undefined
      : new StudioClemexMosaicImage({
        mosaicId: selectedImage.id,
        height: selectedImage.height,
        width: selectedImage.width,
        x: 0,
        y: 0,
        baseURL: window.location.origin,
      })
    if (_mosaicImage !== undefined) {
      setMosaicImageCache({
        ...mosaicImageCache,
        [cacheKey]: _mosaicImage,
      })
    }
    return _mosaicImage
  }, [selectedImage, mosaicImageCache, imageMaskTilingStatus, isValidatingImageMaskTiling])

  return {
    ...rest,
    data: mosaicImage,
  }
}

interface useMosaicMasksType {
  data: StudioClemexMosaicMask[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicMasks = (projectId: string | undefined): useMosaicMasksType => {
  const { data: projectImageInfo, isLoading, isValidating, error } = useSelectedImage(projectId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: predictionMaskTilingStatus, isValidating: isValidatingPredictionMaskTilingStatus } = usePredictionMaskTilingStatus(projectImageInfo?.id, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const [mosaicMaskCache, setMosaicMaskCache] = useState<Record<string, StudioClemexMosaicMask[]>>({})

  const mosaicMasks = useMemo((): StudioClemexMosaicMask[] | undefined => {
    if (projectImageInfo?.id === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }

    const cacheKey = `${projectImageInfo.id}-${projectTrainingSnapshot.projectTrainingSnapshotId}`

    if (mosaicMaskCache[cacheKey] !== undefined) {
      return mosaicMaskCache[cacheKey]
    }

    if (predictionMaskTilingStatus !== TaskStatus.Completed || isValidatingPredictionMaskTilingStatus) {
      return undefined
    }

    const trainingSnapshotColorIndexes = uniq(projectTrainingSnapshot.classAnnotations.map((classAnnotation) => classAnnotation.colorIndex))
    const masks = trainingSnapshotColorIndexes.map((trainingSnapshotColorIndex) => {
      return new StudioClemexMosaicMask({
        mosaicId: projectImageInfo.id,
        height: projectImageInfo.height,
        width: projectImageInfo.width,
        x: 0,
        y: 0,
        maskIndex: trainingSnapshotColorIndex,
        baseURL: window.location.origin,
        projectTrainingSnapshotId: projectTrainingSnapshot.projectTrainingSnapshotId,
      })
    })
    if (masks.length > 0) {
      setMosaicMaskCache({
        ...mosaicMaskCache,
        [cacheKey]: masks,
      })
    }
    return masks
  }, [isValidatingPredictionMaskTilingStatus, mosaicMaskCache, predictionMaskTilingStatus, projectImageInfo, projectTrainingSnapshot])

  return {
    data: mosaicMasks,
    isLoading,
    isValidating,
    error,
  }
}
