import type * as React from 'react'
import { Button, Typography, Row, Tooltip, Badge, Empty } from 'antd'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import UploadIcon from '@material-design-icons/svg/outlined/file_upload.svg'
import AddIcon from '@material-design-icons/svg/filled/add.svg'
import styles from './styles/left-panel.module.scss'
import { TOOLTIP_MOUSE_ENTER_DELAY, MESSAGE_UPLOAD_IMAGE_DIMENSION, MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT } from '@app/constants'
import { JumpingDots } from '@components/common/jumping-dots'
import { type ProjectImageResponse } from '@app/api/openapi'

const { Title, Text } = Typography

interface ImagePredictionStatusWrapperProps {
  isPredicting: boolean
  hasPrediction: boolean
  selected: boolean
  children: React.ReactNode
}
export const ImagePredictionStatusWrapper: React.FC<ImagePredictionStatusWrapperProps> = ({ children, isPredicting, selected }) => {
  const ribbonText = <div className={styles.predictionStatus}>
    <Tooltip
      title={
        <FormattedMessage id={'image-prediction-status.tooltip.status.is-prediction'} defaultMessage={'Predicting...'} />
      }
      mouseLeaveDelay={0}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
    >
      <JumpingDots />
    </Tooltip>
  </div>
  return <Badge.Ribbon placement="start" className={clsx(styles.imagePredictionStatusRibbon, { [styles.hasStatus]: (isPredicting) })} text={ribbonText} color="#e6e4e3">
    <div className={clsx(styles.imageContainer, { [styles.selected]: selected })}>
      {children}
    </div>
  </Badge.Ribbon>
}

interface ImageInfoWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  shouldDisplayImageInfo: boolean
  imageIndex: number
  imageInfo: ProjectImageResponse | undefined
}
export const ImageInfoWrapper: React.FC<ImageInfoWrapperProps> = ({ children, shouldDisplayImageInfo, imageInfo, imageIndex, ...divProps }) => {
  return <div
    {...divProps}
    className={clsx(divProps.className, styles.imageInfoWrapper)}
  >
    {children}
    <div className={clsx(styles.imageInfo, { [styles.shouldDisplayImageInfo]: shouldDisplayImageInfo })}>
      <div className={styles.imageInfoTitle}>
        <FormattedMessage id={'left-panel.images.image-info.title'} defaultMessage={'Image #{imageIndex}'} values={{ imageIndex: imageIndex + 1 }} />
      </div>
      {
        imageInfo !== undefined &&
          <>
            <div className={styles.imageName} title={imageInfo.name}>
              <FormattedMessage id={'left-panel.images.image-info.image-name'} defaultMessage={'{imageName}'} values={{ imageName: imageInfo.name }} />
            </div>
            <div className={styles.imageSize}>
              <FormattedMessage id={'left-panel.images.image-info.image-size'} defaultMessage={'{width}x{height}px'} values={{ width: imageInfo.width, height: imageInfo.height }} />
            </div>
          </>
      }
    </div>
  </div>
}

interface LeftPanelElementProps {
  underline?: boolean
  topLine?: boolean
  expand?: boolean
  shrinkable?: boolean
  separator?: boolean
  children: React.ReactNode
  grow?: boolean
  className?: string
}
export const LeftPanelElement: React.FC<LeftPanelElementProps> = ({ underline, topLine, expand, shrinkable, separator, grow, children, className }) => {
  return <Row
    className={clsx(
      className,
      styles.panelRow,
      {
        [styles.underline]: underline,
        [styles.topLine]: topLine,
        [styles.expand]: expand,
        [styles.shrinkable]: shrinkable,
        [styles.smallSeparator]: separator,
        [styles.grow]: grow,
      },
    )}
  >
    {children}
  </Row>
}

export const LeftPanelGroup: React.FC<{ children?: React.ReactNode, restrictedHeight?: boolean }> = ({ children, restrictedHeight }) => {
  return <div className={clsx(
    styles.panelGroup,
    {
      [styles.restrictedHeight]: restrictedHeight,
    },
  )}>
    {children}
  </div>
}

export const DropZoneText: React.FC = () => {
  return <div className={styles.dropZone}>
    <div>
      <div className={styles.dropZoneIcon}>
        <UploadIcon />
      </div>
      <Text className={styles.dropZoneLabel}>
        <FormattedMessage id="editor.add-image.dropzone.label" defaultMessage={'Release here to upload images'} />
        <br/>
        { MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT }
        <br/>
        { MESSAGE_UPLOAD_IMAGE_DIMENSION }
      </Text>
    </div>
  </div>
}

interface UploadImageSectionProps {
  addImage: () => void
}
export const UploadImageSection: React.FC<UploadImageSectionProps> = ({ addImage }) => {
  return <div className={styles.addImage} id='upload-image-section'>
    <div className={styles.addImageHeader}>
      <Title level={5} className={styles.addImageTitle}>
        <FormattedMessage id="editor.left-panel.title" defaultMessage={'Images'} />
      </Title>
      <Tooltip
        title={
          <FormattedMessage id={'left-panel.images.button.add'} defaultMessage={'Upload images'} />
        }
        mouseLeaveDelay={0}
        mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
      >
        <Button
          className={styles.addImageButton}
          icon={<AddIcon />}
          onClick={() => {
            const element = document.activeElement as HTMLElement
            addImage()
            element.blur()
          }}
        />
      </Tooltip>
    </div>
  </div>
}

interface ImageListContainerProps {
  children?: React.ReactNode
  numberOfImages: number
  numberOfUploads: number
}
export const ImageListContainer: React.FC<ImageListContainerProps> = ({ children, numberOfImages, numberOfUploads }) => {
  return <div className={styles.imageListContainer}>
    <div className={styles.imageList} id='image-list'>
      {numberOfImages === 0 && numberOfUploads === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      { children }
    </div>
  </div>
}

export const LeftPanel: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <div className={styles.leftPanel}>
    {children}
  </div>
}
