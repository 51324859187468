/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectMeasureType } from './DirectMeasureType';
import {
    DirectMeasureTypeFromJSON,
    DirectMeasureTypeFromJSONTyped,
    DirectMeasureTypeToJSON,
} from './DirectMeasureType';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
} from './Geometry';
import type { GeometryProperties } from './GeometryProperties';
import {
    GeometryPropertiesFromJSON,
    GeometryPropertiesFromJSONTyped,
    GeometryPropertiesToJSON,
} from './GeometryProperties';

/**
 * Direct Measure with analytic stats
 * @export
 * @interface DirectMeasureWithStats
 */
export interface DirectMeasureWithStats {
    /**
     * 
     * @type {DirectMeasureType}
     * @memberof DirectMeasureWithStats
     */
    type: DirectMeasureType;
    /**
     * 
     * @type {Geometry}
     * @memberof DirectMeasureWithStats
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties}
     * @memberof DirectMeasureWithStats
     */
    geometryProperties?: GeometryProperties;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    minX: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    maxX: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    area: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    length: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    angle: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectMeasureWithStats
     */
    radius: number | null;
}

/**
 * Check if a given object implements the DirectMeasureWithStats interface.
 */
export function instanceOfDirectMeasureWithStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "minX" in value;
    isInstance = isInstance && "minY" in value;
    isInstance = isInstance && "maxX" in value;
    isInstance = isInstance && "maxY" in value;
    isInstance = isInstance && "area" in value;
    isInstance = isInstance && "length" in value;
    isInstance = isInstance && "angle" in value;
    isInstance = isInstance && "radius" in value;

    return isInstance;
}

export function DirectMeasureWithStatsFromJSON(json: any): DirectMeasureWithStats {
    return DirectMeasureWithStatsFromJSONTyped(json, false);
}

export function DirectMeasureWithStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureWithStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': DirectMeasureTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': !exists(json, 'geometry_properties') ? undefined : GeometryPropertiesFromJSON(json['geometry_properties']),
        'minX': json['min_x'],
        'minY': json['min_y'],
        'maxX': json['max_x'],
        'maxY': json['max_y'],
        'area': json['area'],
        'length': json['length'],
        'angle': json['angle'],
        'radius': json['radius'],
    };
}

export function DirectMeasureWithStatsToJSON(value?: DirectMeasureWithStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': DirectMeasureTypeToJSON(value.type),
        'geometry': GeometryToJSON(value.geometry),
        'geometry_properties': GeometryPropertiesToJSON(value.geometryProperties),
        'min_x': value.minX,
        'min_y': value.minY,
        'max_x': value.maxX,
        'max_y': value.maxY,
        'area': value.area,
        'length': value.length,
        'angle': value.angle,
        'radius': value.radius,
    };
}

