import { api } from './api'
import { EVENTS_ID } from '@app/constants'
import { CACHE_KEYS, type CacheKey } from '@app/api/cache-keys'
import { mutate as globalMutate } from 'swr'
import { type ClassAnnotationGeoJSON } from '@app/api/openapi'

export const trainProject = async (projectId: string): Promise<void> => {
  await api.prepareTrainingAlgorithmWorkflowFromProjectApiAlgorithmTrainProjectIdPost({
    projectId,
  })
  await globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATUS(projectId))
}

export const cancelTrainProject = async (projectId: string): Promise<void> => {
  await api.cancelProjectTrainingApiAlgorithmTrainProjectIdCancelPost({
    projectId,
  })
  await globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATUS(projectId))
  await globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  await globalMutate(CACHE_KEYS.PROJECT_TRAINING_SNAPSHOT(projectId))
}

export const resetProject = async (projectId: string): Promise<void> => {
  await api.resetProjectApiProjectsProjectIdResetPost({ projectId })
  await globalMutate(CACHE_KEYS.PROJECT(projectId))
  await globalMutate(CACHE_KEYS.PROJECT_TRAINING_SNAPSHOT(projectId))
  await globalMutate(CACHE_KEYS.TRAINING_PARAMETERS(projectId))
  await globalMutate(CACHE_KEYS.PROJECT_BUNDLE(projectId))
  await globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION.name) {
        const projectImageAnnotationClassDistributionCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationClassDistributionCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  })

  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))
}

export const triggerClearAllAnnotations = async (projectId: string): Promise<void> => {
  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  }, [], { revalidate: false })
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))

  await api.clearProjectClassAnnotationsApiProjectsProjectIdClearClassAnnotationsPost({ projectId })
  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION.name) {
        const projectImageAnnotationClassDistributionCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationClassDistributionCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  })
  void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  // Clear annotations is a breaking change for the undo/redo history
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))
}

export const triggerClearAnnotationsByColorIndex = async (projectId: string, colorIndex: number): Promise<void> => {
  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  }, (currentProjectImageAnnotations: ClassAnnotationGeoJSON[] | undefined) => {
    return (currentProjectImageAnnotations ?? []).filter((annotation) => annotation.colorIndex !== colorIndex)
  }, { revalidate: false })
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))

  await api.clearProjectClassAnnotationsByColorIndexApiProjectsProjectIdClearClassAnnotationsColorIndexPost({ projectId, colorIndex })

  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION.name) {
        const projectImageAnnotationClassDistributionCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationClassDistributionCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  })
  void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  // Clear annotations is a breaking change for the undo/redo history
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))
}
