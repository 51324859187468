import {
  type Column,
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table'

import { notUndefined, useVirtualizer } from '@tanstack/react-virtual'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcn-ui/components/ui/table'
import { type InputHTMLAttributes, type MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@shadcn-ui/components/ui/button'
import { Input } from '@shadcn-ui/components/ui/input'
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '@shadcn-ui/components/ui/dropdown-menu'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  // TData[] when data is loaded, undefined when loading, null when data is not available
  data: TData[] | undefined | null
  csvURL?: string
  defaultHiddenColumns?: VisibilityState
  defaultSorting?: SortingState
}

export function DataTable<TData, TValue> ({
  columns,
  data,
  csvURL,
  defaultHiddenColumns,
  defaultSorting,
}: DataTableProps<TData, TValue>): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? [])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [showColumnFilters, setShowColumnFilters] = useState(false)
  const [columnFiltersPosition, setColumnFiltersPosition] = useState({ top: 0, left: 0 })
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultHiddenColumns ?? {})

  const memoData = useMemo(() => {
    return data ?? []
  }, [data])

  const table = useReactTable({
    data: memoData,
    columns,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    isMultiSortEvent: (e: unknown) => {
      const event = e as MouseEvent
      return event.ctrlKey || event.shiftKey
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })

  const { rows } = table.getRowModel()
  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 54,
    overscan: 20,
  })
  const items = virtualizer.getVirtualItems()
  // XXX: Sticky header and virtualized rows have some issues.
  // This comment on the GH issue give a solution that is working for us.
  // https://github.com/TanStack/virtual/issues/640#issuecomment-2248330184
  const [before, after] = items.length > 0
    ? [
        notUndefined(items[0]).start - virtualizer.options.scrollMargin,
        virtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
      ]
    : [0, 0]
  const colSpan = 8

  const onContextMenu = (e: MouseEvent): void => {
    e.preventDefault()
    setColumnFiltersPosition({ top: e.clientY - 50, left: e.clientX })
    // XXX: The column filters dropdown should show up when the click is released
    // context menu does not have a release event, so we need to listen to the mouseup event once
    window.addEventListener('mouseup', () => {
      setShowColumnFilters(true)
      // Close the dropdown when clicking outside of it
      window.addEventListener('click', () => {
        setShowColumnFilters(false)
      }, { once: true })
    }, { once: true })
  }

  return (
    <div className='flex flex-col grow max-h-full max-w-full overflow-auto'>
      <div className="flex flex-row-reverse items-center p-3 justify-between gap-2 max-w-full">
        <div className='invisible fixed' style={columnFiltersPosition}>
          <DropdownMenu open={showColumnFilters}>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="">
                <FormattedMessage id='databrowser.table.columns.visibility' defaultMessage='Columns' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter(
                  (column) => column.getCanHide(),
                )
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => { column.toggleVisibility(!!value) }}
                    >
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className='grow flex justify-between gap-[16px] items-center'>
          <div className='shrink-0 text-sm text-clemex-darkGray'>
            <FormattedMessage id='databrowser.table.search' defaultMessage='Search into all fields:' />
          </div>
          <Input
            value={globalFilter ?? ''}
            onChange={value => { setGlobalFilter(value.target.value) }}
            className="max-w-sm min-w-min"
          />
        </div>
      </div>
      <div ref={parentRef} className="grow rounded-md border max-h-full max-w-full overflow-auto">
        <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
          <Table staticTable>
            <TableHeader className='sticky top-0 bg-clemex-white z-10' onContextMenu={onContextMenu}>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className='box-border border-b border-clemex-offGray'>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        {header.column.getCanFilter()
                          ? <div>
                              <Filter column={header.column} />
                            </div>
                          : null}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {before > 0 && (
                <tr>
                  <td colSpan={colSpan} style={{ height: before }} />
                </tr>
              )}
              {(virtualizer.getVirtualItems().length ?? 0) > 0
                ? (
                    virtualizer.getVirtualItems().map((virtualRow) => {
                      const row = rows[virtualRow.index]
                      return <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && 'selected'}
                        className='box-border'
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    })
                  )
                : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-18 text-center">
                        {
                          data === undefined
                            ? <FormattedMessage id='databrowser.table.loading' defaultMessage='Loading...' />
                            : <FormattedMessage id='databrowser.table.no-data' defaultMessage='No data' />
                        }
                      </TableCell>
                    </TableRow>
                  )}
              {after > 0 && (
                <tr>
                  <td colSpan={colSpan} style={{ height: after }} />
                </tr>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="flex flex-end items-center p-4 justify-between gap-2 mt-auto">
        <div className="flex items-center justify-end space-x-2 text-clemex-offDarkGray">
          <FormattedMessage id='databrowser.table.summary.total-item' defaultMessage='{count} objects found' values={{ count: table.getRowCount() }} />
        </div>
        <div className="flex items-center justify-end space-x-2">
          <a href={csvURL} target='_blank' rel="noreferrer" className='hover:text-inherit'>
            <Button
              variant="outline"
              size="sm"
              disabled={csvURL === undefined}
            >
              <FormattedMessage id='databrowser.action.export.csv' defaultMessage='Export to CSV' />
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

function DebouncedInput ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>): JSX.Element {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => { clearTimeout(timeout) }
  }, [debounce, onChange, value])

  return (
    <input {...props} value={value} onChange={e => { setValue(e.target.value) }} />
  )
}

function Filter<C> ({ column }: { column: Column<C, unknown> }): JSX.Element {
  const columnFilterValue = column.getFilterValue()

  return (
    <DebouncedInput
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={value => { column.setFilterValue(value) }}
      placeholder={'Search...'}
      className="w-36 border shadow rounded"
    />
  )
}
