import * as React from 'react'
import { notification, Modal, Typography, Space } from 'antd'
import * as RFAPI from '@api/api'
import styles from './styles/left-panel.module.scss'
import { ProjectDatasetContext, TaskStatus, type ProjectImageResponse } from '@api/openapi'
import {
  DropZoneText,
  ImageInfoWrapper,
  ImageListContainer,
  ImagePredictionStatusWrapper,
  LeftPanel,
  LeftPanelElement,
  LeftPanelGroup,
  UploadImageSection,
} from '@components/left-panel'
import { type RcFile } from 'rc-upload/lib/interface'

import { EVENTS_ID, MINIMAL_IMAGE_DIMENSION_PIXEL, SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE } from '@app/constants'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import {
  useAnnotationClassesDistribution,
  useProject,
  useProjectActions,
  useProjectImages,
  useProjectTrainingSnapshot,
  useProjectTrainingState,
  useRFAlgorithmProjectTrainingParameters,
  useDeepLearningProjectTrainingParameters,
  useImageProjectInfo,
} from '@app/api/hooks'
import { Project } from '@app/api/models'
import { UploadImagesModal } from '@components/modals/upload-images-modal'
import { useSelectedImageId } from '@app/hooks/editor'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import { useSearchParams } from 'react-router-dom'
import { EditorImageThumbnail } from '@components/image-thumbnail'
import { ImageDropAreaComponent } from '@components/image-drop-area'
import { UploadingProgressionComponent } from '@components/uploading-progression'
import { EditorClassEditor } from '@app/pages/editor-page/class-editor'
import { AnnotationParametersGlobalOpacity } from '@components/panel/panel-elements/annotation-parameters'
import { AnnotationClassesDistributionComponent } from '@components/panel/panel-elements/classes-distribution'
import { usePredictionStatus, useProjectImageStatus } from '@app/api/resource-status'

const { Paragraph } = Typography
interface EditorLeftPanelProps {
  projectSlug: string
  projectId: string
  context: ProjectDatasetContext
}

export const EditorLeftPanel: React.FC<EditorLeftPanelProps> = ({
  projectSlug,
  projectId,
  context,
}) => {
  const intl = useIntl()
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [openUploadModal, setOpenUploadModal] = React.useState(false)
  const { data: projectResponse, mutate: mutateProject } = useProject(projectSlug)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const { data: images, isLoading: isImagesLoading, isValidating: isImagesValidating, mutate: mutateImages } = useProjectImages(projectId, context)
  const [selectedImageId, setSelectedImageId] = useSelectedImageId()
  const { triggerReset } = useProjectActions(projectId)
  const { invalidate: invalidateRFAlgorithmTrainingParameters } = useRFAlgorithmProjectTrainingParameters(projectId)
  const { invalidate: invalidateDeepLearningTrainingParameters } = useDeepLearningProjectTrainingParameters(projectId)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [confirmResetProjectModal, confirmResetProjectContextHolder] = Modal.useModal()
  const { mutate: mutateAnnotationClassesDistribution } = useAnnotationClassesDistribution(context === ProjectDatasetContext.Training ? projectId : undefined, selectedImageId)
  const { mutate: mutateProjectImageStatus } = useProjectImageStatus(projectId)

  const [shouldDisplayImageInfo, setShouldDisplayImageInfo] = React.useState<boolean>(false)
  const onMouseEnterImageList = React.useCallback((): void => {
    setShouldDisplayImageInfo(true)
  }, [setShouldDisplayImageInfo])
  const onMouseLeaveImageList = React.useCallback((): void => {
    setShouldDisplayImageInfo(false)
  }, [setShouldDisplayImageInfo])

  const [uploadingData, setUploadingData] = React.useState<Array<[string, number, RcFile]>>([])

  React.useEffect(() => {
    const sendImageTo = async (
      imageId: string,
      context: ProjectDatasetContext,
    ): Promise<void> => {
      await RFAPI.sendImageToDataset(projectId, imageId, context)
      await mutateImages()
      await mutateProjectImageStatus()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TRAIN))
    }
    const onSendToValidation = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await sendImageTo(imageId, ProjectDatasetContext.Validation)
      await mutateProjectTrainingState()
    }
    const onSendToTraining = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await sendImageTo(imageId, ProjectDatasetContext.Training)
      await mutateProjectTrainingState()
    }
    const onSendToTrainingAndNavigate = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      const imageSlug = (event as CustomEvent).detail.imageSlug
      await sendImageTo(imageId, ProjectDatasetContext.Training)
      const annotateProjectRoute = WebUIRoutes.annotateProject(projectResponse?.slug, { image: imageSlug })
      navigate({ pathname: annotateProjectRoute.path, search: annotateProjectRoute.search })
      await mutateProjectTrainingState()
    }
    const onUploadImage = (): void => {
      setOpenUploadModal(true)
    }
    const onConfirmReset = async (): Promise<void> => {
      await triggerReset()
    }

    const onResetProject = async (): Promise<void> => {
      void confirmResetProjectModal.confirm({
        title: intl.formatMessage({ id: 'project.menu.item.reset.confirm.title', defaultMessage: 'Confirm reset project?' }),
        content: <Space direction="vertical">
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p1',
                defaultMessage: 'This action will delete all annotations from all images and unlink the trained model from the project.',
              })
            }
          </Paragraph>
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p2',
                defaultMessage: 'This action is not reversible.',
              })
            }
          </Paragraph>
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p3',
                defaultMessage: 'Are you sure to continue?',
              })
            }
          </Paragraph>
        </Space>,
        okText: intl.formatMessage({ id: 'modal.confirm.continue.label', defaultMessage: 'Continue' }),
        onOk: onConfirmReset,
        centered: true,
        transitionName: '',
      })
    }

    const onDeleteProjectImage = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await RFAPI.sendImageToDataset(
        projectId,
        imageId,
        ProjectDatasetContext.Deleted,
      )
      await mutateImages()
      await mutateProjectImageStatus()
    }
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION,
      onSendToValidation,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING,
      onSendToTraining,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE,
      onSendToTrainingAndNavigate,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_DELETE_IMAGE,
      onDeleteProjectImage,
      {},
    )
    window.addEventListener(EVENTS_ID.UPLOAD_IMG_MODAL, onUploadImage, {})
    window.addEventListener(EVENTS_ID.PROJECT_RESET, onResetProject, {})
    return () => {
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION,
        onSendToValidation,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING,
        onSendToTraining,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE,
        onSendToTrainingAndNavigate,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_DELETE_IMAGE,
        onDeleteProjectImage,
        {},
      )
      window.removeEventListener(EVENTS_ID.PROJECT_RESET, onResetProject, {})
      window.removeEventListener(EVENTS_ID.UPLOAD_IMG_MODAL, onUploadImage, {})
    }
  }, [
    images?.length,
    invalidateRFAlgorithmTrainingParameters,
    invalidateDeepLearningTrainingParameters,
    mutateImages,
    mutateProject,
    navigate,
    projectId,
    projectResponse?.slug,
    intl,
    confirmResetProjectModal,
    mutateAnnotationClassesDistribution,
    mutateProjectTrainingState,
    triggerReset,
    mutateProjectImageStatus,
  ])

  const selectedImageSlug = searchParams.get('image')

  // Set initial selected image
  React.useEffect(() => {
    if (selectedImageSlug !== null && images !== undefined && !isImagesLoading && !isImagesValidating) {
      const image = images.find((image) => image.slug === selectedImageSlug)
      if (image !== undefined) {
        setSelectedImageId(image.id)
      } else {
        setSelectedImageId(images[0]?.id ?? undefined)
      }
    } else if (images !== undefined && images.length > 0) {
      setSelectedImageId(images[0]?.id ?? undefined)
    } else {
      setSelectedImageId(undefined)
    }
    return () => {
      setSelectedImageId(undefined)
    }
  }, [context, images, selectedImageSlug, setSelectedImageId, isImagesLoading, isImagesValidating])

  React.useEffect(() => {
    const image = (isImagesLoading || isImagesValidating) ? undefined : images?.find((image) => image.id === selectedImageId)
    if (image !== undefined) {
      setSearchParams((previousParams) => {
        return {
          ...previousParams,
          image: image.slug,
        }
      }, {
        replace: true,
      })
    }
  }, [images, isImagesLoading, isImagesValidating, selectedImageId, setSearchParams])

  const project = React.useMemo(() => {
    if (projectResponse === undefined) {
      return
    }
    return Project.fromProjectResponse(projectResponse)
  }, [projectResponse])

  const [leftPanelUploadNotification, leftPanelUploadContextHolder] = notification.useNotification()
  const onUploadSuccess = React.useCallback(
    async (response: unknown, selectImage: boolean): Promise<void> => {
      if (project === undefined) {
        return
      }
      const image = RFAPI.getImageSizeFromImageResponse(
        response as ProjectImageResponse,
      )
      try {
        await RFAPI.sendImageToDataset(project.id, image.id, context)
        await mutateImages()
        await mutateProjectImageStatus()
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPLOADED_IMG))
        if (selectImage || selectedImageId === undefined) {
          setSelectedImageId(image.id)
        }
      } catch (err) {
        leftPanelUploadNotification.error({
          message: intl.formatMessage({
            id: 'editor.notification.left.panel.upload.error.title',
            defaultMessage: 'Something went wrong.',
          }),
          description: intl.formatMessage({
            id: 'editor.notification.left.panel.display.error.body',
            defaultMessage: 'Please reload the page.',
          }),
        })
        Sentry.captureException(err)
      }
    },
    [project, context, mutateImages, mutateProjectImageStatus, selectedImageId, setSelectedImageId, leftPanelUploadNotification, intl],
  )

  const onUploadFail = (file: RcFile): void => {
    leftPanelUploadNotification.error({
      message: intl.formatMessage({
        id: 'projects.uploadImage.form.image.upload.error',
        defaultMessage: 'Image {fileName} upload failed. The image format or dimensions could be incorrect. Please validate the image is {minDimension}x{minDimension} and in the following formats: {imageFormat}',
      }, {
        fileName: file.name,
        minDimension: MINIMAL_IMAGE_DIMENSION_PIXEL,
        imageFormat: SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE,
      }),
    })
  }

  const confirmImageUpload = async (imageIds: string[]): Promise<void> => {
    setIsConfirming(true)
    try {
      await Promise.all(
        imageIds.map(async (imageId) => {
          await RFAPI.sendImageToDataset(projectId, imageId, context)
        }),
      )
      const newTrainingImagesInfo = await Promise.all(
        imageIds.map(
          async (imageId) => await RFAPI.getImageInfo(imageId, projectId),
        ),
      )
      await mutateImages()
      await mutateProjectImageStatus()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPLOADED_IMG))

      setSelectedImageId(newTrainingImagesInfo[0].id)
    } finally {
      setOpenUploadModal(false)
      setIsConfirming(false)
    }
  }

  return (project !== undefined)
    ? (
        <>
          {leftPanelUploadContextHolder}
          <LeftPanel>
            <ImageDropAreaComponent
              accept={SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE}
              action={`/api/projects/${project.id}/image/upload`}
              method={'POST'}
              name={'image'}
              onSuccess={onUploadSuccess}
              onFail={onUploadFail}
              onUploadingChange={setUploadingData}
              dropZoneText={<DropZoneText />}
              forceDisable={openUploadModal || images?.length === 0}
            >
              <UploadImagesModal
                isOpen={openUploadModal}
                onClose={() => {
                  setOpenUploadModal(false)
                }}
                onConfirm={confirmImageUpload}
                uploadUrl={`/api/projects/${projectId}/image/upload`}
                isConfirmingUpload={isConfirming}
              />
              {confirmResetProjectContextHolder}
              <LeftPanelElement>
                <UploadImageSection
                  addImage={() => {
                    setOpenUploadModal(true)
                  }}/>
              </LeftPanelElement>
              <LeftPanelElement expand shrinkable className={styles.imageList}>
                <ImageListContainer numberOfImages={images?.length ?? 0} numberOfUploads={uploadingData.length}>
                  <div onMouseEnter={onMouseEnterImageList} onMouseLeave={onMouseLeaveImageList}>
                    {
                      images?.map((image, index) => {
                        return <LeftPanelImage
                          key={image.id}
                          imageId={image.id}
                          index={index}
                          numberOfImages={images.length}
                          projectDatasetContext={context}
                          projectId={projectId}
                          shouldDisplayImageInfo={shouldDisplayImageInfo}
                        />
                      })
                    }
                  </div>
                </ImageListContainer>
              </LeftPanelElement>
              <UploadingProgressionComponent uploadingData={uploadingData} />
              <LeftPanelGroup restrictedHeight>
                <LeftPanelElement underline topLine shrinkable grow>
                  <EditorClassEditor projectId={projectId} context={context} />
                </LeftPanelElement>
                {
                  context === ProjectDatasetContext.Training &&
                  <LeftPanelElement underline>
                    <AnnotationParametersGlobalOpacity />
                  </LeftPanelElement>
                }
                {
                  context === ProjectDatasetContext.Training &&
                  <LeftPanelElement expand >
                    <AnnotationClassesDistributionComponent projectId={projectId} />
                  </LeftPanelElement>
                }
              </LeftPanelGroup>
            </ImageDropAreaComponent>
          </LeftPanel>
        </>
      )
    : null
}

interface LeftPanelImageProp {
  projectId: string
  imageId: string
  index: number
  numberOfImages: number
  shouldDisplayImageInfo: boolean
  projectDatasetContext: ProjectDatasetContext
}
const LeftPanelImage: React.FC<LeftPanelImageProp> = ({ imageId, projectId, index, shouldDisplayImageInfo, numberOfImages, projectDatasetContext }) => {
  const { data: imageInfo } = useImageProjectInfo(projectId, imageId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const [selectedImageId, setSelectedImageId] = useSelectedImageId()
  const { data: predictionStatus, isValidating: isValidatingPredictionStatus } = usePredictionStatus(imageId, projectTrainingSnapshot?.projectTrainingSnapshotId)
  const selectedImageElementRef = React.useRef<HTMLDivElement>(null)

  // When the selected imageId changes, scroll to the selected image
  React.useEffect(() => {
    if (selectedImageElementRef.current === null) {
      return
    }
    if (selectedImageId === imageId) {
      selectedImageElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [imageId, selectedImageElementRef, selectedImageId])

  // Use cache to store the prediction thumbnail URL
  // Notably, if `isValidatingPredictionStatus` is true but the URL has been cached, we can still display the thumbnail as it has been fetched before and should be valid
  const [predictionThumbnailURLCache, setPredictionThumbnailURLCache] = React.useState<Record<string, string>>({})
  const predictionThumbnailURL = React.useMemo(() => {
    if (imageInfo === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    const predictionCacheKey = `${imageInfo.id}-${projectTrainingSnapshot.projectTrainingSnapshotId}`
    if (predictionThumbnailURLCache[predictionCacheKey] !== undefined) {
      return predictionThumbnailURLCache[predictionCacheKey]
    }
    if (isValidatingPredictionStatus || predictionStatus !== TaskStatus.Completed) {
      return undefined
    }

    const url = RFAPI.createImagePredictionThumbnail(
      imageInfo.id,
      projectTrainingSnapshot.projectTrainingSnapshotId,
      256,
      180,
    )
    setPredictionThumbnailURLCache({
      ...predictionThumbnailURLCache,
      [predictionCacheKey]: url,
    })
    return url
  }, [imageInfo, isValidatingPredictionStatus, predictionStatus, predictionThumbnailURLCache, projectTrainingSnapshot])

  return <ImageInfoWrapper
    imageIndex={index}
    imageInfo={imageInfo}
    shouldDisplayImageInfo={shouldDisplayImageInfo}
    onClick={() => { setSelectedImageId(imageId) }}
  >
    <ImagePredictionStatusWrapper
      hasPrediction={predictionStatus === TaskStatus.Completed}
      isPredicting={predictionStatus === TaskStatus.Running}
      selected={selectedImageId === imageId}
    >
      <div ref={selectedImageElementRef}>
        <EditorImageThumbnail
          imageId={imageId}
          imageSlug={imageInfo?.slug}
          imageThumbnailUrl={RFAPI.createImageThumbnailURL(imageId, 256, 180)}
          imagePredictionMaskThumbnailUrl={predictionThumbnailURL}
          width={256}
          height={180}
          step={projectDatasetContext}
          isOneImage={numberOfImages === 1}
        />
      </div>
    </ImagePredictionStatusWrapper>
  </ImageInfoWrapper>
}
