/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InProgressTraining } from './InProgressTraining';
import {
    InProgressTrainingFromJSON,
    InProgressTrainingFromJSONTyped,
    InProgressTrainingToJSON,
} from './InProgressTraining';
import type { TrainingError } from './TrainingError';
import {
    TrainingErrorFromJSON,
    TrainingErrorFromJSONTyped,
    TrainingErrorToJSON,
} from './TrainingError';

/**
 * Project Training Status
 * @export
 * @interface ProjectTrainingStatus
 */
export interface ProjectTrainingStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    currentProjectTrainingSnapshotId: string | null;
    /**
     * 
     * @type {TrainingError}
     * @memberof ProjectTrainingStatus
     */
    lastTrainingError: TrainingError | null;
    /**
     * 
     * @type {InProgressTraining}
     * @memberof ProjectTrainingStatus
     */
    inProgressTraining: InProgressTraining | null;
}

/**
 * Check if a given object implements the ProjectTrainingStatus interface.
 */
export function instanceOfProjectTrainingStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "currentProjectTrainingSnapshotId" in value;
    isInstance = isInstance && "lastTrainingError" in value;
    isInstance = isInstance && "inProgressTraining" in value;

    return isInstance;
}

export function ProjectTrainingStatusFromJSON(json: any): ProjectTrainingStatus {
    return ProjectTrainingStatusFromJSONTyped(json, false);
}

export function ProjectTrainingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'currentProjectTrainingSnapshotId': json['current_project_training_snapshot_id'],
        'lastTrainingError': TrainingErrorFromJSON(json['last_training_error']),
        'inProgressTraining': InProgressTrainingFromJSON(json['in_progress_training']),
    };
}

export function ProjectTrainingStatusToJSON(value?: ProjectTrainingStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'current_project_training_snapshot_id': value.currentProjectTrainingSnapshotId,
        'last_training_error': TrainingErrorToJSON(value.lastTrainingError),
        'in_progress_training': InProgressTrainingToJSON(value.inProgressTraining),
    };
}

