import type React from 'react'
import { type FC } from 'react'
import styles from './panel-bar.module.scss'
import DatabrowserIcon from '@material-design-icons/svg/outlined/table_chart.svg'
import { Button, Tooltip } from 'antd'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import { FormattedMessage } from 'react-intl'
import { useDataBrowserStore } from '@app/stores/databrowser'

interface PanelBarProps {
  icon: React.ReactNode
  tooltip?: React.ReactNode
  onClick?: () => void
}
const PanelItem: FC<PanelBarProps> = ({ icon, tooltip, onClick }) => {
  return <Tooltip
    title={tooltip}
    placement='right'
    mouseLeaveDelay={0}
    mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
  >
    <Button className={styles.panelItem} onClick={onClick}>
      <div className={styles.panelIcon}>
        {icon}
      </div>
    </Button>
  </Tooltip>
}

export const PanelBar: FC = () => {
  const { toggleOpenClose } = useDataBrowserStore((state) => ({
    toggleOpenClose: state.toggleOpenClose,
  }))

  return <div className={styles.root}>
    <PanelItem
      icon={<DatabrowserIcon />}
      tooltip={<FormattedMessage id='panel-bar.panel-item.databrowser.tooltip' defaultMessage={'Databrowser'} />}
      onClick={toggleOpenClose}
    />
  </div>
}
