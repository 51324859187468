import type * as React from 'react'
import { Switch, Typography, Flex, Button, Progress } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useExportOptions } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'
import styles from './styles/export-settings.module.scss'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'
import { useExportProgressStore } from '@app/stores/export-progress'
import { LoadingIcon } from '@components/loading-icon'

const { Paragraph } = Typography
interface ExportSettingsProps {
  projectId: string
}
export const ExportSettings: React.FC<ExportSettingsProps> = ({ projectId }) => {
  const { data: exportOptions, mutateExportOptions } = useExportOptions(projectId)
  const [isExporting, exportProgress] = useExportProgressStore((state) => [state.isExporting, state.progress])

  return <Flex vertical className={styles.root}>
    <SettingsSection
      title={<FormattedMessage id='options.label' defaultMessage='Export Options'/>}
    >
      <SettingRow
        title={
          <FormattedMessage id='export.options.re-export-original-image.label' defaultMessage={'Re-export original image'}/>
        }
      >
        <Switch defaultChecked={exportOptions?.includeOriginalImage}
          onChange={(checked: boolean) => {
            if (exportOptions === undefined) {
              return
            }
            void mutateExportOptions({
              ...exportOptions,
              includeOriginalImage: checked,
            })
          }}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id='export.options.include-annotations.label' defaultMessage={'Include annotations'}/>
        }
      >
        <Switch defaultChecked={exportOptions?.includeCombinedMasks}
          onChange={(checked: boolean) => {
            if (exportOptions === undefined) {
              return
            }
            void mutateExportOptions({
              ...exportOptions,
              includeCombinedMasks: checked,
              includeIndividualMasks: !checked,
            })
          }}
        />
      </SettingRow>
      <SettingRow className={styles.exportButtonRow}>
        {
          isExporting && <Progress
            showInfo={false}
            status='active'
            percent={exportProgress * 100}
            className={styles.progress}
          />
        }
        <Button
          type='primary'
          onClick={() => {
            window.dispatchEvent(new CustomEvent(EVENTS_ID.EXPORT_PROJECT))
          }}
          className={styles.exportButton}
          disabled={isExporting}
        >
          <LoadingIcon isLoading={isExporting} className={styles.exporting} />
          <FormattedMessage id='project.export.label' defaultMessage={'Export'} />
        </Button>
      </SettingRow>
    </SettingsSection>
  </Flex>
}

export const ExportFeatureExplanation: React.FC = () => {
  return <Flex className={styles.root} vertical>
    <SettingsSection
      title={<FormattedMessage id='project.export.explanation.modal.title' defaultMessage='What is the project export feature?'/>}
    >
      <SettingRow>
        <div>
          <Paragraph>
            <FormattedMessage id="project.export.explanation.modal.description.2" defaultMessage="Project exportation allows to export all images, annotation masks and prediction masks of a project." />
          </Paragraph>
          <RequestLicenceFeature featureText='Project export' licenceFeatureRequest={UserAssistanceLicenceFeature.ExportProject} />
        </div>
      </SettingRow>
    </SettingsSection>
  </Flex>
}
