import type * as React from 'react'
import { Drawer, Menu, Typography, Space, Divider } from 'antd'
import styles from './styles/navigation.module.scss'
import { FormattedMessage } from 'react-intl'
import HouseIcon from '@material-design-icons/svg/round/house.svg'
import ProfileIcon from '@material-design-icons/svg/round/person.svg'
import LicenceIcon from '@material-design-icons/svg/round/lock.svg'
import HelpIcon from '@material-design-icons/svg/round/help.svg'
import EditIcon from '@material-design-icons/svg/round/settings.svg'
import ResetIcon from '@material-design-icons/svg/round/sync_problem.svg'
import ExportIcon from '@material-design-icons/svg/round/download_for_offline.svg'
import UploadIcon from '@material-design-icons/svg/round/upload_file.svg'
import CreateIcon from '@material-design-icons/svg/round/add.svg'
import LogoutIcon from '@material-design-icons/svg/round/logout.svg'
import ShareIcon from '@material-design-icons/svg/round/share.svg'
import { Link } from 'react-router-dom'
import { WebUIRoutes } from '@app/routes'
import { useFeatureFlags, useLicence } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'
import { type ItemType } from 'antd/lib/menu/hooks/useItems'
import clsx from 'clsx'

const { Text, Title } = Typography
export enum MenuContext {
  HOME = 'HOME',
  EDITOR = 'EDITOR',
  PROFILE = 'PROFILE',
  LICENCE = 'LICENCE',
  NONE = 'NONE',
}
interface NavigationMenuProps {
  onCloseNavigation: () => void
  context?: MenuContext
}
const NavigationMenu: React.FC<NavigationMenuProps> = ({ onCloseNavigation, context = MenuContext.NONE }) => {
  const { data: flags, isLoading: isFlagsLoading } = useFeatureFlags()

  const dispatch = (eventId: EVENTS_ID) => (): void => {
    window.dispatchEvent(new CustomEvent(eventId))
    onCloseNavigation()
  }
  const defaultOpenKeys: string[] = []
  const projectEditorSubMenu: ItemType[] = [
    {
      className: styles.divider,
      label: <Divider />,
      key: 'divider-project-menu',
    },
    {
      icon: <CreateIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id='navigation.submenu.project.item.create.title' defaultMessage='Create Project'/></Text>,
      key: 'Create',
      onClick: dispatch(EVENTS_ID.PROJECT_CREATE_NEW),
    },
    {
      icon: <EditIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id="navigation.submenu.project.item.edit.title" defaultMessage="Project Settings"/></Text>,
      key: 'Edit',
      onClick: dispatch(EVENTS_ID.PROJECT_SETTING_OPEN),
    },
    {
      icon: <ResetIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id="navigation.submenu.project.item.reset.title" defaultMessage="Reset Project"/></Text>,
      key: 'Reset',
      onClick: dispatch(EVENTS_ID.PROJECT_RESET),
    },
    {
      icon: <ExportIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id='navigation.submenu.project.item.export.title' defaultMessage='Export Project'/></Text>,
      key: 'Export',
      onClick: dispatch(EVENTS_ID.PROJECT_EXPORT_SETTINGS),
    },
    {
      icon: <UploadIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id='navigation.submenu.project.item.upload.title' defaultMessage='Add Images'/></Text>,
      key: 'Upload Images',
      onClick: dispatch(EVENTS_ID.UPLOAD_IMG_MODAL),
    },
    {
      className: styles.divider,
      label: <Divider />,
      key: 'divider-rest  -menu',
    },
  ]
  if (flags !== undefined && !flags?.enableLocalUser) {
    const itemShare = {
      icon: <ShareIcon className={styles.menuIcon} />,
      label: <Text><FormattedMessage id='navigation.submenu.project.item.share.title' defaultMessage='Share Project'/></Text>,
      key: 'Share Project',
      onClick: dispatch(EVENTS_ID.PROJECT_SHARE_MODAL),
    }
    projectEditorSubMenu.splice(projectEditorSubMenu.length - 1, 0, itemShare)
  }

  const items: ItemType[] = [
    {
      icon: <HouseIcon className={styles.menuIcon} />,
      label: <Link to={WebUIRoutes.projectList().path}><Text>
        <FormattedMessage id='link.page.home.title' defaultMessage='Home'/>
      </Text></Link>,
      key: 'Home',
    },
  ]
  const itemsRest = [
    {
      icon: <HelpIcon className={styles.menuIcon} />,
      label: <Text>
        <FormattedMessage id='link.page.global-settings.title' defaultMessage='Global Settings'/>
      </Text>,
      key: 'GlobalSettings',
      onClick: dispatch(EVENTS_ID.GLOBAL_SETTING_OPEN),
    },
    {
      icon: (isFlagsLoading || !(flags?.enableLocalUser ?? false)) ? <ProfileIcon className={styles.menuIcon} /> : <LicenceIcon className={styles.menuIcon} />,
      label: (isFlagsLoading || !(flags?.enableLocalUser ?? false))
        ? <Link to={WebUIRoutes.profile().path}>
            <Text>
              <FormattedMessage id='link.page.profile.title' defaultMessage='My Profile'/>
            </Text>
          </Link>
        : <Link to={WebUIRoutes.licence().path}>
            <Text>
              <FormattedMessage id='link.page.licence.title' defaultMessage='Licence'/>
            </Text>
          </Link>,
      key: (isFlagsLoading || !(flags?.enableLocalUser ?? false)) ? 'profile' : 'licence',
    },
    {
      icon: <HelpIcon className={styles.menuIcon} />,
      label: <Text>
        <FormattedMessage id='link.page.help.title' defaultMessage='Help'/>
      </Text>,
      key: 'help',
      onClick: dispatch(EVENTS_ID.SHORTCUTS_MODAL),
    },
  ]
  const itemDownloadLogs = {
    icon: <ExportIcon className={styles.menuIcon} />,
    label: <Text>
      <FormattedMessage id='link.page.download-logs.title' defaultMessage='Application logs' />
    </Text>,
    key: 'download-logs',
    onClick: dispatch(EVENTS_ID.DOWNLOAD_LOGS),
  }
  const itemLogout = {
    label: <Link to={WebUIRoutes.logout().path}>
      {
        <FormattedMessage id="header.profile.logout" defaultMessage={'Logout'} />
      }
    </Link>,
    icon: <LogoutIcon className={styles.menuIcon} />,
    className: styles.menuItem,
    key: 'user-logout',
  }
  if (context === MenuContext.NONE) {
    return null
  }
  if (context === MenuContext.EDITOR) {
    items.push(...projectEditorSubMenu)
  }
  items.push(...itemsRest)

  if (flags !== undefined) {
    if (flags.enableLocalUser) {
      items.push(itemDownloadLogs)
    } else {
      items.push(itemLogout)
    }
  }

  return <Menu
    mode="inline"
    selectable={false}
    expandIcon={null}
    items={items}
    defaultOpenKeys={defaultOpenKeys}
    inlineCollapsed={false}
    inlineIndent={0}
    className={styles.menu}
    onOpenChange={() => { /* noop */ }}
  />
}
interface NavigationProps {
  show: boolean
  onClose: () => void
  context?: MenuContext
}
export const Navigation: React.FC<NavigationProps> = ({ show, onClose, context }) => {
  const { data: flags, isLoading: isFlagsLoading } = useFeatureFlags()
  const { data: licence } = useLicence()

  return <Drawer
    title={
      <div className={clsx(styles.titleContainer, `theme-${licence?.theme ?? 'clemex'}`)}>
        <Link to={WebUIRoutes.projectList().path}>
          <Title className={styles.titleText}>Clemex Studio</Title>
        </Link>
      </div>}
    open={show}
    placement={'left'}
    onClose={onClose}
    className={styles.drawer}
    width={310}
    footer={<Space size='small' className={styles.footer}>
      <a href={'/api/assets/pdf_tou'} target='_blank' rel="noreferrer">
        <FormattedMessage
          id="link.term-use.url"
          defaultMessage={'Terms and Conditions'}
        />
      </a>
      { !isFlagsLoading && flags !== undefined && !flags.enableLocalUser &&
          <>
            <Text className={styles.separator}>-</Text>
            <a href={'https://clemex.com/privacy-policy/'} target='_blank' rel="noreferrer">
              <FormattedMessage
                id="link.privacy-policy.url"
                defaultMessage={'Privacy Policy'}
              />
            </a>
          </>
      }
    </Space>}
  >
    <NavigationMenu onCloseNavigation={onClose} context={context}/>
  </Drawer>
}
