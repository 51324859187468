import * as React from 'react'
import { messagesEN } from './translations/en'
import { IntlProvider, type MessageFormatElement } from 'react-intl'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { NotificationRouter } from '@app/notifications-router'
import { ServerDisconnectedModal } from '@components/modals/server-disconnected'
import { StudioRouter } from './routes'
import { useEnvironment } from '@app/api/hooks'
import { SWRConfig, mutate } from 'swr'
import { ErrorPage } from '@app/pages/error-page'
import { useMaintenance } from '@app/hooks/maintenance-hook'
import { debounce } from 'lodash'
import { type ResponseError } from '@app/api/openapi'
import { AntDConfigProvider } from '@components/antd-config-provider'
import { BaseNotificationContainer } from '@components/base-notification-container'
import { CACHE_KEYS } from '@app/api/cache-keys'

const messages: Record<
string,
Record<string, string> | Record<string, MessageFormatElement[]>
> = {
  en: messagesEN,
  fr: messagesEN,
}

const refreshFeatureFlagsDebounced = debounce(() => {
  void mutate(CACHE_KEYS.FEATURE_FLAGS)
}, 1000, { leading: true })

const onRequestsErrors = (error: ResponseError): void => {
  if (error.response?.status >= 500) {
    Sentry.captureException(error)
  }
  // If the user hit a 401 (unauthorized) then we redirect him to the login page
  // and we preserve query params
  if (error.response?.status === 401) {
    window.location.href = '/' + window.location.search
  }
  refreshFeatureFlagsDebounced()
}

const Index: React.FC = () => {
  const { data: environmentConfiguration, error: environmentError, isLoading } = useEnvironment()
  const [isReady, setIsReady] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (environmentConfiguration !== undefined && !isReady) {
      Sentry.init({
        dsn: import.meta.env.VITE_CLEMEX_STUDIO_WEB_UI_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration({}),
          Sentry.replayIntegration({}),
          Sentry.replayCanvasIntegration({}),
        ],
        tracesSampleRate: 1.0,
        // Capture Replay for 0% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: import.meta.env.VITE_CLEMEX_STUDIO_WEB_UI_VERSION_RELEASE_ID,
        environment: environmentConfiguration.environment,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
      })
      if (environmentConfiguration.logrocketAppId != null) {
        LogRocket.init(environmentConfiguration.logrocketAppId)
      }
      window.clemex = {
        environmentConfiguration,
      }
      setIsReady(true)
    }
  }, [environmentConfiguration, isLoading, isReady])

  useMaintenance()

  const language = typeof window === 'undefined' ? 'en-US' : navigator.language
  const hasError = environmentError !== undefined

  return (
    <SWRConfig value={{
      keepPreviousData: true,
      onError: onRequestsErrors,
      // XXX: revalidateOnFocus cause trouble because when some resources are loaded/reloaded.
      //      some components will trigger useEffect to perform some actions.
      //      We expect these actions to come from either the first initial load or from an
      //      user actions that would invalidate the cache keys on purpose.
      revalidateOnFocus: false,
    }}>
      <HelmetProvider>
        <AntDConfigProvider>
          <IntlProvider
            locale={language}
            messages={messages[language.split(/[-_]/)[0]]}
          >
            <Helmet key='helmet'>
              {
                [32, 48, 72, 96, 144, 192, 256, 384, 512].map((size) => {
                  return <link
                    key={size}
                    rel={'icon'}
                    href={`/icons/icon-${size}x${size}.png`}
                    sizes={`${size}x${size}`}
                    type={'image/png'}
                  />
                })
              }
            </Helmet>
            {isReady && (
              <>
                <BaseNotificationContainer />
                <NotificationRouter />
                <StudioRouter />
                {/* TODO: Remove this component from the final cloud build */}
                <ServerDisconnectedModal />
              </>
            )}
            {hasError && <ErrorPage /> }
          </IntlProvider>
        </AntDConfigProvider>
      </HelmetProvider>
    </SWRConfig>
  )
}

export default Index
