import { CACHE_KEYS } from '@app/api/cache-keys'
import { useProjectTrainingSnapshot } from '@app/api/hooks'
import { useCurrentProjectTrainingSnapshotId, useInProgressTraining, useLastErrorTraining } from '@app/api/resource-status'
import { useGlobalPredictionVisibility, useHiddenPredictionClassIndexes } from '@app/hooks/editor'
import { ProjectNotificationType, useProjectsNotification } from '@app/hooks/notifications'
import { useEffect, useState } from 'react'
import { mutate as globalMutate } from 'swr'

/**
 * This hook revalidate the resource when the project status changes.
 * Notably, when the current project training snapshot id changes, the project training snapshot is revalidated.
 * Other resources should be revalidated in this hook if they depend on the project status.
 * For now, there is only project training snapshot.
 *
 * Note: this hook should only be used once in the page.
 *       Using it more than once would result in the revalidation of the resource multiple times.
 *       Meaning it will trigger multiple requests to the server to fetch the project training snapshot.
 */
export const useRevalidateResourceWhenProjectStatusChange = (projectId: string | undefined): void => {
  const swrProjectTrainingSnapshot = useProjectTrainingSnapshot(projectId)
  const { data: currentProjectTrainingSnapshotId } = useCurrentProjectTrainingSnapshotId(projectId)
  const [, setHiddenPredictionClassIndexes] = useHiddenPredictionClassIndexes()
  const [, setGlobalPredictionVisibility] = useGlobalPredictionVisibility()

  useEffect(() => {
    if (currentProjectTrainingSnapshotId !== undefined && !swrProjectTrainingSnapshot.isLoading && !swrProjectTrainingSnapshot.isValidating) {
      if (swrProjectTrainingSnapshot.data?.projectTrainingSnapshotId !== currentProjectTrainingSnapshotId) {
        // Force SWR to revalidate the project training snapshot
        void swrProjectTrainingSnapshot.mutate()

        // Project training might have changed too
        void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))

        // As the project training snapshot has changed
        // The prediction will change as well
        // So we need to reset the state of the prediction mask
        setHiddenPredictionClassIndexes([])
        setGlobalPredictionVisibility(true)
      }
    }
  }, [currentProjectTrainingSnapshotId, projectId, setGlobalPredictionVisibility, setHiddenPredictionClassIndexes, swrProjectTrainingSnapshot])
}

export const useProjectTrainingErrorNotitificationMonitoring = (projectId: string | undefined): void => {
  const { data: lastTrainingError, isValidating } = useLastErrorTraining(projectId)
  const { data: inProgressTraining } = useInProgressTraining(projectId)
  const { notify } = useProjectsNotification()
  const [hasLastTrainingErrorBeenNotified, setHasLastTrainingErrorBeenNotified] = useState<boolean>(false)

  useEffect(() => {
    if (projectId === undefined) {
      return
    }
    const isTrainingInProgress = inProgressTraining !== undefined

    if (lastTrainingError !== undefined && !isValidating && !isTrainingInProgress) {
      if (!hasLastTrainingErrorBeenNotified) {
        notify(projectId, ProjectNotificationType.TrainingError, lastTrainingError.errorMessage)
        setHasLastTrainingErrorBeenNotified(true)
      }
    }
    if ((lastTrainingError === undefined && !isValidating) || isTrainingInProgress) {
      setHasLastTrainingErrorBeenNotified(false)
    }
  }, [lastTrainingError, isValidating, hasLastTrainingErrorBeenNotified, projectId, notify, inProgressTraining])
}
